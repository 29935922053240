import { Label } from "flowbite-react";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as Yup from 'yup';
import AxiosMain from "../../../Components/AxiosMain";
import CustomDropDown from "../../../Components/CustomDropDown";
import FormsLayout from "../../../Components/FormsLayout";
import LoadingComponent from "../../../Components/LoadingComponent";
import { getAdminClasses } from "../../../Components/adminHelper";
function BusinessUserAdd() {
    const dispatch = useDispatch();
    const [isformLoading, setIsFormLoading] = useState(false);
    const [selectedClass, setSelectedClass] = useState([]);
    const [classOptions, setClassOption] = useState([]);
    const adminAllClasses = useSelector((state) => state.auth.classes);
    const userauth = useSelector((state) => state.auth.data);
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userauth.token.access}`,
        },
    }
    const businessUSerSchema = Yup.object().shape({
        fullname: Yup.string().required("Name is Required"),
        mobile: Yup.string()
            .required("Please enter your mobile number.")
            .matches(/^[0-9]+$/, "Only number allowed")
            .min(6, "Minimum 6 Digit Required")
            .max(10, "Max 10 Digit Required"),
        email: Yup.string().email().required("Email is required"),
        schoolname: Yup.string().required("Schoolname is required"),
        state: Yup.string().required("State is required"),
        city: Yup.string().required("City is required"),
        address: Yup.string().required("Address is required"),
        postal_pincode: Yup.string()
            .required("Pincode is required")
            .matches(/^[0-9]+$/, "Only number allowed")
            .min(6, "Minimum 6 Digit Required")
            .max(10, "Max 10 Digit Required"),
        valid_upto: Yup.string().required("Date is Requied"),
        classes: Yup.array().min(1).required('Class is required.'),
    })
    const businessUser = useFormik({
        initialValues: {
            fullname: "",
            mobile: "",
            email: "",
            schoolname: "",
            state: "",
            city: "",
            address: "",
            postal_pincode: "",
            valid_upto: "",
            classes: []
        },
        validationSchema: businessUSerSchema,
        onSubmit: (values, { resetForm }) => {
            console.log("Admin Add USer", values);
            setIsFormLoading(true);
            AxiosMain.post("/add-user/", values, config).then((res) => {
                console.log(res);
                setIsFormLoading(false);
                if (res.status === 201) {
                    Swal.fire({
                        title: 'Done',
                        text: 'User Successfully added!',
                        icon: "success",
                        timer: 3000
                    });
                    resetForm();
                    setSelectedClass([]);
                }
            }).catch((e) => {
                const { response } = e;
                setIsFormLoading(false);
                console.log(response);
                if (response.status === 400) {
                    const { data } = response;
                    const { non_field_errors } = data;
                    if (non_field_errors.length > 0) {
                        Swal.fire({
                            title: "Error",
                            text: non_field_errors[0],
                            icon: "error",
                        })
                    } else {
                        Swal.fire({
                            title: "Error",
                            text: "Something Went Wrong!",
                            icon: "error",
                        })
                    }
                }
                else {
                    Swal.fire({
                        title: "Error",
                        text: "Something Went Wrong!",
                        icon: "error",
                    })
                }
            })
        }
    })
    useEffect(() => {
        if (Object.keys(adminAllClasses).length === 0) {
            getAdminClasses(dispatch, config);
        } else {
            const classData = adminAllClasses.results.map((item) => {
                return {
                    label: item.name,
                    value: item.id
                }
            })
            setClassOption(classData);
        }
    }, [adminAllClasses])
    useEffect(() => {
        if (selectedClass.length > 0) {
            const classData = selectedClass.map((item) => {
                return item.id
            })
            businessUser.setFieldValue("classes", classData)
        }
    }, [selectedClass])
    return (
        <div className="bg-white p-5 min-h-[50%] mb-[30px]">
            <div className='flex flex-row md:w-full mb-3 w-full justify-center'>
                <div className="shadow rounded-[10px] border p-3 basis-full md:basis-full lg:basis-1/2">
                    <div>
                        <h2 className="cm-kits-form-title flex text-xl lg:text-3xl font-normal text-[#5a5c69] gap-y-2 mb-4 text-center">Add User</h2>
                        <LoadingComponent isLoading={isformLoading} component={(
                            <form onSubmit={businessUser.handleSubmit} className='flex flex-col w-full cm-form-add'>
                                <div className='flex flex-col w-full'>
                                    <div className="mb-3">
                                        <FormsLayout touched={businessUser.touched.fullname} placeholder="Full Name" errors={businessUser.errors.fullname} type="text" name="fullname" form={businessUser} values={businessUser.values.name} />
                                    </div>
                                    <div className="mb-3">
                                        <FormsLayout touched={businessUser.touched.mobile} placeholder="Mobile" errors={businessUser.errors.mobile} type="text" name="mobile" form={businessUser} values={businessUser.values.mobile} />
                                    </div>
                                    <div className="mb-3">
                                        <FormsLayout touched={businessUser.touched.email} placeholder="Email" errors={businessUser.errors.email} type="email" name="email" form={businessUser} values={businessUser.values.email} />
                                    </div>
                                    <div className="mb-3">
                                        <FormsLayout touched={businessUser.touched.schoolname} placeholder="School Name" errors={businessUser.errors.schoolname} type="text" name="schoolname" form={businessUser} values={businessUser.values.schoolname} />
                                    </div>
                                    <div className="mb-3">
                                        <FormsLayout
                                            touched={businessUser.touched.state}
                                            placeholder="State"
                                            errors={businessUser.errors.state}
                                            type="text"
                                            name="state"
                                            form={businessUser}
                                            values={businessUser.values.state}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <FormsLayout
                                            touched={businessUser.touched.city}
                                            placeholder="City"
                                            errors={businessUser.errors.city}
                                            type="text"
                                            name="city"
                                            form={businessUser}
                                            values={businessUser.values.city}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <FormsLayout
                                            touched={businessUser.touched.address}
                                            placeholder="Address"
                                            errors={businessUser.errors.address}
                                            type="text"
                                            name="address"
                                            form={businessUser}
                                            values={businessUser.values.address}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <FormsLayout
                                            touched={businessUser.touched.postal_pincode}
                                            placeholder="Postal Pincode"
                                            errors={businessUser.errors.postal_pincode}
                                            type="text"
                                            name="postal_pincode"
                                            form={businessUser}
                                            values={businessUser.values.postal_pincode}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <DatePicker
                                            className='w-full px-5 p-2 border-[#d1d3e2] rounded-[8px] focus:border-blue-300 outline-none border-2 focus:shadow-md text-xs md:text-base'
                                            name="valid_upto" //only when value has changed
                                            selected={businessUser.values.valid_upto}
                                            onChange={(date) => businessUser.setFieldValue('valid_upto', date)}
                                            placeholderText="Valid Upto"
                                        />
                                        {businessUser.errors.valid_upto && businessUser.touched.valid_upto && (
                                            <p className='form-error  mb-0'>{businessUser.errors.valid_upto}</p>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                        <Label htmlFor="classes" className="ml-2">Select an option of Class</Label>
                                        <CustomDropDown isMultiple={true} placeholder="Select Classes" url="/kits/class/" saveData={setSelectedClass} />
                                        {businessUser.errors.classes && businessUser.touched.classes && (
                                            <p className='form-error  mb-0'>{businessUser.errors.classes}</p>
                                        )}
                                        {/* <MultiSelect
                                            name="classes"
                                            options={classOptions}
                                            value={selectedClass}
                                            onChange={setSelectedClass} />
                                        {businessUser.errors.classes && businessUser.touched.classes && (
                                            <p className='form-error  mb-0'>{businessUser.errors.classes}</p>
                                        )} */}
                                    </div>
                                    <button className='btn btn-primary border text-xs md:text-sm bg-[#4e73df] mt-4 p-2 md:p-4 rounded-[8px] text-[#fff]' type="submit">Add</button>
                                </div>
                            </form>
                        )} />

                    </div>
                </div>
            </div>
        </div>
    )
}
export default BusinessUserAdd