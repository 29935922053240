import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from 'yup';
import AxiosMain from "../../../Components/AxiosMain";
import FormsLayout from "../../../Components/FormsLayout";
import LoadingComponent from "../../../Components/LoadingComponent";
import { getAdminCategory } from "../../../Components/adminHelper";
function AdminCategoryAdd() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isCPLoading, setIsCPLoading] = useState(false);
    const userauth = useSelector((state) => state.auth.data);
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userauth.token.access}`,
        },
    }
    const addCategorySchema = Yup.object().shape({
        name: Yup.string()
            .required("Field is required"),
    });

    const addCategory = useFormik({
        initialValues: {
            name: ""
        },
        validationSchema: addCategorySchema,
        onSubmit: (values) => {
            setIsCPLoading(true);
            AxiosMain.post("/kits/category/", values, config).then((res) => {
                setIsCPLoading(false);
                if (res.status === 201) {
                    Swal.fire({
                        title: 'Done',
                        text: 'Category Successfully created!',
                        icon: "success",
                        timer: 3000
                    });
                    getAdminCategory(dispatch, config);
                    navigate("/category")
                }
            }).catch((e) => {
                const { response } = e;
                console.log("Error ", response);
                setIsCPLoading(false);
                if (response.status === 400) {
                    const { data } = response;
                    const { name } = data;
                    if (name.length > 0) {
                        Swal.fire({
                            title: "Error",
                            text: name[0],
                            icon: "error",
                        })
                    } else {
                        Swal.fire({
                            title: "Error",
                            text: "Something Went Wrong!",
                            icon: "error",
                        })
                    }

                }
                else {
                    Swal.fire({
                        title: "Error",
                        text: "Something Went Wrong!",
                        icon: "error",
                    })
                }
            })
        }
    });

    return (
        <>
            <div className="bg-white p-5 min-h-[50%]">
                <div className='flex flex-row md:w-full mb-3 w-full justify-center'>
                    <div className="shadow rounded-[10px] border p-3 basis-full md:basis-full lg:basis-1/2 xl:basis-1/3">
                        <h2 className="cm-kits-form-title flex text-xl lg:text-3xl font-normal text-[#5a5c69] gap-y-2 mb-4">Category Add</h2>
                        <LoadingComponent isLoading={isCPLoading} component={(
                            <form onSubmit={addCategory.handleSubmit} className='flex flex-col w-full'>
                            <div className='flex flex-col w-full'>
                                    <FormsLayout touched={addCategory.touched.name} placeholder="Name" errors={addCategory.errors.name} type="text" name="name" form={addCategory} values={addCategory.values.name} />
                                <button className='btn btn-primary border text-xs md:text-sm bg-[#4e73df] mt-4 p-2 md:p-4 rounded-[8px] text-[#fff]' type="submit">Add</button>
                            </div>
                        </form>
                        )} />

                    </div>

                </div>
            </div>

        </>
    )
}

export default AdminCategoryAdd;