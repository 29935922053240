import React, { createRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "../styles/PersonalDashboard.css";
import { adminSidebarRoutes } from "../Routes/AdminRoutes";
import { personalSidebarRoutes } from "../Routes/PersonalRoutes";
import { b2bSidebarRoutes } from "../Routes/B2BRoutes";
import { Sidebar } from "flowbite-react";
import classNames from "classnames";
import { useOnClickOutside } from "usehooks-ts";
import BottomSideBar from "./BottomSideBar";
import Logo from "../assets/images/RoboKidzLogo.svg";

function Sidenav({ userauth, open, setOpen, profile, onMenuButtonClick }) {
  const ref = createRef(null);
  const navigate = useNavigate();
  // const [sideMenuOpenCloseState, setSideMenuOpenCloseState] =
  //   React.useState(false);
  const [currentUserType, setCurrentUserType] = useState("");
  const [sidebarLinks, setSidebarLinks] = useState([]);

  // New state to keep track of the selected menu item
  const [selectedMenuItem, setSelectedMenuItem] = useState(0);
  useEffect(() => {
    if (Object.keys(userauth).length > 0) {
      setCurrentUserType(userauth.user.user_type);
    }
  }, [userauth]);
  useEffect(() => {
    if (currentUserType !== "") {
      if (currentUserType === "PersonalUser") {
        setSidebarLinks(personalSidebarRoutes);
      } else if (currentUserType === "B2B") {
        setSidebarLinks(b2bSidebarRoutes);
      } else if (currentUserType === "ADMIN") {
        setSidebarLinks(adminSidebarRoutes);
      } else {
        setSidebarLinks([]);
      }
    }
  }, [currentUserType]);
  useOnClickOutside(ref, (e) => {
    setOpen(false);
  });
  return (
    <>
      <div
        className={classNames({
          "flex flex-col justify-between bg-[#F8F8F8]": true, // layout
          "text-zinc-50 py-3 ": true, // colors
          "md:w-full md:sticky md:top-16 md:z-0 top-0 z-20 fixed ": true, // positioning
          "md:h-[calc(100vh - 95px)] h-full w-[300px] ": true, // for height and width
          "transition-transform .3s ease-in-out md:-translate-x-0 ": true, //animations
          "-translate-x-full": !open, //hide sidebar to the left when closed
        })}
        ref={ref}
        style={{ borderRight: "5px solid #F8F8F8" }}
      >
        <div className="flex flex-col h-full">
          {" "}
          <div className="flex justify-center mt-[2.62rem] mb-4">
            {" "}
            {/* New div for centering */}
            <img
              className="w-[9.9rem] cursor-pointer"
              src={Logo}
              alt="logo"
              onClick={() => navigate("/")}
            />
          </div>
          {/* <div className='sidebar-heading text-left'>{currentUserType}</div> */}
          <div className="flex-grow">
            {" "}
            {/* This div grows to push content to the bottom */}
            <Sidebar
              aria-label="Sidebar with multi-level dropdown"
              className="main-sidenav-bar-wrp"
            >
              <Sidebar.Items>
                <Sidebar.ItemGroup>
                  {sidebarLinks.length > 0 &&
                    sidebarLinks.map((item, index) =>
                      item.child.length > 0 ? (
                        <Sidebar.Collapse
                          label={item.label}
                          key={`ADMIN-lin-collapse-${index}`}
                          className="cm-admin-multi-menu"
                          icon={() => item.icon}
                        >
                          {item.child.map((innerItem, innerIndex) => (
                            <Sidebar.Item
                              icon={() => innerItem.icon}
                              key={`ADMIN-lin-${innerIndex}`}
                              className={`border-0 cm-menu-link cm-admin-sidebar-link ${
                                selectedMenuItem === innerItem.link
                                  ? "highlight"
                                  : ""
                              }`}
                              onClick={() => {
                                navigate(innerItem.link);
                                setSelectedMenuItem(innerItem.link);
                              }}
                            >
                              <div className=" hover:text-[#0B54A4] p-2 flex">
                                {innerItem.label}
                              </div>
                            </Sidebar.Item>
                          ))}
                        </Sidebar.Collapse>
                      ) : (
                        <Sidebar.Item
                          key={`ADMIN-lin-${index}`}
                          className={`border-0 cm-menu-link cm-admin-sidebar-link admin-no-child ${
                            selectedMenuItem === item.link ? "highlight" : ""
                          }`}
                          onClick={() => {
                            navigate(item.link);
                            setSelectedMenuItem(item.link);
                          }}
                        >
                          <div className=" hover:text-[#0B54A4] p-2 flex">
                            <div style={{ color: "#A3A3A3" }}>{item.icon}</div>
                            <div className="pl-3">{item.label}</div>
                          </div>
                        </Sidebar.Item>
                      )
                    )}
                </Sidebar.ItemGroup>
              </Sidebar.Items>
            </Sidebar>
          </div>
          <div className="mr-5 ml-4 mb-4 mt-4">
            <BottomSideBar profile={profile} />
          </div>
        </div>
      </div>
    </>
  );
}
export default Sidenav;
