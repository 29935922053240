import axios from "axios";
const baseURL = "https://lmsapi.robokidz.co.in"
const AxiosMain = axios.create({
  baseURL: baseURL
});

AxiosMain.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  //console.log("Main Response", response);
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});
export default AxiosMain;                      