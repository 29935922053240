import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AxiosMain from "../Components/AxiosMain";
import "aos/dist/aos.css";
import BackdropLoginPage from "../assets/images/BackdropLogin.svg";
import Logo from "../assets/images/RoboKidzLogo.svg";
import LoginBoxLineIcon from 'remixicon-react/LoginBoxLineIcon';
import { Alert } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";
import MobileBackdrop from "../assets/images/MobileBackdrop.svg";

function ForgotpasswordPage() {
  const navigate = useNavigate();
  const [isMobileStatus, setisMobileStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
  const resetSchema = Yup.object().shape({
    mobile: Yup.string()
      .required("Please Enter Mobile Number")
      .matches(/^[0-9]+$/, "Only number allowed")
      .min(10, "Minimum 10 Digit Required")
      .max(10, "Max 10 Digit Required"),
    email: Yup.string().email("Please enter valid email"),
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const resetFormik = useFormik({
    initialValues: {
      mobile: "",
      email: "",
    },
    validationSchema: resetSchema,
    onSubmit: (values) => {
      if (values.email !== "") {

        AxiosMain.post("/forget-password/", { email: values.email })
          .then((res) => {

            if (res.status === 200) {
              if (res.data.message === "Email Send Succefully") {

                setErrorMsg(false);
                navigate("/");
              }
            }
          })
          .catch((e) => {
            const { response } = e;

            if (response.status === 400) {
              const error = response.data.non_field_errors[0];

              setErrorMsg(error);
            } else {

              setErrorMsg("Something Went Wrong!");
            }
          });
      }
      if (values.mobile !== "") {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        console.log("mobile", values.mobile);
        AxiosMain.post(
          "/check-phone-number/",
          { mobile: values.mobile },
          config
        )
          .then((res) => {
            const { response } = res;

            console.log("res", res.status);
            if (res.status == 200) {
              console.log("response.data", res.data);
              if (res.data !== "") {

                setErrorMsg("Entered Mobile no is not registered");
              } else {

                setErrorMsg("Something Went Wrong!");
              }
            } else {

              setErrorMsg("Something Went Wrong!");
            }
          })
          .catch((e) => {
            console.log("e", e.response.status);
            if (e.response.status === 400) {
              AxiosMain.post(
                "/generate-otp/",
                { mobile: values.mobile },
                config
              )
                .then((res) => {

                  if (res.status === 201) {
                    setErrorMsg(false);
                    setisMobileStatus(true);
                  }
                })
                .catch((e) => {
                  const { response } = e;

                  if (response.status === 400) {

                    setErrorMsg(e.response.data.mobile[0]);
                  } else {

                    setErrorMsg("Something Went Wrong!");
                  }
                });
            } else {

              if (e.status === 400) {
                if (e.data !== "") {

                  setErrorMsg(e.data.mobile[0]);
                } else {

                  setErrorMsg("Something Went Wrong!");
                }
              } else {

                setErrorMsg("Something Went Wrong!");
              }
            }
          });
      }
      if (values.email !== "" && values.mobile !== "") {

        setErrorMsg("You can not Enter Both Way!");
      }
    },
  });

  const verifySchema = Yup.object().shape({
    otp: Yup.string()
      .required("Enter OTP")
      .matches(/^[0-9]+$/, "Only number allowed")
      .max(4, "Max 4 Digits Required"),
    password: Yup.string()
      .min(5)
      .matches(passwordRules, { message: "Please create a stronger password" })
      .required("Required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
  });
  const verifyForm = useFormik({
    initialValues: {
      otp: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: verifySchema,
    onSubmit: (values) => {

      AxiosMain.post("/mobile-change-password/", values, config)
        .then((res) => {
          if (res.status === 202) {


            setErrorMsg(false);
            navigate("/");
          }
        })
        .catch((e) => {

          const { response } = e;
          if (response.status === 400) {

            setErrorMsg(e.response.data.non_field_errors[0]);
          } else {

            setErrorMsg("Something Went Wrong!");
          }
        });
    },
  });


  return (
    <>
      <div className="h-screen relative w-screen flex md:items-start lg:items-center bg-cover bg-no-repeat bg-blend-multiply
      md: bg-right-bottom"
        style={{
          backgroundImage: `
        linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%),
      url(${BackdropLoginPage})`,
        }}
      >
        <div className="lg:hidden absolute z-0 h-screen w-full  flex flex-col">
          <div className="relative h-full w-full ">
            <div className="h-full w-full absolute z-10 bg-gradient-to-b from-[#0E2640] to-[#0060B9] " />
            <div className="h-1/3 w-full absolute bottom-0 z-20 bg-cover bg-right-bottom bg-no-repeat"
              style={{
                backgroundImage: `
                linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 80%),
                url(${MobileBackdrop})`,
              }} >

            </div>
            <div className="h-full w-full absolute z-30  bg-gradient-to-b from-[#0E2640] to-[#0060B980] " />

          </div>
        </div>
        <div className="flex w-full items-start lg:p-0 md:px-[10rem] pt-4 px-4 z-40">
          <div className="lg:w-1/2 w-full flex flex-col justify-center lg:pl-28 lg:pr-28 ">
            <img className="w-[9rem] h-[4rem] mb-4" src={`${Logo}`} alt="Robokidz Logo" />
            <div className="self-stretch text-white text-[20px] leading-7 font-bold mb-4">
              Discover Robokidz's Creative Learning
            </div>
            <div className="pt-8 lg:px-10 px-6 pb-5 mt-8 bg-white rounded-2xl shadow justify-start inline-flex">
              {!isMobileStatus ? (
                <form onSubmit={resetFormik.handleSubmit} className="w-full">
                  {errorMsg && (
                    <Alert color="failure" icon={HiInformationCircle}>
                      <span>
                        <p>
                          <span className="font-medium"> </span>
                          {errorMsg}
                        </p>
                      </span>
                    </Alert>
                  )}
                  <div>
                    <div className="flex flex-col mb-3 gap-y-4 md:mx-auto">
                      <h1 className="text-[1.75rem] text-black font-bold leading-8">Reset Password</h1>
                      <input
                        type="text"
                        aria-label="mobile"
                        max={10}
                        onChange={resetFormik.handleChange}
                        name="mobile"
                        values={resetFormik.values.mobile}
                        placeholder="Mobile Number"
                        className="h-12 w-full p-4 bg-[#FAFAFA] rounded-lg border border-[#ECECEC] text-black text-sm font-[600] leading-5">
                      </input>
                      {resetFormik.errors.mobile &&
                        resetFormik.touched.mobile && (
                          <p className="text-red-500 text-sm">
                            {resetFormik.errors.mobile}
                          </p>
                        )}
                      <button
                        className="w-full h-12 px-10 py-4 bg-[#0060B9] rounded-lg  text-white text-sm font-semibold leading-tight"
                        type="submit"
                      >
                        Send OTP
                      </button>
                      <Link className="flex flex-row items-center cursor-pointer mt-10"
                        type='link'
                        to={'/login'}>
                        <LoginBoxLineIcon color='#0B54A4' />
                        <p className="text-sky-700 text-sm font-bold leading-loose ml-[0.75rem]">Already have and account? Login</p>
                      </Link>
                    </div>
                  </div>
                </form>
              ) : (
                <form onSubmit={verifyForm.handleSubmit} className="w-full">
                  {errorMsg && (
                    <Alert color="failure" icon={HiInformationCircle}>
                      <span>
                        <p>
                          <span className="font-medium"> </span>
                          {errorMsg}
                        </p>
                      </span>
                    </Alert>
                  )}
                  <div className="grid grid-cols-1 mb-3 gap-x-4 md:mx-auto">
                    <div className="flex flex-col space-y-2 mb-6">
                      <h1 className="text-[1.75rem] text-black font-bold leading-8">Reset Password</h1>
                      <label className=' text-black text-sm font-bold leading-tight'>OTP</label>
                      <input
                        errors={verifyForm.errors.otp}
                        type="number"
                        aria-label="otp"
                        name="otp"
                        onChange={verifyForm.handleChange}
                        maxLength={4}
                        form={verifyForm}
                        values={verifyForm.values.otp}
                        placeholder="Enter OTP"
                        className="h-12 w-full p-4 bg-[#FAFAFA] rounded-lg border border-[#ECECEC] text-black text-sm font-[600] leading-5" />
                      {verifyForm.errors.otp &&
                        verifyForm.errors.otp && (
                          <p className="text-red-500 text-sm">
                            {verifyForm.errors.otp}
                          </p>
                        )}
                    </div>
                    <div className="flex flex-col space-y-2 mb-6">
                      <label className=' text-black text-sm font-bold leading-tight'>New Password</label>
                      <input
                        touched={verifyForm.touched.password}
                        errors={verifyForm.errors.password}
                        type="password"
                        name="password"
                        form={verifyForm}
                        values={verifyForm.values.password}
                        onChange={verifyForm.handleChange}
                        placeholder="Password"
                        className="h-12 w-full p-4 bg-[#FAFAFA] rounded-lg border border-[#ECECEC] text-black text-sm font-[600] leading-5"
                      />
                      {verifyForm.errors.password &&
                        verifyForm.errors.password && (
                          <p className="text-red-500 text-sm">
                            {verifyForm.errors.password}
                          </p>
                        )}
                    </div>
                    <div className="flex flex-col space-y-2 mb-6">
                      <label className=' text-black text-sm font-bold leading-tight'>Confirm Password</label>
                      <input
                        touched={verifyForm.touched.confirm_password}
                        errors={verifyForm.errors.confirm_password}
                        type="password"
                        name="confirm_password"
                        form={verifyForm}
                        values={verifyForm.values.confirm_password}
                        onChange={verifyForm.handleChange}
                        placeholder="Confirm Password"
                        className="h-12 w-full p-4 bg-[#FAFAFA] rounded-lg border border-[#ECECEC] text-black text-sm font-[600] leading-5"

                      />
                      {verifyForm.errors.confirm_password &&
                        verifyForm.errors.confirm_password && (
                          <p className="text-red-500 text-sm">
                            {verifyForm.errors.confirm_password}
                          </p>
                        )}
                    </div>
                  </div>
                  <button
                    className="w-full h-12 px-10 py-4 bg-[#0060B9] rounded-lg  text-white text-sm font-semibold leading-tight"
                    type="submit"
                  >
                    Submit
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
        {/* <div className='flex w-full items-end'>
          <div className="lg:w-1/2 w-0 lg:flex hidden lg:flex-col justify-center pt-10 p-10 lg:pl-28 lg:pr-28 lg:pt-32 ">
            <div className="w-full flex-col justify-start items-start gap-10 inline-flex">
              <Link
                type='link'
                to={'/'}
                className='mt-auto'>
                <img className="w-48 h-20" src={`${Logo}`} />
              </Link>
              <div>

                <div className="self-stretch text-white text-2xl font-bold leading-loose">Where Imagination Meets Code</div>
                <div className="self-stretch text-white text-3xl font-bold leading-10">Discover Robokidz's Creative Learning</div>
              </div>
            </div>
          </div>

          <div className="lg:w-1/2 w-full flex flex-col justify-center p-6 lg:pl-28 lg:pr-28 lg:pt-32 ">
            <div className="pt-10 px-10 pb-8 bg-white rounded-2xl shadow">
              {!isMobileStatus ? (
                <form onSubmit={resetFormik.handleSubmit}>
                  {errorMsg && (
                    <Alert color="failure" icon={HiInformationCircle}>
                      <span>
                        <p>
                          <span className="font-medium"> </span>
                          {errorMsg}
                        </p>
                      </span>
                    </Alert>
                  )}
                  <div>

                    <div className="flex flex-col mb-3 gap-y-4 md:mx-auto">
                      <h1 className="text-black text-2xl font-bold leading-loose">Reset Password</h1>
                      <input
                        type="text"
                        aria-label="mobile"
                        max={10}
                        onChange={resetFormik.handleChange}
                        name="mobile"
                        values={resetFormik.values.mobile}
                        placeholder="Mobile Number"
                        className="p-4 bg-neutral-50 rounded-lg border border-gray-200 justify-start items-start inline-flex">
                      </input>
                      {resetFormik.errors.mobile &&
                        resetFormik.touched.mobile && (
                          <p className="text-red-500 text-sm">
                            {resetFormik.errors.mobile}
                          </p>
                        )}
                      <button
                        className=" w-full h-12 px-10 py-4 bg-[#0060B9] rounded-lg  text-white text-sm font-semibold leading-tight"
                        type="submit"
                      >
                        Send OTP
                      </button>
                      <Link className="flex flex-row items-center cursor-pointer mt-10"
                        type='link'
                        to={'/login'}>
                        <LoginBoxLineIcon color='#0B54A4' />
                        <p className="text-sky-700 text-sm font-bold leading-loose ml-[0.75rem]">Already have and account? Login</p>
                      </Link>
                    </div>
                  </div>
                </form>
              ) : (
                <form onSubmit={verifyForm.handleSubmit}>
                  {errorMsg && (
                    <Alert color="failure" icon={HiInformationCircle}>
                      <span>
                        <p>
                          <span className="font-medium"> </span>
                          {errorMsg}
                        </p>
                      </span>
                    </Alert>
                  )}
                  <div className="grid grid-cols-1 mb-3 gap-x-4 md:mx-auto">
                    <div className="flex flex-col space-y-2 mb-6">
                      <label className=' text-black text-sm font-bold leading-tight'>OTP</label>
                      <input
                        errors={verifyForm.errors.otp}
                        type="text"
                        aria-label="otp"
                        name="otp"
                        onChange={verifyForm.handleChange}
                        max={4}
                        form={verifyForm}
                        values={verifyForm.values.otp}
                        placeholder="Enter OTP"
                        className="h-12 p-4 bg-neutral-50 rounded-lg border border-gray-200 justify-start items-start inline-flex text-black text-sm font-medium leading-tight" />
                      {verifyForm.errors.otp &&
                        verifyForm.errors.otp && (
                          <p className="text-red-500 text-sm">
                            {verifyForm.errors.otp}
                          </p>
                        )}
                    </div>
                    <div className="flex flex-col space-y-2 mb-6">
                      <label className=' text-black text-sm font-bold leading-tight'>New Password</label>
                      <input
                        touched={verifyForm.touched.password}
                        errors={verifyForm.errors.password}
                        type="password"
                        name="password"
                        form={verifyForm}
                        values={verifyForm.values.password}
                        onChange={verifyForm.handleChange}
                        placeholder="Password"
                        className="h-12 p-4 bg-neutral-50 rounded-lg border border-gray-200 justify-start items-start inline-flex text-black text-sm font-medium leading-tight"
                      />
                      {verifyForm.errors.password &&
                        verifyForm.errors.password && (
                          <p className="text-red-500 text-sm">
                            {verifyForm.errors.password}
                          </p>
                        )}
                    </div>
                    <div className="flex flex-col space-y-2 mb-6">
                      <label className=' text-black text-sm font-bold leading-tight'>Confirm Password</label>
                      <input
                        touched={verifyForm.touched.confirm_password}
                        errors={verifyForm.errors.confirm_password}
                        type="password"
                        name="confirm_password"
                        form={verifyForm}
                        values={verifyForm.values.confirm_password}
                        onChange={verifyForm.handleChange}
                        placeholder="Confirm Password"
                        className="h-12 p-4 bg-neutral-50 rounded-lg border border-gray-200 justify-start items-start inline-flex text-black text-sm font-medium leading-tight"

                      />
                      {verifyForm.errors.confirm_password &&
                        verifyForm.errors.confirm_password && (
                          <p className="text-red-500 text-sm">
                            {verifyForm.errors.confirm_password}
                          </p>
                        )}
                    </div>
                  </div>
                  <button
                    className="w-full h-12 px-10 py-4 bg-[#0060B9] rounded-lg  text-white text-sm font-semibold leading-tight"
                    type="submit"
                  >
                    Submit
                  </button>
                </form>
              )}

            </div>
          </div>
        </div > */}
      </div >
    </>
  );
}

export default ForgotpasswordPage;
