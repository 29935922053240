import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  data: [],
  videoData: [],
  videoCategory: [],
  query: []
};

export const personalDBStateSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setPersonalDBState: (state, action) => {
      state.data = action.payload;
    },
    setPersonalDBVideoData: (state, action) => {
      state.videoData = action.payload;
    },
    setPersonalDBVideoCategory: (state, action) => {
      state.videoCategory = action.payload;
    },
    setPersonalDBquery: (state, action) => {
      state.query = action.payload;
    },
    removePersonalDBState: (state) => {
      state.data = [];
      state.videoData = [];
      state.videoCategory = [];
      state.query = [];
    },
  }
});

export const {
  setPersonalDBState,
  removePersonalDBState,
  setPersonalDBVideoData,
  setPersonalDBVideoCategory,
  setPersonalDBquery
} = personalDBStateSlice.actions;

export default personalDBStateSlice.reducer;