import React from 'react'

function Footer () {
return(
    <div className='container '>
    <div className='flex flex-row justify-center'>
      <span className='text-xs'>Copyright © Robokidz Eduventures Pvt Ltd </span>
    </div>
    </div>
)
}

export default Footer