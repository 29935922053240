import AddCircleFillIcon from "remixicon-react/AddCircleLineIcon";
import ListCheckIcon from "remixicon-react/ListCheck2Icon";
import LabelIcon from 'remixicon-react/PrinterLineIcon';
import QuestionnaireFillIcon from "remixicon-react/QuestionnaireLineIcon";
import User2FillIcon from "remixicon-react/User2LineIcon";
import VideoAddFillIcon from "remixicon-react/VideoAddLineIcon";
import ProfilePage from '../Components/ProfilePage';
import AdminLabelPrinting from '../pages/Admin/AdminLabelPrinting';
import AdminRootPage from '../pages/Admin/AdminRootPage';
import AdminCategoryAdd from '../pages/Admin/category/AdminCategoryAdd';
import AdminCategoryEdit from '../pages/Admin/category/AdminCategoryEdit';
import AdminCategoryList from '../pages/Admin/category/AdminCategoryList';
import AdminClassAdd from '../pages/Admin/class/AdminClassAdd';
import AdminClassEdit from '../pages/Admin/class/AdminClassEdit';
import AdminClassList from '../pages/Admin/class/AdminClassList';
import AdminKitsList from '../pages/Admin/kits/AdminKitsList';
import AdminQRCodeList from '../pages/Admin/qrcode/AdminQRCodeList';
import AdminQueryList from '../pages/Admin/query/AdminQueryList';
import AdminQueryReply from '../pages/Admin/query/AdminQueryReply';
import BusinessUserAdd from '../pages/Admin/users/BusinessUserAdd';
import BusinessUserList from '../pages/Admin/users/BusinessUserList';
import AdminVideoAdd from '../pages/Admin/videos/AdminVideoAdd';
import AdminVideoEdit from '../pages/Admin/videos/AdminVideoEdit';
import AdminVideosList from '../pages/Admin/videos/AdminVideosList';
import SchoolLine from "remixicon-react/TyphoonLineIcon";
import PageLine from "remixicon-react/FunctionLineIcon";
import ListLine from "remixicon-react/ListOrderedIcon";


const adminSidebarRoutes = [
    // {
    //     label: "Dashboard",
    //     icon: <DashboardIcon color='#206CB3' />,
    //     icont: <DashboardIcon className=' mx-12 h-8 w-7' color='#206CB3' />,
    //     link: "/dashboard"
    // },
    {
        label: "Label Printing",
        icon: <LabelIcon color='#fff' />,
        icont: <LabelIcon className=' mx-12 h-8 w-7' color='#206CB3' />,
        link: "/label-printing",
        child: []
    },
    {
        label: "Category Details",
        icon: <PageLine color='#fff' />,
        icont: <PageLine className=' mx-12 h-8 w-7' color='#206CB3' />,
        link: "/category",
        sublabel: "List",
        child: [
            {
                label: "List",
                icon: <ListCheckIcon color='#fff' />,
                icont: <ListCheckIcon className=' mx-12 h-8 w-7' color='#206CB3' />,
                link: "/category",
            },
            {
                label: "Add",
                icon: <AddCircleFillIcon color='#fff' />,
                icont: <AddCircleFillIcon className=' mx-12 h-8 w-7' color='#206CB3' />,
                link: "/category/add",
            }
        ]
    },
    {
        label: "Class Details",
        icon: <SchoolLine color='#fff' />,
        icont: <SchoolLine className=' mx-12 h-8 w-7' color='#206CB3' />,
        link: "/class",
        sublabel: "List",
        child: [
            {
                label: "List",
                icon: <ListCheckIcon color='#fff' />,
                icont: <ListCheckIcon className=' mx-12 h-8 w-7' color='#206CB3' />,
                link: "/class",
            },
            {
                label: "Add",
                icon: <AddCircleFillIcon color='#fff' />,
                icont: <AddCircleFillIcon className=' mx-12 h-8 w-7' color='#206CB3' />,
                link: "/class/add",
            }
        ]
    },
   
    // {
    //     label: "QR Codes",
    //     icon: <ListCheckIcon color='#206CB3' />,
    //     icont: <ListCheckIcon className=' mx-12 h-8 w-7' color='#206CB3' />,
    //     link: "/qrcodes",
    //     child: [
    //         {
    //             label: "List",
    //             icon: <ListCheckIcon color='#206CB3' />,
    //             icont: <ListCheckIcon className=' mx-12 h-8 w-7' color='#206CB3' />,
    //             link: "/qrcodes",
    //         },

    //     ]
    // },
    {
        label: "Kit Videos",
        icon: <VideoAddFillIcon color='#fff' />,
        icont: <VideoAddFillIcon className=' mx-12 h-8 w-7' color='#206CB3' />,
        link: "/videos",
        child: [
            {
                label: "List",
                icon: <ListCheckIcon color='#fff' />,
                icont: <ListCheckIcon className=' mx-12 h-8 w-7' color='#206CB3' />,
                link: "/videos",
            },
            {
                label: "Add",
                icon: <AddCircleFillIcon color='#fff' />,
                icont: <AddCircleFillIcon className=' mx-12 h-8 w-7' color='#206CB3' />,
                link: "/videos/add",
            }
        ]
    },
    {
        label: "Users Details",
        icon: <User2FillIcon color='#fff' />,
        icont: <User2FillIcon className=' mx-12 h-8 w-7' color='#206CB3' />,
        link: "/businessuser",
        child: [
            {
                label: "List Users",
                icon: <ListCheckIcon color='#fff' />,
                icont: <ListCheckIcon className=' mx-12 h-8 w-7' color='#206CB3' />,
                link: "/businessuser",
            },
            {
                label: "Add",
                icon: <AddCircleFillIcon color='#fff' />,
                icont: <AddCircleFillIcon className=' mx-12 h-8 w-7' color='#206CB3' />,
                link: "/businessuser/add",
            }
        ]
    },
    {
        label: "Kit List",
        icon: <ListLine color='#fff' />,
        icont: <ListLine className=' mx-12 h-8 w-7' color='#206CB3' />,
        link: "/kits",
        child: [
            {
                label: "List",
                icon: <ListCheckIcon color='#fff' />,
                icont: <ListCheckIcon className=' mx-12 h-8 w-7' color='#206CB3' />,
                link: "/kits",
            },
            // {
            //     label: "Add",
            //     icon: <AddCircleFillIcon color='#206CB3' />,
            //     icont: <AddCircleFillIcon className=' mx-12 h-8 w-7' color='#206CB3' />,
            //     link: "/kits/add",
            // }
        ]
    },
    {
        label: "User Queries",
        icon: <QuestionnaireFillIcon color='#fff' />,
        icont: <QuestionnaireFillIcon className=' mx-12 h-8 w-7' color='#ee4048' />,
        link: "/query",
        child: [
            {
                label: "All Query",
                icon: <ListCheckIcon color='#fff' />,
                icont: <ListCheckIcon className=' mx-12 h-8 w-7' color='#ee4048' />,
                link: "/query",
            },
        ]
    },
    
];

const adminRoutes = [
    {
        link: "/",
        component: <AdminRootPage />
    },
    // {
    //     link: "/dashboard",
    //     component: <AdminDashboardPage />
    // },
    {
        link: "/profile",
        component: <ProfilePage />
    },
    {
        link: "/label-printing",
        component: <AdminLabelPrinting />
    },
    {
        link: "/category",
        component: <AdminCategoryList />,
    },
    {
        link: "/category/add",
        component: <AdminCategoryAdd />,
    },
    {
        link: "/category/edit/:id",
        component: <AdminCategoryEdit />,
    },
    {
        link: "/class",
        component: <AdminClassList />
    },
    {
        link: "/class/add",
        component: <AdminClassAdd />
    },
    {
        link: "/class/edit/:id",
        component: <AdminClassEdit />,
    },
    {
        link: "/videos",
        component: <AdminVideosList />
    },
    {
        link: "/videos/add",
        component: <AdminVideoAdd />
    },
    {
        link: "/videos/edit/:id",
        component: <AdminVideoEdit />
    },
    {
        link: "/kits",
        component: <AdminKitsList />
    },
        // {
        //     link: "/kits/add",
        //     component: <AdminKitsAdd />
        // },
        // {
        //     link: "/kits/edit/:id",
        //     component: <AdminKitsEdit />
        // },
    {
        link: "/qrcodes",
        component: <AdminQRCodeList />
    },
    {
        link: "/businessuser",
        component: <BusinessUserList />
    },
    {
        link: "/businessuser/add",
        component: <BusinessUserAdd />
    },
    {
        link: "/query",
        component: <AdminQueryList />
    },
    {
        link: "/query/reply/:id",
        component: <AdminQueryReply />
    },
]

export {
    adminRoutes,
    adminSidebarRoutes
};
