import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import AxiosMain from "../Components/AxiosMain";
import FormsLayout from "../Components/FormsLayout";
import LoadingComponent from "../Components/LoadingComponent";
import { setProfileState } from '../redux/features/authStateSlice';
import { Alert } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";


const EmailVerifyPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isCPLoading, setIsCPLoading] = useState(false);
    const [isEmilSent, setIsEmailSent] = useState(false);
    const profiledata = useSelector((state) => state.auth.profiledata);
    const userauth = useSelector((state) => state.auth.data);

    const [errorMsg, setErrorMsg] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [infoMessage, setInfoMessage] = useState(false);

    const getProfileData = () => {
        const userId = userauth.user.user_id;
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${userauth.token.access}`,
            },
        }
        AxiosMain.get(`/my-profile/${userId}/`, config).then((res) => {
            if (res.status === 200) {
                dispatch(setProfileState(res.data));
                setSuccessMessageWithTimer();
            }
        }).catch((e) => {

            setErrorMsg("Unable to get Profile Data");
            setInfoMessage(false);
            setSuccessMessage(false);

        })
    }
    const emailSchema = Yup.object().shape({
        email: Yup.string().email()
            .required("Enter Email"),
    });
    const otpSchema = Yup.object().shape({
        otp: Yup.string()
            .required("Enter OTP")
            .matches(/^[0-9]+$/, "Only number allowed"),
    });

    const otpForm = useFormik({
        initialValues: {
            otp: ""
        },
        validationSchema: otpSchema,
        onSubmit: (values) => {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userauth.token.access}`,
                },
            }
            setIsCPLoading(true);
            AxiosMain.post("/confirm-email", values, config).then((res) => {
                setIsCPLoading(false);
                if (res.status === 202) {
                    if (Object.keys(res.data).length > 0) {
                        if (Object.keys(res.data).length > 0) {
                            getProfileData();
                        }

                    }
                }
            }).catch((e) => {
                setIsCPLoading(false);
                const { response } = e;
                if (response.status === 400) {
                    setErrorMsg(response.data.message);
                }
            })
        }
    })
    const formEmilverify = useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: emailSchema,
        onSubmit: (values) => {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userauth.token.access}`,
                },
            }
            if (Object.keys(profiledata).length > 0 && profiledata.email === values.email) {
                setIsCPLoading(true);
                AxiosMain.post("/verify-email", values, config).then((res) => {
                    if (res.status === 200) {
                        if (Object.keys(res.data).length > 0) {
                            setIsEmailSent(true);
                            setIsCPLoading(false);
                            setErrorMsg(false);
                            setSuccessMessage(false);
                            setInfoMessage("The verification OTP has been sent to your registered email address. Please check your inbox and enter the OTP in the required field to complete the verification process.");
                        }
                    }
                }).catch((e) => {
                    setIsCPLoading(false);
                    setErrorMsg("Check your email or email not found!");
                    setInfoMessage(false);
                    setSuccessMessage(false);
                })
            } else {
                setErrorMsg("You are using different Email. Please update this email in your profile");
                setInfoMessage(false);
                setSuccessMessage(false);
            }

        }
    })
    useEffect(() => {
        if (Object.keys(profiledata).length > 0) {
            formEmilverify.setFieldValue('email', profiledata.email);
        }
    }, [profiledata])

    function setSuccessMessageWithTimer() {
        setSuccessMessage("Email Verified Successfully");
        setInfoMessage(false);
        setErrorMsg(false);
        setTimeout(() => {
            navigate(`${profiledata.user_type === "ADMIN" ? '/label-printing' : '/dashboard'}`)
        }, 5000);
    }

    return (
        <>
            <div className="bg-white p-5 min-h-[50%]">
                <div className='flex flex-row md:w-full mb-3 w-full justify-center'>
                    <div className=" rounded-[10px] border p-3 basis-full md:basis-full lg:basis-1/2">
                        <h2 className="text-black text-xl font-bold leading-loose">
                            {isEmilSent ? "OTP Verify" : 'Verify Email'}
                        </h2>
                        {isEmilSent ?
                            (
                                <>
                                    <LoadingComponent isLoading={isCPLoading} component={(
                                        <form onSubmit={otpForm.handleSubmit} className='flex flex-col w-full'>
                                            {errorMsg && (
                                                <Alert color="failure" icon={HiInformationCircle} className="my-4">
                                                    <span>
                                                        <p>
                                                            <span className="font-medium"> </span>
                                                            {errorMsg}
                                                        </p>
                                                    </span>
                                                </Alert>
                                            )}
                                            {successMessage && (
                                                <Alert color="sucess" icon={HiInformationCircle} className="my-4">
                                                    <span>
                                                        <p>
                                                            <span className="font-medium"> </span>
                                                            {successMessage}
                                                        </p>
                                                    </span>
                                                </Alert>
                                            )}
                                            {infoMessage && (
                                                <Alert color="info" icon={HiInformationCircle} className="my-4">
                                                    <span>
                                                        <p>
                                                            <span className="font-medium"> </span>
                                                            {infoMessage}
                                                        </p>
                                                    </span>
                                                </Alert>
                                            )}
                                            <div className='flex flex-col w-full'>
                                                <FormsLayout touched={otpForm.touched.otp} placeholder="OTP" errors={otpForm.errors.otp} type="text" name="otp" form={otpForm} values={otpForm.values.otp} />
                                                <button className='px-10 py-4 mt-4 bg-[#0060B9] text-white rounded-lg' type="submit">Verify OTP</button>
                                            </div>
                                        </form>
                                    )} />
                                </>
                            ) :
                            (
                                <>
                                    <LoadingComponent isLoading={isCPLoading} component={(
                                        <form onSubmit={formEmilverify.handleSubmit} className='flex flex-col w-full'>
                                            {errorMsg && (
                                                <Alert color="failure" icon={HiInformationCircle} className="my-4">
                                                    <span>
                                                        <p>
                                                            <span className="font-medium"> </span>
                                                            {errorMsg}
                                                        </p>
                                                    </span>
                                                </Alert>
                                            )}
                                            {successMessage && (
                                                <Alert color="sucess" icon={HiInformationCircle} className="my-4">
                                                    <span>
                                                        <p>
                                                            <span className="font-medium"> </span>
                                                            {successMessage}
                                                        </p>
                                                    </span>
                                                </Alert>
                                            )}
                                            {infoMessage && (
                                                <Alert color="info" icon={HiInformationCircle} className="my-4">
                                                    <span>
                                                        <p>
                                                            <span className="font-medium"> </span>
                                                            {infoMessage}
                                                        </p>
                                                    </span>
                                                </Alert>
                                            )}
                                            <div className='flex flex-col w-full'>
                                                <FormsLayout touched={formEmilverify.touched.email} placeholder="Email" errors={formEmilverify.errors.email} type="email" name="email" form={formEmilverify} values={formEmilverify.values.email} />
                                                <button className='px-10 py-4 mt-4 bg-[#0060B9] text-white rounded-lg' type="submit">Generate OTP</button>
                                            </div>
                                        </form>
                                    )} />
                                </>
                            )}
                    </div>
                </div>
            </div>
        </>
    )
}
export default EmailVerifyPage;
