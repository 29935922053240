import { Button } from 'flowbite-react';
import Print from "rc-print";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AxiosMain from "../../../Components/AxiosMain";
import { getAdminCategory } from "../../../Components/adminHelper";
import { setAdminQRCodes } from "../../../redux/features/authStateSlice";
function AdminQRCodeList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let printDom = null;
    const [isPaginate, setisPaginate] = useState(false);
    const adminAllCategory = useSelector((state) => state.auth.category);
    const adminAllQRCode = useSelector((state) => state.auth.qrcodes);
    const userauth = useSelector((state) => state.auth.data);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const handleRowSelected = React.useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userauth.token.access}`,
        },
    }
    const deleteCategory = (id) => {
        AxiosMain.delete(`/kits/qr-code/${id}`, config).then((res) => {
            console.log(res);
            if (res.status === 204 && res.data === '') {
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
                getQRCodeData();
            }
        }).catch((e) => {
            const { response } = e;
            console.log("error", response);
        })
    }
    const deleteAction = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCategory(id);

            }
        })
    }
    const getQRCodeData = () => {
        AxiosMain.get("/kits/qr-code/", config).then((res) => {
            console.log(res.data);
            if (res.status === 200) {
                if (res.data.count > 0) {
                    //dispatch(setAdminQRCodes(res.data.results));
                    saveQRCodeCat(res.data.results);
                }
                else if (res.data.count > 10) {
                    setisPaginate(true);
                }
            }
        }).catch((e) => {
            const { response } = e;
            console.log("Errors ", e);
        })
    }
    const saveQRCodeCat = (qrCodeData) => {
        if (adminAllCategory.length > 0 && qrCodeData.length > 0) {
            const outputData = [];
            qrCodeData.map((item) => {
                adminAllCategory.map((inItem) => {
                    if (inItem.id === item.category) {
                        let data = {
                            ...item, category: inItem.name
                        }
                        outputData.push(data)
                    }
                })
            })
            dispatch(setAdminQRCodes(outputData))
        }
    }
    useEffect(() => {
        if (adminAllQRCode.length === 0 && adminAllCategory.length > 0) {
            getQRCodeData();
        }
        else if (adminAllQRCode.length === 0 && adminAllCategory.length === 0) {

            getAdminCategory(dispatch, config);
        }
        if (adminAllQRCode.length > 10) {
            setisPaginate(true);
        }
        else {
            setisPaginate(false);
        }
    }, [adminAllCategory, adminAllQRCode])
    const columnsLable = [
        {
            name: 'id',
            sortable: true,
            selector: row => row.id,
            maxWidth: "100px",
        },
        {
            name: 'Category',
            selector: row => row.category,
            sortable: true,
            maxWidth: "150px",
        },
        {
            name: 'QR Code Serial No.',
            selector: row => row.qr_sr_no,
            sortable: true,
            maxWidth: "200px",
        },
        {
            name: "QR Code Image",
            cell: (row) => (
                <div>
                    <img src={row.qrcode} width="100" />
                </div>
            ),
        },
        {
            name: "Action",
            maxWidth: "150px",
            cell: (row) => (
                <div className="flex my-3">
                    <Button.Group>
                        {/* <Button onClick={() => navigate(`/class/edit/${row.id}`)}>
                            <Edit2FillIcon className="mr-3 h-4 w-4" />
                            Edit
                        </Button> */}
                        {/* <Button color="failure" onClick={() => deleteAction(row.id)}>
                            <DeleteBin3FillIcon className="mr-3 h-4 w-4" />
                            Delete
                        </Button> */}

                    </Button.Group>
                </div >
            )
        }
    ];
    const handlePrint = () => {
        console.log("selectedRows", selectedRows)
        printDom.onPrint();
    }

    return (
        <>
            <div className="bg-white p-5 min-h-[50%]">
                <div className='grid grid-cols-1 md:grid-cols-2 sm:grid-cols-1 md:w-full mb-3 gap-x-4'>
                    <div className="col-span-2">
                        {
                            adminAllQRCode.length > 0 && (
                                <div className="cm-admin-category-table-wrapper shadow rounded-[10px] border p-3 m-0 sm:mb-3">
                                    <div className="flex flex-wrap justify-between">
                                        <h2 className="cm-kits-form-title flex text-xl lg:text-3xl font-normal text-[#5a5c69] gap-y-2 mb-4">QR Code List List</h2>
                                        <div className="flex flex-wrap">
                                            {selectedRows.length > 0 && (
                                                <Button type="button" className="mr-3" onClick={() => handlePrint()}>Print QR Codes</Button>
                                            )}
                                            <Button onClick={() => getQRCodeData()}>Get Latest Data</Button>
                                        </div>
                                    </div>
                                    <DataTable
                                        className="cm-category-admin-table"
                                        columns={columnsLable}
                                        data={adminAllQRCode}
                                        paginationPerPage={10}
                                        pagination={isPaginate}
                                        onSelectedRowsChange={handleRowSelected}
                                        paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
                                        selectableRows
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <Print
                ref={myPrint => printDom = myPrint} lazyRender isIframe={false}
                title="Print QR Codes"
            >
                <div className="cm-print-data">
                    {selectedRows.length > 0 && selectedRows.map((item, index) => (
                        <p key={`qr-codes-${index}`} className="text-center flex items-center justify-center">
                            <div>
                                <h4><strong>Serial No: </strong> {item.qr_sr_no}</h4>
                                <img src={item.qrcode} alt={item.qr_sr_no} />
                            </div>
                        </p>
                    ))}
                </div>
            </Print>
        </>
    )
}

export default AdminQRCodeList;