import React from 'react';
import MenuLineIcon from 'remixicon-react/MenuFillIcon';
import { useNavigate } from 'react-router-dom';
import Logo from "../assets/images/RoboKidzLogo.svg";



function TopBarcomponent(props) {
        const { profile } = props;
        const navigate = useNavigate();
        return (
                <>
                        <div className='bg-white w-full z-10 px-4 py-4 md:hidden fixed left-0 right-0 top-0'>
                                <div className='flex flex-row items-center'>
                                        <div className='md:hidden'>
                                                <MenuLineIcon onClick={props.onMenuButtonClick} />
                                        </div>
                                        <img src={Logo} alt='Robokidz Logo' className='w-[5.93rem]  m-auto'/>
                                </div>
                        </div>
                </>
        )
}
export default TopBarcomponent;