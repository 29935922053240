import { useFormik } from "formik";
import { useState } from "react";

import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AxiosMain from "../Components/AxiosMain";
import { setAuthState } from "../redux/features/authStateSlice";
import { removeAuthState } from "../redux/features/authStateSlice";
import { removeB2BDBState } from "../redux/features/b2bDBStateSlice";
import { removePersonalDBState } from "../redux/features/personalDBStateSlice";
import Logo from "../assets/images/RoboKidzLogo.svg";
import BackdropLoginPage from "../assets/images/BackdropLogin.svg";
import AccountCircleFillIcon from "remixicon-react/AccountCircleFillIcon";
import { Alert } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";
import MobileBackdrop from "../assets/images/MobileBackdrop.svg";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState(false);

  const loginSchema = Yup.object().shape({
    mobile: Yup.string()
      .required("Please Enter Mobile Number")
      .matches(/^[0-9]+$/, "Only number allowed")
      .min(10, "Minimum 10 Digits Required")
      .max(10, "Max 10 Digit Required"),
    password: Yup.string().required("Please Enter a Valid Password"),
  });

  const loginFormik = useFormik({
    initialValues: {
      mobile: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values, { resetForm }) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      AxiosMain.post("/check-phone-number/", values.mobile, config)
        .then((res) => {
          const { response } = res;

          if (response.status === 200) {
            if (response.data !== "") {
              setErrorMsg(res.response.data.mobile[0]);
            } else {
              setErrorMsg("Something Went Wrong!");
            }
          } else {
            setErrorMsg("Something Went Wrong!");
          }
        })
        .catch((e) => {
          if (e.response.status === 400) {
            AxiosMain.post("/login/", values, config)
              .then((res) => {
                if (res.status === 200) {
                  const { access } = res.data;
                  const datauser = jwt_decode(access);
                  const usePayload = {
                    user: datauser,
                    token: res.data,
                  };
                  if (datauser.is_loggedIn === true) {
                    dispatch(removeAuthState());
                    dispatch(removePersonalDBState());
                    dispatch(removeB2BDBState());
                    setErrorMsg("User Already Logged in");
                    // navigate("/login");
                  } else {
                    dispatch(setAuthState(usePayload));
                    setErrorMsg(false);
                    navigate("/");
                  }
                }
              })
              .catch((e) => {
                resetForm();
                if (e.response?.data) {
                  setErrorMsg("Invalid mobile number or password");
                } else {
                  setErrorMsg("Invalid mobile number or password");
                }
              });
          } else {
            if (e.status === 400) {
              if (e.data !== "") {
                setErrorMsg(e.data.mobile[0]);
              } else {
                setErrorMsg("Something Went Wrong!");
              }
            } else {
              setErrorMsg("Something Went Wrong!");
            }
          }
        });
    },
  });

  return (
    <>

      <div
        className="h-screen relative w-screen flex md:items-start lg:items-center bg-cover bg-no-repeat bg-blend-multiply
      md: bg-right-bottom"
        style={{
          backgroundImage: `
        linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%),
      url(${BackdropLoginPage})`,
        }}
      >
        <div className="lg:hidden absolute z-0 h-screen w-full  flex flex-col">
          <div className="relative h-full w-full">
            <div className="h-full w-full absolute z-10  bg-gradient-to-b from-[#0E2640] to-[#0060B9] " />
            <div className="h-1/3 w-full absolute bottom-0 z-20 bg-cover bg-right-bottom bg-no-repeat"
              style={{
                backgroundImage: `
                linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 80%),
                url(${MobileBackdrop})`,
              }} >

            </div>
            <div className="h-full w-full absolute z-30  bg-gradient-to-b from-[#0E2640] to-[#0060B980] " />

          </div>
        </div>
        <div className="flex w-full items-start lg:p-0 md:px-[10rem] pt-4 px-4 z-40">
          <div className="lg:w-1/2 w-full flex flex-col justify-center lg:pl-28 lg:pr-28 ">
            <img className="w-[9rem] h-[4rem] mb-4" src={`${Logo}`} alt="Robokidz Logo" />
            <div className="self-stretch text-white text-[20px] leading-7 font-bold mb-4">
              Discover Robokidz's Creative Learning
            </div>
            <div className="pt-8 lg:px-10 px-6 pb-5 mt-8 bg-white rounded-2xl shadow justify-start inline-flex">
              <form
                onSubmit={loginFormik.handleSubmit}
                className="flex flex-col w-full gap-y-6"
              >
                {errorMsg && (
                  <Alert color="failure" icon={HiInformationCircle}>
                    <span>
                      <p>
                        <span className="font-medium"> </span>
                        {errorMsg}
                      </p>
                    </span>
                  </Alert>
                )}
                <h1 className="text-[1.75rem] text-black font-bold leading-8">
                  Sign in
                </h1>
                <div className="flex flex-col gap-y-4">
                  <input
                    type="number"
                    maxLength={10}
                    aria-label="mobile"
                    name="mobile"
                    placeholder="Mobile Number"
                    onChange={loginFormik.handleChange}
                    value={loginFormik.values.mobile}
                    className="h-12 w-full p-4 bg-[#FAFAFA] rounded-lg border border-[#ECECEC] text-black text-sm font-[600] leading-5"
                  />
                  {loginFormik.errors.mobile && loginFormik.touched.mobile && (
                    <p className="form-error  mb-0 pb-4">
                      {loginFormik.errors.mobile}
                    </p>
                  )}
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    aria-label="password"
                    onChange={loginFormik.handleChange}
                    onBlur={loginFormik.handleBlur}
                    value={loginFormik.values.password}
                    className="h-12 w-full p-4 bg-neutral-50 rounded-lg border border-gray-200 justify-start items-start inline-flex text-black text-sm font-medium leading-tight"
                  />
                  {loginFormik.errors.password && loginFormik.touched.password && (
                    <p className="form-error  mb-0">
                      {loginFormik.errors.password}
                    </p>
                  )}
                  <Link
                    className="flex flex-row items-center cursor-pointer"
                    type="link"
                    to={"/forget-password"}
                  >
                    <p className="text-sky-700 text-sm font-bold leading-loose">
                      Forgot Password?
                    </p>
                  </Link>
                </div>
                <button
                  type="submit"
                  className="h-12 mt-2 bg-[#0060B9] rounded-lg flex-col justify-center items-center gap-2 inline-flex text-white text-sm font-semibold leading-tight"
                >
                  Login
                </button>
                <Link
                  className="flex flex-row items-center mt-4 cursor-pointer"
                  type="link"
                  to={"/register"}
                >
                  <AccountCircleFillIcon color="#0B54A4" />
                  <p className="text-sky-700 text-sm font-bold leading-loose ml-[0.75rem]">
                    Create Account
                  </p>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
