import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import shape1 from "../../assets/svgs/shapes/Type=Ring.svg";
import shape2 from "../../assets/svgs/shapes/Type=Bohot_Sides.svg";
import shape4 from "../../assets/svgs/shapes/Type=Cube.svg";
import shape5 from "../../assets/svgs/shapes/Type=Flat_Kuch_Sides.svg";
import shape6 from "../../assets/svgs/shapes/Type=Kuch_Sides.svg";

import AxiosMain from "../../Components/AxiosMain";
import {
  setPersonalDBVideoCategory,
  setPersonalDBVideoData,
} from "../../redux/features/personalDBStateSlice";
import KitCard from "../../Components/KitCard";
import "../../styles/PersonalDashboard.css";
import * as Yup from "yup";

import AddKitModalContent from "../../Components/AddKitModalContent";

export let categories = [];

function PersonalDashboard() {
  const dispatch = useDispatch();
  const [videoPost, setVideoPost] = useState([]);
  const [isCPLoading, setIsCPLoading] = useState(true);
  const userDBVideo = useSelector((state) => state.dashboard.videoData);
  const userDBVideoCat = useSelector((state) => state.dashboard.videoCategory);
  const userauth = useSelector((state) => state.auth);
  const profileData = useSelector((state) => state.auth.profiledata);
  const [errorMsg, setErrorMsg] = useState(false);

  const addkitSchema = Yup.object().shape({
    qrcode_no: Yup.string().required("Enter Kit Serial Number"),
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userauth.data?.token.access}`,
    },
  };

  const [openModal, setOpenModal] = useState();
  const [isKitAdded, setIsKitAdded] = useState(false);

  const randomizedPastelColors = [
    "linear-gradient(135deg, #CBEAFF 0%, rgba(203, 234, 255, 0.19) 100%)",
    "linear-gradient(133deg, #EEDAFF 0%, rgba(238, 218, 255, 0.20) 100%)",
    "linear-gradient(135deg, #FFE0C3 0%, rgba(255, 224, 195, 0.20) 100%)",
    "linear-gradient(135deg, #C1EC8B 0%, rgba(193, 236, 139, 0.20) 100%)",
    "linear-gradient(135deg, #FFEFB8 0%, rgba(255, 239, 184, 0.20) 100%)",
    "linear-gradient(135deg, #CAE7FF 0%, rgba(202, 231, 255, 0.20) 100%)",
  ];

  const syncShapeWithColor = [shape1, shape4, shape5, shape2, shape5, shape6];

  const arrayOfCategories = videoPost.map((item) => item.name);
  categories = arrayOfCategories;

  localStorage.setItem("arrayOfCategories", JSON.stringify(arrayOfCategories));

  const personaladdkitFormik = useFormik({
    initialValues: {
      qrcode_no: "",
    },
    validationSchema: addkitSchema,
    onSubmit: (values, { resetForm }) => {
      if (profileData.email_verified) {
        setIsCPLoading(true);
        AxiosMain.post("/kits/activate-kit/", values, config)
          .then((res) => {
            if (res.status === 200) {
              setErrorMsg(false);
              setIsKitAdded(true);
            }
            setIsCPLoading(false);
          })
          .catch((e) => {
            const { response } = e;
            resetForm();
            setIsCPLoading(false);
            if (response.status === 400) {
              // const { non_field_errors } = response.data;
              setErrorMsg("Error, Please use a valid code");
            } else {
              setErrorMsg(
                "Something went wrong!, Please contact our support team."
              );
            }
          });
      } else {
        setErrorMsg("Please Verify Your Email");
      }
    },
  });

  const props = {
    isKitAdded,
    setErrorMsg,
    isCPLoading,
    personaladdkitFormik,
    errorMsg,
    setOpenModal,
  };

  useEffect(() => {
    if (userDBVideoCat.length > 0 && userDBVideo.length > 0) {
      let mainData = [];
      userDBVideoCat.map((catval) => {
        const categoryName = catval.name;
        let op = {};
        userDBVideo.map((item) => {
          const vidocat = item.kit.category.name;
          if (categoryName === vidocat) {
            op.videos = item.kit.category.videos;
          }
        });
        mainData.push({ name: categoryName, videos: op.videos });
      });
      mainData.reverse();
      setVideoPost(mainData);
    }
  }, [userDBVideoCat]);

  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userauth.data?.token.access}`,
      },
    };
    AxiosMain.get("/kits/user-videos/", config)
      .then((response) => {
        if (response.status === 200) {
          let categorylist = [];
          if (response.data.results.length > 0) {
            response.data.results.map((item) => {
              if (item.kit !== null) {
                if (
                  item.kit?.category?.name !== "" &&
                  item.kit?.category?.videos.length > 0
                ) {
                  const checkName = (obj) =>
                    obj.name === item.kit?.category.name;
                  if (!categorylist.some(checkName)) {
                    categorylist.push({
                      id: item.kit?.category.id,
                      name: item.kit?.category.name,
                    });
                  }
                }
              }
            });
            dispatch(setPersonalDBVideoCategory(categorylist));
            dispatch(setPersonalDBVideoData(response.data.results));
          }
        }
        setIsCPLoading(false);
      })
      .catch((e) => {
        console.log("Error ", e);
        setIsCPLoading(false);
      });
  }, []);

  return (
    <>
      <div>
        <div className="pt-5 lg:p-5 xl:p-5 2xl:p-5">
          <div className="flex flex-col">
            <div className="mt-8 mb-14">
              <h2 className="text-[#0B54A4] text-2xl font-bold">
                👋 Hey {profileData.fullname}
              </h2>
              <h1 className="text-stone-900 text-3xl font-bold mt-4">
                Welcome to Robokidz!
              </h1>
            </div>

            <div
              className="w-auto gap-10 flex-wrap"
              style={{
                display: "flex",
                // gridTemplateColumns: "repeat(auto-fit, minmax(384px, 1fr))",
                justifyContent: "left",
              }}
            >
              <div onClick={() => setOpenModal(true)} className="w-96">
                <KitCard />
              </div>
              {videoPost.map((item, index) => {
                return (
                  <div key={index} className="w-96">
                    <Link to={`/dashboard/${item.name}`}>
                      <div
                        className="relative w-[364px] h-[247px] pt-10 pl-8 pr-8 rounded-3xl flex"
                        style={{
                          background:
                            randomizedPastelColors[
                              index % randomizedPastelColors.length
                            ],
                        }}
                      >
                        <p className="w-full break-words text-[#000000A6] text-2xl font-extrabold">
                          {item.name}
                        </p>

                        <img
                          src={
                            syncShapeWithColor[
                              index % syncShapeWithColor.length
                            ]
                          }
                          alt="description"
                          className="absolute bottom-0 right-0 rounded-br-box"
                        />
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {openModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-[#00000080] flex justify-center items-center">
          <div className="bg-white rounded-2xl min-w-[33%] relative shadow">
            <AddKitModalContent {...props} />
          </div>
        </div>
      )}
    </>
  );
}

export default PersonalDashboard;
