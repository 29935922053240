import DashboardFillIcon from "remixicon-react/DashboardFillIcon";
import QuestionnaireFillIcon from "remixicon-react/QuestionnaireFillIcon";
import ProfilePage from "../Components/ProfilePage";
import PersonalDashboard from "../pages/Personal/PersonalDashboard";
import PersonalQueryList from "../pages/Personal/query/PersonalQueryList";
import CategoryVideos from "../pages/Personal/dashboard/videos/CategoryVideos";

/* To bring sub menus
 
// Attempt to read from local storage
const arrayOfCategoriesFromStorage = localStorage.getItem("arrayOfCategories");

// Check for null or empty string before parsing and mapping
const arrayOfCategories = arrayOfCategoriesFromStorage
  ? JSON.parse(arrayOfCategoriesFromStorage)
  : [];

// Now it's safe to call .map()
const child = arrayOfCategories.map((category) => {
  return {
    label: category,
    link: `/dashboard/${category}`,
  };
});

*/

const personalSidebarRoutes = [
  {
    label: "Dashboard",
    icon: <DashboardFillIcon color="#A3A3A3" />,
    link: "/dashboard",
    component: "Hello",
    // child: child,
    child: [],
  },
  {
    label: "Query",
    icon: <QuestionnaireFillIcon color="#A3A3A3" />,
    //icont: <QuestionnaireFillIcon className=' mx-12 h-8 w-7' color='#fff' />,
    link: "/query",
    child: [],
  },
];
const personalRoutes = [
  {
    link: "/",
    component: <PersonalDashboard />,
  },
  {
    link: "/login",
    component: <PersonalDashboard />,
  },
  {
    link: "/profile",
    component: <ProfilePage />,
  },
  {
    link: "/dashboard",
    component: <PersonalDashboard />,
  },
  {
    link: "/query",
    component: <PersonalQueryList />,
  },
  {
    link: "/dashboard/:id",
    component: <CategoryVideos />,
  },
];
export { personalSidebarRoutes, personalRoutes };
