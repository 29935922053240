import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from 'yup';
import AxiosMain from "../../../Components/AxiosMain";
import FormsLayout from "../../../Components/FormsLayout";
import LoadingComponent from "../../../Components/LoadingComponent";
import moment from 'moment';
import { removePersonalDBState } from '../../../redux/features/personalDBStateSlice';
import { removeB2BDBState } from '../../../redux/features/b2bDBStateSlice';
import { removeAuthState } from '../../../redux/features/authStateSlice';


function B2BQueryAdd() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isCPLoading, setIsCPLoading] = useState(false);
    const userauth = useSelector((state) => state.auth.data);
    const userData = useSelector((state) => state.auth.data);
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userauth.token.access}`,
        },
    }
    const addQuerySchema = Yup.object().shape({
        query: Yup.string()
            .required("Field is required"),
    });

    const addQuery = useFormik({
        initialValues: {
            query: "",
            date:new Date().toLocaleString()
        },
        validationSchema: addQuerySchema,
        onSubmit: (values) => {
            //console.log(values);
            setIsCPLoading(true);
            AxiosMain.post("/raise-query/", values, config).then((res) => {
                setIsCPLoading(false);
                if (res.status === 201) {
                    Swal.fire({
                        title: 'Done',
                        text: 'Query Successfully submited!',
                        icon: "success",
                        timer: 3000
                    });
                    navigate("/query")
                }
            }).catch((e) => {
                const { response } = e;
                console.log("Error ", response);
                setIsCPLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Something Went Wrong!",
                    icon: "error",
                })
            })
        }
    });

    React.useEffect(()=>{
        const interval = setInterval(()=>{
          var date = new Date(userData.user.exp * 1000 - 36000).toLocaleString();
          var newDate = moment(date,'DD/MM/YYYY HH:mm').toDate();
          var subtractDate = moment(newDate).subtract(1,"hours").toDate()
          var curentDate = new Date();
          var curentTime = moment(curentDate,'DD/MM/YYYY HH:mm').toDate();
          if(subtractDate === curentTime){
            AxiosMain.get("/logout/", config).then((res) => {
              if (res.status === 200) {
                Swal.fire({
                  title: 'Done',
                  text: res.data.data,
                  icon: "success",
                  timer: 3000,
                  showConfirmButton: false
                })
                
                dispatch(removeAuthState());
                dispatch(removePersonalDBState());
                dispatch(removeB2BDBState());
                navigate("/")
              }
            }).catch((e) => {
              if (e.response?.data) {
                Swal.fire({
                  title: 'Warning',
                  text: `Something Went Wrong!`,
                  icon: "warning",
                })
              }
              else {
                Swal.fire({
                  title: 'Warning',
                  text: `Something Went wrong!`,
                  icon: "warning",
                })
              }
            })
          }else if(subtractDate <= curentTime){
            console.log('else if called')
            AxiosMain.get("/logout/", config).then((res) => {
              if (res.status === 200) {
                Swal.fire({
                  title: 'Done',
                  text: res.data.data,
                  icon: "success",
                  timer: 3000,
                  showConfirmButton: false
                })
                
                dispatch(removeAuthState());
                dispatch(removePersonalDBState());
                dispatch(removeB2BDBState());
                navigate("/")
              }
            }).catch((e) => {
              if (e.response?.data) {
                Swal.fire({
                  title: 'Warning',
                  text: `Something Went Wrong!`,
                  icon: "warning",
                })
              }
              else {
                Swal.fire({
                  title: 'Warning',
                  text: `Something Went wrong!`,
                  icon: "warning",
                })
              }
            })
          }
          else{
            Swal.fire({
              title: 'Warning',
              text: `Something Went wrong!`,
              icon: "warning",
            })
          }
    
        },[3600000]);
        return () => clearInterval(interval);
      },[])

    return (
        <>
            <div className="bg-white p-5 min-h-[50%]">
                <div className='flex flex-row md:w-full mb-3 w-full justify-center'>
                    <div className="shadow rounded-[10px] border p-3 basis-full md:basis-full lg:basis-1/2">
                        <h2 className="cm-kits-form-title flex text-xl lg:text-3xl font-normal text-[#5a5c69] gap-y-2 mb-4">Please raise your query here</h2>
                        <LoadingComponent isLoading={isCPLoading} component={(
                            <form onSubmit={addQuery.handleSubmit} className='flex flex-col w-full'>
                                <div className='flex flex-col w-full'>
                                    <FormsLayout touched={addQuery.touched.name} placeholder="Name" errors={addQuery.errors.name} type="textarea" name="query" form={addQuery} values={addQuery.values.query} />
                                    <button className='btn btn-primary border text-xs md:text-sm bg-[#4e73df] mt-4 p-2 md:p-4 rounded-[8px] text-[#fff]' type="submit">Raise Query</button>
                                </div>
                            </form>
                        )} />

                    </div>

                </div>
            </div>

        </>
    )
}

export default B2BQueryAdd;