import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import VideoAccordion from "../../Components/AccordionComponent/VideoAccordion";
import AxiosMain from "../../Components/AxiosMain";
import LoadingComponent from "../../Components/LoadingComponent";
import { setB2BDBVideoClass, setB2BDBVideoData } from "../../redux/features/b2bDBStateSlice";

function B2BDashboardPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [videoPost, setVideoPost] = useState([]);
    const [isCPLoading, setIsCPLoading] = useState(true);
    const userDBVideo = useSelector((state) => state.dashboardb2b.videoData);
    const userDBVideoClass = useSelector((state) => state.dashboardb2b.videoClass);
    const userauth = useSelector((state) => state.auth.data);
    useEffect(() => {
        if (userDBVideoClass.length > 0 && userDBVideo.length > 0) {
            let mainData = [];
            userDBVideoClass.map((className) => {
                const name = className.name;
                let op = {};
                userDBVideo.map((item) => {
                    item.videoclass.map((videItm) => {
                        let tempName = videItm.name;
                        if (name === tempName) {
                            op.videos = videItm.videos
                        }
                    })
                })
                mainData.push({ name: name, videos: op.videos })
            })
            setVideoPost(mainData);
        }
    }, [userDBVideoClass])
    useEffect(() => {
        //console.log("B2B ", userDBVideo);
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${userauth?.token.access}`
            },
        }
        if (userDBVideo.length === 0) {
            AxiosMain.get("/kits/user-videos/", config).then((response) => {
                if (response.status === 200) {
                    let classlist = [];
                    if (response.data.results.length > 0) {
                        response.data.results.map(item => {
                            if (item.videoclass.length > 0) {
                                item.videoclass.map((videItm) => {
                                    if (videItm.videos.length > 0) {
                                        const checkName = obj => obj.name === videItm.name;
                                        if (!classlist.some(checkName)) {
                                            classlist.push({
                                                id: videItm.id,
                                                name: videItm.name
                                            });
                                        }
                                    }

                                })
                            }
                        });
                        dispatch(setB2BDBVideoClass(classlist));
                        dispatch(setB2BDBVideoData(response.data.results))
                    }
                    else {
                        Swal.fire({
                            title: "No Data Found!",
                            text: "Please Add Kit to see Data",
                            icon: "info",
                            timer: 2000,
                            showConfirmButton: false
                        })
                    }
                }
            })
        }
    }, [])
    useEffect(() => {
        if (videoPost.length > 0) {
            setIsCPLoading(false);
        }
        else if (videoPost.length === 0 && isCPLoading === true) {
            setIsCPLoading(false);
        }
    }, [videoPost, isCPLoading])

    return (
        <>
            <div className='p-5'>
                <div className='flex flex-col'>
                    <h1 className='flex text-3xl font-normal text-[#5a5c69] gap-y-2'>Videos</h1>
                    <LoadingComponent
                        isLoading={isCPLoading}
                        component={videoPost.length > 0 ? (
                            <div className='flex flex-col gap-y-4 mt-5'>
                                {userDBVideoClass?.map((cat, index) => (
                                    <VideoAccordion cat={cat} post={videoPost} index={index} key={`db-video-${index}`} />
                                ))}
                            </div>
                        ) : (
                            <div className='mt-3 shadow rounded-[10px] border p-3 basis-full md:basis-full lg:basis-1/2 xl:basis-1/3'>
                                No Data Found
                            </div>
                        )}
                    />

                </div>
            </div>
        </>
    )
}

export default B2BDashboardPage;