import React, { useRef } from "react";
import PropTypes from 'prop-types';
import { Badge, Button, Checkbox, Dropdown, Label, Select } from "flowbite-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAdminCategory } from "./adminHelper";
import AxiosMain from "./AxiosMain";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import ArrowDownSLineIcon from "remixicon-react/ArrowDownSLineIcon"
import LoadingComponent from "./LoadingComponent";
import { boolean, string } from "yup";

function CustomDropDown({ url, isMultiple, placeholder, saveData, defaultValue }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isCPLoading, setIsCPLoading] = useState(true);
    const [totalcount, setTotalCount] = useState(0);
    const [totalPost, setTotalPost] = useState(0);
    const [isDataEmpty, setIsDataEmpty] = useState(false);
    const [selectOptions, setSelectOptions] = useState([]);
    const [selectedValue, setSelectedValues] = useState([]);
    const [paged, setPaged] = useState(1);
    const userauth = useSelector((state) => state.auth.data);
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userauth.token.access}`,
        },
    }
    const multiDropDown = useFormik({
        initialValues: {
            selectData: isMultiple ? [] : "",
        },
        onSubmit: (values) => {
            const allData = values.selectData.filter((item) => parseInt(item));
            console.log("DropDown Selected Value", allData);
        }
    })
    const getDropDownData = (paged) => {
        setIsCPLoading(true);
        AxiosMain.get(`${url}?page=${paged}`, config).then((res) => {
            console.log(`${paged} Page Data`);
            console.log("Response ", res);
            if (res.status === 200) {
                setPaged(paged);
                if (res.data.count > 0) {
                    setTotalPost(res.data.count);
                    setTotalCount(Math.ceil(res.data.count / 10))
                }
                if (res.data.count === 0) {
                    setIsDataEmpty(true);
                }
                if (paged === 1) {
                    setSelectOptions(res.data.results);
                }
                else {
                    const newData = [...selectOptions, ...res.data.results];
                    setSelectOptions(newData)
                }
            }
            setIsCPLoading(false);
        }).catch((e) => {
            const { response } = e;
            setIsCPLoading(false);
            if (paged > totalcount) {
                Swal.fire({
                    title: "Info",
                    text: "No Data Found!",
                    icon: 'warning'
                })
            }
            console.log("Category Errors found! while in DropDown ", response);
        })

    }
    // useEffect(() => {
    //     if (defaultValue.length > 0) {
    //         console.log("defaultValue ", defaultValue)
    //         const getexistval = defaultValue.map((item) => `${item.id}`);
    //         multiDropDown.setFieldValue("selectData", getexistval);
    //     }
    // }, [defaultValue])
    useEffect(() => {
        if (multiDropDown.values.selectData.length > 0) {
            let dataOutput = [];
            multiDropDown.values.selectData.map((item) => {
                const index = parseInt(item);
                selectOptions.map((itemd) => {
                    if (index === itemd.id) {
                        dataOutput.push({
                            name: itemd.name,
                            id: itemd.id
                        })
                    }
                })
            })
            setSelectedValues(dataOutput);
        }
        else {
            if (defaultValue.length > 0) {
                let dataOutput = [];
                defaultValue.map((item) => {
                    selectOptions.map((itemd) => {
                        const index = item.id
                        if (index === itemd.id) {
                            dataOutput.push({
                                name: itemd.name,
                                id: itemd.id
                            })
                        }
                    })
                })
                setSelectedValues(dataOutput);
            }
        }
    }, [multiDropDown.values.selectData, selectOptions, defaultValue])
    useEffect(() => {
        if (selectOptions.length === 0) {
            if (!isDataEmpty) {
                getDropDownData(paged);
            }

        }
    }, [selectOptions]);
    useEffect(() => {
        if (isOpen) {
            if (defaultValue.length > 0) {
                console.log("defaultValue ", defaultValue)
                const getexistval = defaultValue.map((item) => `${item.id}`);
                multiDropDown.setFieldValue("selectData", getexistval);
            }
        }
    }, [isOpen])
    // useEffect(() => {
    //     if (multiDropDown.values.selectData.length > 0) {
    //         if (defaultValue.length > 0) {
    //             let dataOutput = [];
    //             multiDropDown.values.selectData.map((item) => {
    //                 const index = parseInt(item);
    //                 selectOptions.map((itemd) => {
    //                     if (index === itemd.id) {
    //                         dataOutput.push({
    //                             name: itemd.name,
    //                             id: itemd.id
    //                         })
    //                     }
    //                 })
    //             })
    //             let saveValueinform = defaultValue.map((item) => `${item.id}`)
    //             const newdata = [...saveValueinform, ...dataOutput];
    //             multiDropDown.setFieldValue("selectData", newdata);
    //         }
    //     } else {
    //         if (defaultValue.length > 0) {
    //             let saveValueinform = defaultValue.map((item) => `${item.id}`)
    //             console.log(saveValueinform)
    //             multiDropDown.setFieldValue("selectData", saveValueinform);
    //         }
    //     }
    // }, [defaultValue]);
        // useEffect(() => {
        //     if (isMultiple) {
        //         console.log("All Form Values", multiDropDown.values);
        //         if (multiDropDown.values.selectData.length > 0) {
        //             // let dataOutput = [];
        //             // multiDropDown.values.selectData.map((item) => {
        //             //     const index = parseInt(item);
        //             //     selectOptions.map((itemd) => {
        //             //         if (index === itemd.id) {
        //             //             dataOutput.push({
        //             //                 name: itemd.name,
        //             //                 id: itemd.id
        //             //             })
        //             //         }
        //             //     })
        //             // })
        //             // if (defaultValue.length > 0) {
        //             //     const newdata = [...defaultValue, ...dataOutput];
        //             //     setSelectedValues(newdata);
        //             // } else {
        //             //     setSelectedValues(dataOutput);
        //             // }
        //             console.log("Existing Values in form", multiDropDown.values.selectData);
        //         }
        //         else {
        //             console.log("New values");
        //             // if (defaultValue.length > 0) {
        //             //     setSelectedValues(defaultValue);
        //             // }
        //         }

        //     } else {
        //         let dataOutput = [];
        //         selectOptions.map((itemd) => {
        //             if (multiDropDown.values.selectData === itemd.id) {
        //                 dataOutput.push({
        //                     name: itemd.name,
        //                     id: itemd.id
        //                 })
        //             }
        //         })
        //         setSelectedValues(dataOutput);
        //     }
        // }, [multiDropDown.values, defaultValue]);
    useEffect(() => {
        if (isMultiple) {
            saveData(selectedValue);
            //console.log("Multiple Vlaues ready for Save", selectedValue);
        } else {
            saveData(selectedValue);
            console.log("Single Vlaues ", selectedValue);
        }
    }, [selectedValue]);
    return (
        <>
            <div className="cm-dropdown-container mb-3 relative">
                {
                    isMultiple ? (
                        <>
                            <div className="flex align-center w-full rounded-[4px] border border-gray-300 px-3 py-2 cursor-default pr-[40px]" onClick={() => setIsOpen(!isOpen)}>
                                <div className="max-w-md mr-auto min-w-0">
                                    <div className="min-w-0 overflow-x-auto flex gap-2 cm-bottom-scrollbar">
                                        {selectedValue.length > 0 ? selectedValue.map((item, index) => (<Badge color="gray" className="w-auto min-w-fit" key={`lable-selected-${index}`}>{item.name}</Badge>)) : placeholder}
                                    </div>
                                </div>
                                <ArrowDownSLineIcon className="mr-[-30px]" />
                            </div>
                            <div className={`${isOpen ? "cm-dropwond-open" : "hidden"} cm-dropdown-data border border-gray-300 mt-3 p-2 rounded-[4px]`}>
                                <LoadingComponent isLoading={isCPLoading} component={(
                                    <>
                                        <form onSubmit={multiDropDown.handleSubmit} className='flex flex-col w-full'>
                                            <div className="cm-dropdown-scroll max-h-[200px] overflow-y-scroll">
                                                {/* {selectOptions.length > 0 && selectOptions.map((item, index) => {
                                                    if (defaultValue.length > 0) {
                                                        const checkval = obj => obj.id === item.id;

                                                        if (defaultValue.some(checkval) ) {
                                                            return defaultValue.map((inneritem, innerindex) => {

                                                                if (inneritem.id === item.id) {
                                                                    return (
                                                                        <div className="flex flex-col gap-4 my-2" id={`${item.id}-${index}`} key={`matching-val-${item.id}-${innerindex}-${index}`}>
                                                                            <div className="flex items-center gap-2 mx-2">
                                                                                <Checkbox checked={true} name="selectData" id={`${item}-${index}`} value={item.id} onChange={multiDropDown.handleChange} />
                                                                                <Label htmlFor={`${item}-${index}`} className="capitalize">{item.name}</Label>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            })
                                                        } else {
                                                            return (<div className="flex flex-col gap-4 my-2" id={`${item.id}-${index}`} key={`${item.id}-${index}`}>
                                                                <div className="flex items-center gap-2 mx-2">
                                                                    <Checkbox name="selectData" id={`${item}-${index}`} value={item.id} onChange={multiDropDown.handleChange} />
                                                                    <Label htmlFor={`${item}-${index}`} className="capitalize">{item.name}</Label>
                                                                </div>
                                                            </div>);
                                                        }
                                                    } else {
                                                        return (
                                                            <div className="flex flex-col gap-4 my-2" id={`${item.id}-${index}`} key={`${item.id}-${index}`}>
                                                                <div className="flex items-center gap-2 mx-2">
                                                                    <Checkbox name="selectData" id={`${item}-${index}`} value={item.id} onChange={multiDropDown.handleChange} />
                                                                    <Label htmlFor={`${item}-${index}`} className="capitalize">New Data {item.name}</Label>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })} */}
                                                {selectOptions.length > 0 && selectOptions.map((item, index) =>
                                                {
                                                    if (multiDropDown.values.selectData.indexOf(`${item.id}`) === -1) {
                                                        return (
                                                            <div className="flex flex-col gap-4 my-2" id={`${item.id}-${index}`} key={`${item.id}-${index}`}>
                                                                <div className="flex items-center gap-2 mx-2">
                                                                    <Checkbox name="selectData" id={`${item}-${index}`} value={item.id} onChange={multiDropDown.handleChange} />
                                                                    <Label htmlFor={`${item}-${index}`} className="capitalize">{item.name}</Label>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <div className="flex flex-col gap-4 my-2" id={`${item.id}-${index}`} key={`${item.id}-${index}`}>
                                                                <div className="flex items-center gap-2 mx-2">
                                                                    <Checkbox checked={true} name="selectData" id={`${item}-${index}`} value={item.id} onChange={multiDropDown.handleChange} />
                                                                    <Label htmlFor={`${item}-${index}`} className="capitalize">{item.name}</Label>
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                }
                                                )}
                                            </div>
                                        </form>
                                        {isDataEmpty && "No Data Found!"}
                                        <Button disabled={selectOptions.length === totalPost ? true : false} size="xs" onClick={() => getDropDownData(paged + 1)}>Load More</Button>
                                    </>
                                )} />
                            </div>
                        </>
                    ) :
                        <>
                            <form onSubmit={multiDropDown.handleSubmit} className='flex flex-col w-full'>
                                <Select id="selectData" name="selectData" onChange={(e) => e.target.value !== 'none' ? multiDropDown.setFieldValue("selectData", parseInt(e.target.value)) : multiDropDown.setFieldValue("selectData", '')} value={multiDropDown.values.selectData}>
                                    <option value="none">{placeholder}</option>
                                    {selectOptions.length > 0 && selectOptions.map((item, index) => (
                                        <option value={item.id} key={`kits-admin-qr-${index}`}>{item.name}</option>
                                    ))}
                                </Select>
                            </form>
                        </>
                }


            </div>
        </>)
}
CustomDropDown.defaultProps = {
    url: "",
    isMultiple: false,
    placeholder: "No Dat Set",
    defaultValue: []
}
CustomDropDown.propTypes = {
    url: PropTypes.string.isRequired,
    isMultiple: PropTypes.bool.isRequired,
    placeholder: PropTypes.string.isRequired,
    saveData: PropTypes.func.isRequired,
    defaultValue: PropTypes.arrayOf(Object)
}
export default CustomDropDown;