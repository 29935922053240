import { Checkbox, Label } from "flowbite-react";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from 'yup';
import AxiosMain from "../../../Components/AxiosMain";
import CustomDropDown from "../../../Components/CustomDropDown";
import FormsLayout from "../../../Components/FormsLayout";
import LoadingComponent from "../../../Components/LoadingComponent";
import { setAdminVideos } from "../../../redux/features/authStateSlice";
function AdminVideoEdit() {
    const location = useLocation();
    const { id, videoData } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isdummyData, setIsDummyData] = useState(false);
    const [isformLoading, setIsFormLoading] = useState(true);
    const [isPaginate, setisPaginate] = useState(false)
    const adminAllCategory = useSelector((state) => state.auth.category);
    const adminAllClasses = useSelector((state) => state.auth.classes);
    const userauth = useSelector((state) => state.auth.data);
    const [categoryName, setCategoryName] = useState('');
    const [videoClassName, setVideoClassName] = useState('');
    const [selectedCat, setSelectedCat] = useState([]);
    const [categoryOptions, setCategoryOption] = useState([]);
    const [selectedClass, setSelectedClass] = useState([]);
    const [classOptions, setClassOption] = useState([]);
    const [editCategoryVal, setEditCategoryVal] = useState([]);
    const [editClassVal, setEditClassVal] = useState([]);
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userauth.token.access}`,
        },
    }
    const addCategorySchema = Yup.object().shape({
        name: Yup.string()
            .required("Field is required"),
        videocategory: Yup.array().min(1).required('Video Category  is required.'),
        videoclass: Yup.array().min(1).required('Video Class is required.'),
    });
    const getvideoData = () => {
        AxiosMain.get("/kits/video/", config).then((res) => {
            if (res.status === 200) {
                if (res.data.count > 0) {
                    dispatch(setAdminVideos(res.data));
                    navigate("/videos")
                }
            }
        }).catch((e) => {
            const { response } = e;
            console.log("Errors ", response);
        })
    }
    const addCategory = useFormik({
        initialValues: {
            id: id,
            name: "",
            videocategory: [],
            videoclass: [],
            is_dummy: isdummyData
        },
        validationSchema: addCategorySchema,
        onSubmit: (values) => {
            setIsFormLoading(true);
            console.log("Edit Video Updating data ", values);
            AxiosMain.patch(`/kits/video/${id}/`, values, config).then((res) => {
                console.log(res.data);
                setIsFormLoading(false);
                if (res.status === 200) {
                    Swal.fire({
                        title: 'Done',
                        text: 'Video Successfully Updated!',
                        icon: "success",
                        timer: 3000
                    });
                    getvideoData();
                }
            }).catch((e) => {
                setIsFormLoading(false);
                const { response } = e;
                console.log("Error ", response);
                if (response.status === 400) {
                    const { data } = response;
                    const { details } = data;
                    Swal.fire({
                        title: "Error",
                        text: "Something Went Wrong!",
                        icon: "error",
                    })
                } else if (response.status === 403) {
                    const { data } = response;
                    const { detail } = data;
                    if (detail !== '') {
                        Swal.fire({
                            title: "Error",
                            text: detail,
                            icon: "error",
                        })
                    }
                }
            })

        }
    });
    useEffect(() => {
        if (id !== '') {
            setIsFormLoading(true);
            const { videoData } = location.state;
            AxiosMain.get(`/kits/video/${id}/`, config).then((res) => {
                console.log("Get All Data ", res);
                const { name, is_dummy, videocategory, videoclass } = res.data;
                addCategory.setFieldValue("name", name);
                addCategory.setFieldValue("is_dummy", is_dummy);
                addCategory.setFieldValue('id', parseInt(id));
                setIsDummyData(is_dummy);
                if (videoclass.length > 0) {
                    let dataclass = videoclass.map((iten) => {
                        return ({
                            name: iten.name,
                            id: iten.id
                        })
                    })
                    setEditClassVal(dataclass);
                }
                if (videocategory.length > 0) {
                    let datacat = videocategory.map((iten) => {
                        return ({
                            name: iten.name,
                            id: iten.id
                        })
                    })
                    setEditCategoryVal(datacat);
                }
                setIsFormLoading(false);
            }).catch((e) => {
                console.log("Unable to get Data", e);
            })

            // if (Object.keys(videoData).length > 0) {
            //     //console.log("videoData", videoData);
            //     const { name, is_dummy, videocategory, videoclass } = videoData;
            //     addCategory.setFieldValue("name", name);
            //     addCategory.setFieldValue("is_dummy", is_dummy);
            //     if (videoclass.length > 0) {
            //         let dataclass = videoclass.map((iten) => {
            //             return ({
            //                 name: iten.name,
            //                 id: iten.id
            //             })
            //         })
            //         setEditClassVal(dataclass);
            //     }
            //     if (videocategory.length > 0) {
            //         let datacat = videocategory.map((iten) => {
            //             return ({
            //                 name: iten.name,
            //                 id: iten.id
            //             })
            //         })
            //         setEditCategoryVal(datacat);
            //         console.log("videocategory ", datacat);
            //     }
            // }
        }
    }, []);
    useEffect(() => {
        if (Object.keys(adminAllCategory).length > 0) {
            const catData = adminAllCategory.results.map((item) => {
                return {
                    label: item.name,
                    value: item.id
                }
            })
            setCategoryOption(catData);
        }
        if (Object.keys(adminAllClasses).length > 0) {
            const classData = adminAllClasses.results.map((item) => {
                return {
                    label: item.name,
                    value: item.id
                }
            })
            setClassOption(classData);
        }

    }, [adminAllClasses, adminAllCategory]);
    useEffect(() => {
        if (selectedCat.length > 0) {
            let formValue = [];
            selectedCat.map((item) => {
                // formValue.push({
                //     value: item.id,
                //     label: item.name
                // });
                formValue.push(item.id)
            })
            addCategory.setFieldValue("videocategory", formValue);
        }
        if (selectedClass.length > 0) {
            let formValue = [];
            selectedClass.map((item) => {
                // formValue.push({
                //     id: item.id,
                //     name: item.name
                // });
                formValue.push(item.id)
            })
            addCategory.setFieldValue("videoclass", formValue)
        }
        // if (selectedClass.length > 0 || selectedCat.length > 0) {
        //     console.log("selectedCat ", selectedCat);
        //     console.log("selectedClass ", selectedClass);
        // }
    }, [selectedCat, selectedClass]);

    return (
        <>
            <div className="bg-white p-5 min-h-[50%]">
                <div className='flex flex-row md:w-full mb-3 w-full justify-center'>
                    <div className="shadow rounded-[10px] border p-3 basis-full md:basis-full lg:basis-1/2 ">
                        <h2 className="cm-kits-form-title flex text-xl lg:text-3xl font-normal text-[#5a5c69] gap-y-2 mb-4">Video Edit</h2>
                        <LoadingComponent
                            isLoading={isformLoading}
                            component={(
                                <form onSubmit={addCategory.handleSubmit} className='flex flex-col w-full cm-form-add'>
                            <div className='flex flex-col w-full'>
                                <div className="mb-3">
                                            <FormsLayout touched={addCategory.touched.name} placeholder="Name" errors={addCategory.errors.name} type="text" name="name" form={addCategory} values={addCategory.values.name} />
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="videocategory" className="ml-2">Select an option of Category</Label>
                                            <CustomDropDown defaultValue={editCategoryVal} url="/kits/category/" isMultiple={true} placeholder="Select Category" saveData={setSelectedCat} />
                                    {addCategory.errors.videocategory && addCategory.touched.videocategory && (
                                        <p className='form-error  mb-0'>{addCategory.errors.videocategory}</p>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="videoclass" className="ml-2">Select an option of Class</Label>
                                            <CustomDropDown defaultValue={editClassVal} url="/kits/class/" isMultiple={true} placeholder="Select Classes" saveData={setSelectedClass} />
                                    {addCategory.errors.videoclass && addCategory.touched.videoclass && (
                                        <p className='form-error  mb-0'>{addCategory.errors.videoclass}</p>
                                    )}
                                </div>
                                <div>
                                            <Checkbox id="is_dummy" onChange={addCategory.handleChange} checked={addCategory.values.is_dummy} />
                                    <Label htmlFor="is_dummy" className="ml-2">Is uploaded Video is dummy or not .</Label>
                                </div>
                                        <button className='btn btn-primary border text-xs md:text-sm bg-[#4e73df] mt-4 p-2 md:p-4 rounded-[8px] text-[#fff]' type="submit">Update</button>
                            </div>
                        </form>
                            )}
                        />

                    </div>

                </div>
            </div >

        </>
    )
}

export default AdminVideoEdit;