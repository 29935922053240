import { useFormik } from "formik";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AxiosMain from "../Components/AxiosMain";
import { setAuthState } from "../redux/features/authStateSlice";
import "aos/dist/aos.css";
import WhatsappFillIcon from "remixicon-react/WhatsappFillIcon";
import Logo from "../assets/images/RoboKidzLogo.svg";
import BackdropLoginPage from "../assets/images/BackdropLogin.svg";
import LoginBoxLineIcon from "remixicon-react/LoginBoxLineIcon";
import { Alert } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";
import MobileBackdrop from "../assets/images/MobileBackdrop.svg";

function RegisterPage() {
  const dispatch = useDispatch();
  const [isMobileStatus, setisMobileStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [userMobile, setUserMobile] = useState("");
  const navigate = useNavigate();
  const mobileSchema = Yup.object().shape({
    mobile: Yup.string()
      .required("Please Enter Mobile Number")
      .matches(/^[0-9]+$/, "Only number allowed")
      .min(10, "Minimum 10 Digit Required")
      .max(10, "Max 10 Digit Required"),
  });
  const mobileForm = useFormik({
    initialValues: {
      mobile: "",
    },
    validationSchema: mobileSchema,
    onSubmit: (val) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      AxiosMain.post("/check-phone-number/", val, config)
        .then((res) => {
          if (res.status === 200) {
            AxiosMain.post("/generate-otp/", val, config)
              .then((res) => {
                if (res.status === 201) {
                  console.log("res otp if called");
                  setUserMobile(val.mobile);
                  setErrorMsg(false);

                  setisMobileStatus(true);
                }
              })
              .catch((e) => {
                const { response } = e;

                if (response.status === 400) {
                  if (response.data !== "") {
                    setErrorMsg(e.response.data.mobile[0]);
                  } else {
                    setErrorMsg("Something Went Wrong!");
                  }
                } else {
                  setErrorMsg("Something Went Wrong!");
                }
              });
          } else {
            if (res.status === 400) {
              if (res.data !== "") {
                setErrorMsg(res.data.mobile[0]);
              } else {
                setErrorMsg("Something Went Wrong!");
              }
            } else {
              setErrorMsg("Something Went Wrong!");
            }
          }
        })
        .catch((e) => {
          const { response } = e;

          if (response.status === 400) {
            if (response.data !== "") {
              setErrorMsg(e.response.data.mobile[0]);
            } else {
              setErrorMsg("Something Went Wrong!");
            }
          } else {
            setErrorMsg("Something Went Wrong!");
          }
        });
    },
  });
  const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
  const RegisterSchema = Yup.object().shape({
    fullname: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Only letters and spaces are allowed")
      .required("Enter Full Name"),
    mobile: Yup.string()
      .required("Enter Mobile Number")
      .matches(/^[0-9]+$/, "Only number allowed")
      .min(10, "Minimum 10 Digit Required")
      .max(10, "Max 10 Digit Required"),
    email: Yup.string().email().required("Enter Email"),
    user_type: Yup.string().required("Type is required"),
    otp: Yup.number().required("Enter OTP"),
    password: Yup.string()
      .min(5)
      .matches(passwordRules, { message: "Create a stronger password" })
      .required("Enter Password"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Enter Confirm Password"),
  });
  const RegisterFormik = useFormik({
    initialValues: {
      fullname: "",
      mobile: userMobile,
      email: "",
      user_type: "PersonalUser",
      otp: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      AxiosMain.post("/register/", values, config)
        .then((res) => {
          if (res.status === 201) {
            setErrorMsg(false);
            const { access } = res.data;
            const datauser = jwt_decode(access);

            const usePayload = {
              user: datauser,
              token: res.data,
            };
            dispatch(setAuthState(usePayload));
            navigate("/dashboard");
          }
        })
        .catch((e) => {
          console.log("erroe", e);
          setErrorMsg(e.response.data.non_field_errors[0]);
        });
    },
  });
  useEffect(() => {
    if (userMobile !== "") {
      RegisterFormik.setFieldValue("mobile", userMobile);
    }
  }, [userMobile]);

  return (
    <>
      <div
        className="h-screen relative w-screen flex md:items-start lg:items-center bg-cover bg-no-repeat bg-blend-multiply
      md: bg-right-bottom"
        style={{
          backgroundImage: `
        linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%),
      url(${BackdropLoginPage})`,
        }}
      >
        <div className="lg:hidden absolute z-0 h-screen w-full  flex flex-col">
          <div className="relative h-full w-full">
            <div className="h-full w-full absolute z-10 bg-gradient-to-b from-[#0E2640] to-[#0060B9] " />
            <div
              className="h-1/3 w-full absolute bottom-0 z-20 bg-cover bg-right-bottom bg-no-repeat"
              style={{
                backgroundImage: `
                linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 80%),
                url(${MobileBackdrop})`,
              }}
            >
              {/* <div className="h-full w-full absolute z-10 bg-gradient-to-b from-[#0060B9] to-[#0060B900] border-2 border-violet-500" /> */}
            </div>
            <div className="h-full w-full absolute z-30 bg-gradient-to-b from-[#0E2640] to-[#0060B980] " />
            {/* <div className="h-full w-full absolute z-60 bg-gradient-to-b from-[#0060B9] to-[#0060B900]" /> */}
          </div>
        </div>
        <div className="flex w-full items-start lg:p-0 md:px-[10rem] pt-4 px-4 z-40">
          <div className="lg:w-1/2 w-full flex flex-col justify-center lg:pl-28 lg:pr-28 ">
            <img
              className="w-[9rem] h-[4rem] mb-4"
              src={`${Logo}`}
              alt="Robokidz Logo"
            />
            <div className="self-stretch text-white text-[20px] leading-7 font-bold mb-4">
              Discover Robokidz's Creative Learning
            </div>
            <div className="pt-8 lg:px-10 px-6 pb-5 mt-8 bg-white rounded-2xl shadow justify-start inline-flex">
              {isMobileStatus ? (
                <div className="w-full">
                  <form
                    onSubmit={RegisterFormik.handleSubmit}
                    className=" w-full"
                  >
                    <h1 className="text-[1.75rem] text-black font-bold leading-8 mb-2">
                      Create Account
                    </h1>
                    {errorMsg && (
                      <Alert
                        color="failure"
                        icon={HiInformationCircle}
                        className="my-2"
                      >
                        <span>
                          <p>
                            <span className="font-medium"> </span>
                            {errorMsg}
                          </p>
                        </span>
                      </Alert>
                    )}
                    <div className="w-full text-black text-sm font-bold leading-loose">
                      OTP sent to <span>{userMobile}</span>
                    </div>
                    <div className="flex flex-col mt-4">
                      <div className="flex flex-col space-y-2 mb-6">
                        <label className=" text-black text-sm font-bold leading-tight">
                          OTP
                        </label>
                        <input
                          type="text"
                          placeholder="Enter OTP"
                          aria-label="otp"
                          name="otp"
                          maxLength={4}
                          onBlur={RegisterFormik.handleBlur}
                          onChange={RegisterFormik.handleChange}
                          value={RegisterFormik.values.otp}
                          className="h-12 p-4 bg-neutral-50 rounded-lg border border-gray-200 justify-start items-start inline-flex text-black text-sm font-medium leading-tight"
                        />
                        {RegisterFormik.errors.otp &&
                          RegisterFormik.touched.otp && (
                            <p className="text-red-500 text-sm">
                              {RegisterFormik.errors.otp}
                            </p>
                          )}
                      </div>
                      <div className="flex flex-col space-y-2 mb-6">
                        <label className=" text-black text-sm font-bold leading-tight">
                          Full Name
                        </label>
                        <input
                          type="text"
                          placeholder="Full Name"
                          aria-label="Full Name"
                          name="fullname"
                          onBlur={RegisterFormik.handleBlur}
                          onChange={(e) => {
                            const onlyLetters = /^[A-Za-z\s]+$/;
                            if (
                              e.target.value === "" ||
                              onlyLetters.test(e.target.value)
                            ) {
                              RegisterFormik.handleChange(e);
                            }
                          }}
                          value={RegisterFormik.values.fullname}
                          className="h-12 p-4 bg-neutral-50 rounded-lg border border-gray-200 justify-start items-start inline-flex text-black text-sm font-medium leading-tight"
                        />
                        {RegisterFormik.errors.fullname &&
                          RegisterFormik.touched.fullname && (
                            <p className="text-red-500 text-sm">
                              {RegisterFormik.errors.fullname}
                            </p>
                          )}
                      </div>
                      <div className="flex flex-col space-y-2 mb-6">
                        <label className=" text-black text-sm font-bold leading-tight">
                          Email
                        </label>
                        <input
                          type="email"
                          placeholder="Email"
                          aria-label="email"
                          name="email"
                          onBlur={RegisterFormik.handleBlur}
                          onChange={RegisterFormik.handleChange}
                          value={RegisterFormik.values.email}
                          className="h-12 p-4 bg-neutral-50 rounded-lg border border-gray-200 justify-start items-start inline-flex text-black text-sm font-medium leading-tight"
                        />

                        {RegisterFormik.errors.email &&
                          RegisterFormik.touched.email && (
                            <p className="text-red-500 text-sm">
                              {RegisterFormik.errors.email}
                            </p>
                          )}
                      </div>
                      <div className="flex flex-col space-y-2 mb-6">
                        <label className=" text-black text-sm font-bold leading-tight">
                          Password
                        </label>
                        <input
                          type="password"
                          placeholder="Password"
                          aria-label="password"
                          name="password"
                          onBlur={RegisterFormik.handleBlur}
                          onChange={RegisterFormik.handleChange}
                          value={RegisterFormik.values.password}
                          className="h-12 p-4 bg-neutral-50 rounded-lg border border-gray-200 justify-start items-start inline-flex text-black text-sm font-medium leading-tight"
                        />

                        {RegisterFormik.errors.password &&
                          RegisterFormik.touched.password && (
                            <p className="form-error  mb-0 ">
                              {RegisterFormik.errors.password}
                            </p>
                          )}
                      </div>
                      <div className="flex flex-col space-y-2 mb-6">
                        <label className=" text-black text-sm font-bold leading-tight">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          placeholder="Confirm Password"
                          aria-label="confirm_password"
                          name="confirm_password"
                          onBlur={RegisterFormik.handleBlur}
                          onChange={RegisterFormik.handleChange}
                          value={RegisterFormik.values.confirm_password}
                          className="h-12 p-4 bg-neutral-50 rounded-lg border border-gray-200 justify-start items-start inline-flex text-black text-sm font-medium leading-tight"
                        />

                        {RegisterFormik.errors.confirm_password &&
                          RegisterFormik.touched.confirm_password && (
                            <p className="form-error  mb-0 ">
                              {RegisterFormik.errors.confirm_password}
                            </p>
                          )}
                      </div>
                      <button
                        type="submit"
                        className=" h-12 px-10 py-4 bg-[#0060B9] rounded-lg flex-col justify-center items-center gap-2 inline-flex text-white text-sm font-semibold leading-tight"
                      >
                        Create Account
                      </button>
                    </div>
                  </form>
                </div>
              ) : (
                <div className="w-full">
                  <form onSubmit={mobileForm.handleSubmit} className="w-full">
                    <h1 className="text-[1.75rem] text-black font-bold leading-8 mb-6">
                      Create Account
                    </h1>
                    {errorMsg && (
                      <Alert
                        color="failure"
                        icon={HiInformationCircle}
                        className="my-2"
                      >
                        <span>
                          <p>
                            <span className="font-medium"> </span>
                            {errorMsg}
                          </p>
                        </span>
                      </Alert>
                    )}

                    <div className="flex flex-col space-y-4">
                      <input
                        placeholder="Mobile Number"
                        type="number"
                        aria-label="mobile"
                        name="mobile"
                        maxLength={10}
                        onChange={mobileForm.handleChange}
                        value={mobileForm.values.mobile}
                        className="h-12 p-4 bg-neutral-50 rounded-lg border border-gray-200 justify-start items-start inline-flex text-black text-sm font-medium leading-tight"
                      />
                      {mobileForm.errors.mobile && (
                        <p className="text-red-500 text-sm">
                          {mobileForm.errors.mobile}
                        </p>
                      )}

                      <div className=" h-9 p-2 bg-opacity-5 rounded-lg borderjustify-start items-center gap-2 inline-flex">
                        <WhatsappFillIcon color="#1FAF38" />
                        <div className="text-green-400 text-sm font-medium leading-tight">
                          Whatsapp number is preferred
                        </div>
                      </div>
                      <button
                        type="submit"
                        className=" h-12 px-10 py-4 bg-[#0060B9] rounded-lg flex-col justify-center items-center gap-2 inline-flex text-white text-sm font-semibold leading-tight"
                      >
                        Generate OTP
                      </button>
                    </div>
                  </form>
                  <div className="w-full ">
                    <Link
                      className="flex flex-row items-center cursor-pointer mt-[3.25rem]"
                      type="link"
                      to={"/login"}
                    >
                      <LoginBoxLineIcon color="#0B54A4" />
                      <p className="text-[#0B54A4] font-bold text-[1rem] leading-loose ml-[0.75rem]">
                        Already have an account? Login
                      </p>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegisterPage;
