import React from "react";
import { Route } from 'react-router-dom';
import { adminRoutes } from "./AdminRoutes";
import { b2bRoutes } from "./B2BRoutes";
import { personalRoutes } from "./PersonalRoutes";
const getRoutesByUser = (userType) => {
    if (userType === "ADMIN") {
        return (
            adminRoutes.map((val, index) => (
                <Route exact path={val.link} element={val.component} key={`${userType}-routes-${index}`} />
            ))
        )
    }
    if (userType === "B2B") {
        return (
            b2bRoutes.map((val, index) => (
                <Route exact path={val.link} element={val.component} key={`${userType}-routes-${index}`} />
            ))
        )
    }
    if (userType === "PersonalUser") {

        return (
            personalRoutes.map((val, index) => (
                <Route exact path={val.link} element={val.component} key={`${userType}-routes-${index}`} />
            ))
        )
    }


}
export { getRoutesByUser };
