import { Button, Pagination, Timeline } from "flowbite-react";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AxiosMain from "../../../Components/AxiosMain";
import LoadingComponent from "../../../Components/LoadingComponent";
import { setB2BDBquery } from "../../../redux/features/b2bDBStateSlice";

function B2BQueryList() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [allquery, setAllQuery] = useState([]);
    const [isCPLoading, setIsCPLoading] = useState(false);
    const [isPaginate, setisPaginate] = useState(false)
    const pallquery = useSelector((state) => state.dashboardb2b.query);
    const userauth = useSelector((state) => state.auth.data);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPostCount, setTotalPostCount] = useState(0);
    const [totalPostPages, settotalPostPages] = useState(0);
    const postPerPage = 10;
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userauth.token.access}`,
        },
    }

    const columnsLable = [
        {
            name: 'id',
            sortable: true,
            selector: row => row.id,
            maxWidth: "100px",
        },
        {
            name: 'Query',
            sortable: true,
            selector: row => row.query,
        },
        {
            name: "Remark",
            selector: (row) => {
                if (row.remark !== null) {
                    return (<Button className="border-0" color="light" onClick={() => {
                        Swal.fire({
                            html: '<div class="text-left cm-ans-wrapper">' +
                                '<ul>' +
                                `<li><strong>Query :</strong> ${row.query}</li>` +
                                `<li><strong>Reply :</strong> ${row.remark}</li>` +
                                ' </ul>' +
                                "</div>",
                            showConfirmButton: false
                        })
                    }
                    }>View</Button>)
                }
                else {
                    return ("not yet Reply")
                }
            }
        },
        {
            name: 'Resolved',
            sortable: true,
            selector: row => row.resolved ?
                <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Yes</span>
                :
                <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">No</span>
        },
    ]
    // useEffect(() => {
    //     getPersonalQuery(dispatch, config);
    // }, []);
    // useEffect(() => {
    //     if (pallquery.length > 10) {
    //         setisPaginate(true);
    //     }
    //     setisPaginate(false)
    // }, [pallquery])
    function getUnique(arr, index) {

        const unique = arr
            .map(e => e[index])

            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);

        return unique;
    }
    const getNextData = (page) => {
        setIsCPLoading(true);
        AxiosMain.get(`/raise-query/?page=${page}`, config).then((res) => {
            if (res.status === 200) {
                dispatch(setB2BDBquery(res.data));
                // if (page === 1) {
                //     dispatch(setB2BDBquery(res.data));

                // }
                // else {
                //     const mergeData = [...pallquery.results, ...res.data.results];
                //     const uniqueNames = mergeData.filter((val, id, array) => array.indexOf(val) === id)
                //     const lastval = getUnique(uniqueNames, "id");
                //     const newdata = { ...pallquery, results: lastval };
                //     dispatch(setB2BDBquery(newdata));
                // }
                setCurrentPage(page);
                setIsCPLoading(false);
                //console.log("Data save in redux of page ", page);
            }
        }).catch((e) => {
            console.log("Error While getting Query of user", e.response);
            setIsCPLoading(false);
        })
    }
    const setDataintable = () => {
        if (Object.keys(pallquery).length > 0) {
            const indexOfLastPost = currentPage * postPerPage;
            const indexOfFirstPost = indexOfLastPost - postPerPage;
            const saveresult = parseInt(pallquery.results.length);
            if (indexOfFirstPost === 0 && indexOfLastPost === saveresult) {
                const tempData = pallquery.results.slice(indexOfFirstPost, indexOfLastPost);
                setAllQuery(tempData);
            } else {
                const tempData = pallquery.results.slice(indexOfFirstPost, saveresult);
                setAllQuery(tempData);
            }

            if (indexOfFirstPost > saveresult && indexOfLastPost === saveresult) {
                const tempData = pallquery.results.slice(indexOfFirstPost, indexOfLastPost);
                setAllQuery(tempData);
            } else {
                const tempData = pallquery.results.slice(indexOfFirstPost, saveresult);
                setAllQuery(tempData);
            }
        }
    }
    useEffect(() => {
        if (Object.keys(pallquery).length === 0) {
            getNextData(currentPage);
        }
        if (Object.keys(pallquery).length > 0) {
            setTotalPostCount(pallquery.count);
            settotalPostPages(Math.ceil(pallquery.count / 10));
            if (pallquery.results.length > 0) {
                setAllQuery(pallquery.results);
            }
        }

    }, [pallquery])
    useEffect(() => {
        if (allquery.length > 0) {
            setIsCPLoading(false);
        }
    }, [allquery])
    useEffect(() => {
        if (totalPostPages !== 0) {
            getNextData(currentPage);
            // if (currentPage === 1) {
            //     if (Object.keys(adminKitUser).length > 0) {
            //         setDataintable();
            //     } else {
            //         getNextData(currentPage);
            //     }
            // } else {
            //     if (Object.keys(adminKitUser).length > 0) {
            //         const indexOfLastPost = currentPage * postPerPage;
            //         const indexOfFirstPost = indexOfLastPost - postPerPage;
            //         const saveresult = parseInt(adminKitUser.results.length);
            //         if (indexOfFirstPost === saveresult) {
            //             getNextData(currentPage);
            //         } else {
            //             setDataintable();
            //         }

            //     }
            // }
        }
    }, [currentPage, totalPostPages]);
    return (<div className="bg-white py-5 px-3">
        <div className='md:w-full mb-3 w-full max-w-[700px] mx-auto'>
            <div className="shadow rounded-[10px] border p-3 min-h-[300px]">
                <div className="cm-admin-category-table-wrapper m-0 sm:mb-3">
                    <div className="flex justify-between">
                        <h2 className="cm-kits-form-title flex text-xl lg:text-3xl font-normal text-[#5a5c69] gap-y-2 mb-4">Query List</h2>
                        <Button onClick={() => navigate("/query/add")}>Add Query</Button>
                    </div>
                    <LoadingComponent isLoading={isCPLoading} component={(
                        <Timeline>
                            {
                                allquery.length > 0 && allquery.map((item, index) => (
                                    <Timeline.Item key={`query-index-${index}`}>
                                        <Timeline.Point />
                                        <Timeline.Content>
                                            <Timeline.Title>
                                                <strong>Q </strong>{item.query}
                                            </Timeline.Title>
                                            <Timeline.Body>
                                                {item.remark !== null ? (
                                                    <>
                                                        <strong>A </strong>{item.remark}
                                                    </>
                                                ) : (
                                                    "No yet Reply"
                                                )}
                                            </Timeline.Body>
                                        </Timeline.Content>
                                    </Timeline.Item>
                                ))
                            }
                        </Timeline>
                        // <DataTable
                        //     className="cm-category-admin-table"
                        //     columns={columnsLable}
                        //     data={allquery}
                        //     paginationPerPage={10}
                        //     pagination={isPaginate}
                        //     paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
                        // />
                    )} />
                </div>
            </div>
            <div className="flex justify-center mt-2">
                <Pagination
                    currentPage={currentPage}
                    onPageChange={(data) => setCurrentPage(data)}
                    showIcons={true}
                    totalPages={totalPostPages} />
            </div>
        </div>
    </div>)
}
export default B2BQueryList;