import React from "react";
import { HiInformationCircle } from "react-icons/hi";
import Firefly_3d_hardware from "../assets/images/Firefly_3d_hardware.svg";
import tick from "../assets/images/ep_success-filled.png";
import { Alert } from "flowbite-react";
import CloseFillIcon from "remixicon-react/CloseFillIcon";
import { Spinner } from "flowbite-react";
import "../styles/PersonalDashboard.css";


 
export let categories = [];

function AddKitModalContent(props) {
  return (
    <>
      {!props.isKitAdded && (
        <div className="relative flex flex-col z-10 ">
          <div className="w-full flex flex-row justify-between px-8 pt-10 pb-4 z-10">
            <h2 className="text-white font-bold text-xl">Add Kit</h2>

            <button
              className="text-white font-bold text-xl"
              onClick={() => {
                props.setErrorMsg(false);
                props.setOpenModal(undefined);
              }}
            >
              <CloseFillIcon color="#fff" />
            </button>
          </div>
        </div>
      )}
      {!props.isKitAdded ? (
        <div>
          <div className="absolute top-0 h-1/2 w-full bg-radial-gradient rounded-t-xl"></div>
          <div className="">
            <form
              className=" w-full flex flex-col items-stretch gap-y-4 p-6 "
              onSubmit={props.personaladdkitFormik.handleSubmit}
            >
              <img src={Firefly_3d_hardware} className="z-10 mx-auto" />

              <div className="flex flex-col gap-y-4 w-full">
                
                <input
                  onChange={props.personaladdkitFormik.handleChange}
                  placeholder="Enter Kit Serial Number"
                  errors={props.personaladdkitFormik.errors.qrcode_no}
                  type="text"
                  name="qrcode_no"
                  form={props.personaladdkitFormik}
                  values={props.personaladdkitFormik.values.qrcode_no}
                  className="text-black rounded-lg border-1 border-[#ECECEC] bg-[#FAFAFA]"
                />
                {props.errorMsg && (
                  <Alert color="failure" icon={HiInformationCircle}>
                    <span>
                      <p>{props.errorMsg}</p>
                    </span>
                  </Alert>
                )}
                <button
                  className={`w-full text-white font-bold h-12 rounded-lg border-1 border-[#0060B9] ${
                    !props.personaladdkitFormik.values.qrcode_no ||
                    props.personaladdkitFormik.errors.qrcode_no ||
                    props.personaladdkitFormik.values.qrcode_no === ""
                      ? "bg-[#0060B9bb]" 
                      : "bg-[#0060B9]" 
                  }`}
                  type="submit"
                  disabled={
                    !props.personaladdkitFormik.values.qrcode_no ||
                    props.personaladdkitFormik.errors.qrcode_no ||
                    props.personaladdkitFormik.values.qrcode_no === ""
                  }
                >

                  {props.isCPLoading ? (
                    <Spinner caria-label="Default status example" />
                  ) :
                    "Add"}
                </button>
              </div>
            </form>
            {/* <LoadingComponent
                isLoading={props.isCPLoading}
                component={
                }
              /> */}
          </div>
        </div>
      ) : (
        <div className="bg-radial-gradient flex flex-col items-center p-6 ">
          <img src={Firefly_3d_hardware} className="" />
          <div className="flex flex-row w-full justify-center mb-2">
            <img src={tick} className="w-5 h-5" />
            <h3 className="text-white text-lg ml-4">Kit Added Successfully</h3>
          </div>
          <p className="text-white text-center mb-6">
            Resources for your new kit can now be accessed through
            the dashboard
          </p>
          <button
            className="w-full text-[#0060B9] bg-white h-12 rounded-lg"
            onClick={() => {
              props.setOpenModal(undefined);
              window.location.reload();
            }}
          >
            View Kit Resources
          </button>
        </div>
      )}
    </>
  );
}
export default AddKitModalContent;
