import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import AxiosMain from "../AxiosMain";
import DatePicker from "react-datepicker";
import { setProfileState } from "../../redux/features/authStateSlice";
import "react-datepicker/dist/react-datepicker.css";
import { Alert } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";
import { Spinner } from "flowbite-react";

function UpdateProfileForm(props) {
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isCPLoading, setIsCPLoading] = useState(false);
  const userData = useSelector((state) => state.auth.data);
  const Profiledata = useSelector((state) => state.auth.profiledata);
  const profileSchema = Yup.object().shape({
    fullname: Yup.string().required("Enter Full Name"),
    email: Yup.string().email().required("Enter Email"),
    schoolname: Yup.string().required("Enter School Name"),
    address: Yup.string().required("Enter Address"),
    city: Yup.string().required("Enter City"),
    state: Yup.string().required("Enter State"),
    pin_code: Yup.string()
      .required("Enter Pin Code")
      .min(6, "Minimum 6 Digit Required")
      .max(6, "Maximum 6 Digit Required")
      .matches(/^[0-9]+$/, "Only number allowed"),
    dob: Yup.date().required("Enter Date"),
    mobile: Yup.string()
      .required("Enter mobile number")
      .matches(/^[0-9]+$/, "Only number allowed")
      .min(10, "Minimum 10 Digit Required")
      .max(10, "Maximum 10 Digit Required"),
  });
  const profileformik = useFormik({
    initialValues: {
      fullname: Profiledata?.fullname ? Profiledata?.fullname : "",
      dob: Profiledata?.dob ? new Date(Profiledata?.dob) : "",
      schoolname: Profiledata?.schoolname ? Profiledata?.schoolname : "",
      email: Profiledata?.email ? Profiledata?.email : "",
      mobile: Profiledata?.mobile ? Profiledata?.mobile : "",
      address: Profiledata?.address ? Profiledata?.address : "",
      city: Profiledata?.city ? Profiledata?.city : "",
      state: Profiledata?.state ? Profiledata?.state : "",
      pin_code: Profiledata?.pin_code ? Profiledata?.pin_code : "",
      standed: Profiledata?.standed ? Profiledata?.standed : "",
      user_type: Profiledata?.user_type ? Profiledata.user_type : "",
    },
    validationSchema: profileSchema,
    onSubmit: async (values) => {
      setIsCPLoading(true);

      // Verify the pin code
      try {
        const pinResponse = await AxiosMain.get(
          `https://api.postalpincode.in/pincode/${values.pin_code}`
        );
        const pinData = pinResponse.data[0]; // The API response is an array, so we'll take the first object

        if (pinData.Status === "Error") {
          setErrorMsg("Invalid Pin Code");
          setIsCPLoading(false);
          return;
        }
      } catch (e) {
        setErrorMsg("Error validating pin code");
        setIsCPLoading(false);
        return;
      }

      // If the pin code is valid, proceed to update the profile
      console.log("Update Profile ", values);
      const userId = userData.user.user_id;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.token.access}`,
        },
      };
      AxiosMain.patch(`/my-profile/${userId}/`, values, config)
        .then((res) => {
          if (res.status === 200) {
            dispatch(setProfileState(res.data));
            setErrorMsg(false);
            setSuccessMessage("Profile Successfully Updated!");
            setTimeout(() => {
              setSuccessMessage(false);
            }, 5000);
          }
          setIsCPLoading(false);
        })
        .catch((e) => {
          setIsCPLoading(false);
          console.log(e);

          setErrorMsg(e.response.data.detail);

          setTimeout(() => {
            setErrorMsg(false);
          }, 5000);
        });
    },
  });
  useEffect(() => {
    if (
      Object.keys(userData).length > 0 &&
      Object.keys(Profiledata).length === 0
    ) {
      const userId = userData.user.user_id;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.token.access}`,
        },
      };
      AxiosMain.get(`/my-profile/${userId}/`, config)
        .then((res) => {
          console.log("Profile Data ", res.data);
          if (res.status === 200) {
            dispatch(setProfileState(res.data));
          }
        })
        .catch((e) => {
          setErrorMsg("Unable to get Profile Data");
        });
    } else {
      if (Object.keys(Profiledata).length > 0) {
        profileformik.setFieldValue(
          "fullname",
          Profiledata?.fullname ? Profiledata?.fullname : null
        );
        profileformik.setFieldValue(
          "dob",
          Profiledata?.dob ? new Date(Profiledata?.dob) : null
        );
        profileformik.setFieldValue(
          "schoolname",
          Profiledata?.schoolname ? Profiledata?.schoolname : null
        );
        profileformik.setFieldValue(
          "email",
          Profiledata?.email ? Profiledata?.email : null
        );
        profileformik.setFieldValue(
          "mobile",
          Profiledata?.mobile ? Profiledata?.mobile : null
        );
        profileformik.setFieldValue(
          "address",
          Profiledata?.address ? Profiledata?.address : null
        );
        profileformik.setFieldValue(
          "city",
          Profiledata?.city ? Profiledata?.city : null
        );
        profileformik.setFieldValue(
          "state",
          Profiledata?.state ? Profiledata?.state : null
        );
        profileformik.setFieldValue(
          "pin_code",
          Profiledata?.pin_code ? Profiledata?.pin_code : null
        );
        profileformik.setFieldValue(
          "standed",
          Profiledata?.standed ? Profiledata?.standed : null
        );
        profileformik.setFieldValue(
          "user_type",
          Profiledata?.user_type ? Profiledata?.user_type : null
        );
      }
    }
  }, [userData, Profiledata]);

  useEffect(() => {
    console.log("profile Data Mount");
    setErrorMsg(false);
    setSuccessMessage(false);
  }, []);
  return (
    <>
      <div className="gap-y-4 bg-none rounded-[10px] mb-2 overflow-hidden">
        <div className="flex justify-center items-center gap-y-2">
          <form
            className="cm-register-form block  w-full md:w-full gap-x-4 mx-auto p-1"
            onSubmit={profileformik.handleSubmit}
          >
            <div className="w-full flex flex-row">
              <h1 className="flex text-black font-bold leading-normal text-xl md:text-2xl mb-8 md:justify-left px-1 md:px-0">
                Edit Personal Information
              </h1>
              {/* <CloseFillIcon className='w-8 h-8 cursor-pointer text-black ml-auto' onClick={props.handleCancel} /> */}
            </div>
            {errorMsg && (
              <Alert color="failure" icon={HiInformationCircle}>
                <span>
                  <p>
                    <span className="font-medium"> </span>
                    {errorMsg}
                  </p>
                </span>
              </Alert>
            )}
            {successMessage && (
              <Alert color="success" icon={HiInformationCircle}>
                <span>
                  <p>
                    <span className="font-medium"> </span>
                    {successMessage}
                  </p>
                </span>
              </Alert>
            )}

            <div className="grid grid-cols-1 md:grid-cols-2  mb-3 gap-x-4">
              <div className="w-96 flex-col justify-start items-start gap-1 inline-flex mt-2">
                <label className="self-stretch text-black text-sm font-medium leading-tight">
                  Full Name
                </label>
                <input
                  name="fullname"
                  onChange={profileformik.handleChange}
                  value={profileformik.values.fullname}
                  onBlur={profileformik.handleBlur}
                  className="text-black border-gray-200 bg-[#FAFAFA] rounded-lg h-12 text-sm font-[600] leading-tight w-full"
                  type="text"
                  placeholder="Full Name"
                  aria-label="Full Name"
                />
                {profileformik.errors.fullname &&
                  profileformik.touched.fullname && (
                    <p className="text-red-500 text-sm h-[20px] mt-1">
                      {profileformik.errors.fullname}
                    </p>
                  )}
              </div>
              <div className="w-96 flex-col justify-start items-start gap-1 inline-flex">
                <div className="w-96 flex-col justify-start items-start gap-1 inline-flex mt-2">
                  <label className="self-stretch text-black text-sm font-medium leading-tight">
                    Email
                  </label>
                  <input
                    disabled={Profiledata.email_verified ? true : false}
                    name="email"
                    onChange={profileformik.handleChange}
                    value={profileformik.values.email}
                    onBlur={profileformik.handleBlur}
                    className="text-black border-gray-200 bg-[#FAFAFA] rounded-lg h-12 text-sm font-[600] leading-tight w-full"
                    type="email"
                    placeholder="Email Address"
                    aria-label="email"
                    style={{
                      backgroundColor: Profiledata.email_verified
                        ? "#ccc"
                        : "#FAFAFA",
                    }} // Grey out the background if disabled
                  ></input>

                  {profileformik.errors.email &&
                    profileformik.touched.email && (
                      <p className="text-red-500 text-sm h-[20px] mt-1">
                        {profileformik.errors.email}
                      </p>
                    )}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 mb-3 gap-x-4 mx-auto">
              <div className="w-96 flex-col justify-start items-start gap-2 inline-flex mt-2">
                <label className="text-[#000] text-sm font-[500] leading-tight">
                  Mobile
                </label>
                <input
                  name="mobile"
                  disabled
                  onChange={profileformik.handleChange}
                  value={profileformik.values.mobile}
                  onBlur={profileformik.handleBlur}
                  className="text-black border-gray-200 bg-[#FAFAFA] rounded-lg h-12 text-sm font-[600] leading-tight w-full"
                  type="text"
                  placeholder="Mobile"
                  aria-label="Mobile"
                  style={{ backgroundColor: "#ccc" }} // Grey out the background since it's disabled
                />

                {profileformik.errors.mobile &&
                  profileformik.touched.mobile && (
                    <p className="text-red-500 text-sm h-[20px] mt-1">
                      {profileformik.errors.mobile}
                    </p>
                  )}
              </div>

              <div className="w-96 flex-col justify-start items-start gap-2 inline-flex mt-2">
                <label className="self-stretch text-black text-sm font-medium leading-tight">
                  Date of Birth
                </label>
                <DatePicker
                  className="text-black border-gray-200 bg-[#FAFAFA] rounded-lg h-12 text-sm font-[600] leading-tight w-full"
                  name="dob"
                  selected={profileformik.values.dob}
                  onChange={(date) => profileformik.setFieldValue("dob", date)}
                  placeholderText="Date of Birth"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />

                {profileformik.errors.dob && profileformik.touched.dob && (
                  <p className="text-red-500 text-sm h-[20px] mt-1">
                    Enter Date of Birth
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 mb-3 gap-x-4 mx-auto">
              <div className="w-96 flex-col justify-start items-start gap-1 inline-flex mt-2">
                <label className="self-stretch text-black text-sm font-medium leading-tight">
                  City
                </label>
                <input
                  name="city"
                  onChange={profileformik.handleChange}
                  value={profileformik.values.city}
                  onBlur={profileformik.handleBlur}
                  className="text-black border-gray-200 bg-[#FAFAFA] rounded-lg h-12 text-sm font-[600] leading-tight w-full"
                  type="text"
                  placeholder="City"
                  aria-label="city"
                />

                {profileformik.errors.city && profileformik.touched.city && (
                  <p className="text-red-500 text-sm h-[20px] mt-1">
                    {profileformik.errors.city}
                  </p>
                )}
              </div>

              <div className="w-96 flex-col justify-start items-start gap-2 inline-flex mt-2">
                <label className="self-stretch text-black text-sm font-medium leading-tight">
                  State
                </label>
                <input
                  name="state"
                  onChange={profileformik.handleChange}
                  value={profileformik.values.state}
                  onBlur={profileformik.handleBlur}
                  className="text-black border-gray-200 bg-[#FAFAFA] rounded-lg h-12 text-sm font-[600] leading-tight w-full"
                  type="text"
                  placeholder="State"
                  aria-label="state"
                />

                {profileformik.errors.state && profileformik.touched.state && (
                  <p className="text-red-500 text-sm h-[20px] mt-1">
                    {profileformik.errors.state}
                  </p>
                )}
              </div>
            </div>

            {/* SchoolName   */}
            <div className="grid grid-cols-1 w-full mb-3 gap-x-4">
              <div className="flex-col justify-start items-start gap-2 inline-flex mt-2">
                <label className="self-stretch text-black text-sm font-medium leading-tight">
                  School Name
                </label>
                <input
                  name="schoolname"
                  onChange={profileformik.handleChange}
                  value={profileformik.values.schoolname}
                  onBlur={profileformik.handleBlur}
                  className="text-black border-gray-200 bg-[#FAFAFA] rounded-lg h-12 text-sm font-[600] leading-tight w-full"
                  type="text"
                  placeholder="School Name"
                  aria-label="schoolname"
                />

                {profileformik.errors.schoolname &&
                  profileformik.touched.schoolname && (
                    <p className="text-red-500 text-sm h-[20px] mt-1">
                      {profileformik.errors.schoolname}
                    </p>
                  )}
              </div>
            </div>
            {/* Address */}
            <div className="grid grid-cols-1 w-full mb-3 gap-x-4">
              <div className="flex-col justify-start items-start gap-2 inline-flex mt-2">
                <label className="self-stretch text-black text-sm font-medium leading-tight">
                  Address
                </label>
                <input
                  placeholder="Address"
                  name="address"
                  type="text"
                  onChange={profileformik.handleChange}
                  value={profileformik.values.address}
                  className=" text-black border-gray-200 bg-[#FAFAFA] rounded-lg h-12 text-sm font-[600] leading-tight w-full"
                  aria-label="address"
                ></input>

                {profileformik.errors.address &&
                  profileformik.touched.address && (
                    <p className="text-red-500 text-sm h-[20px] mt-1">
                      {profileformik.errors.address}
                    </p>
                  )}
              </div>
            </div>
            <div className="grid grid-cols-1 w-full mb-3 gap-x-4">
              <div className="w-96 h-20 flex-col justify-start items-start gap-2 inline-flex mt-2">
                <label className="self-stretch text-black text-sm font-medium leading-tight">
                  Pin Code
                </label>
                <input
                  name="pin_code"
                  onChange={profileformik.handleChange}
                  value={profileformik.values.pin_code}
                  onBlur={profileformik.handleBlur}
                  className="text-black border-gray-200 bg-[#FAFAFA] rounded-lg h-12 text-sm font-[600] leading-tight w-full"
                  type="text"
                  placeholder="Pin Code"
                  aria-label="pin_code"
                />

                {profileformik.errors.pin_code &&
                  profileformik.touched.pin_code && (
                    <p className="text-red-500 text-sm h-[20px] mt-1">
                      {profileformik.errors.pin_code}
                    </p>
                  )}
              </div>
            </div>
            <div className=" w-full flex flex-row bg-white justify-end items-end gap-4 mt-2">
              <button
                className="w-28 px-10 py-4 bg-white rounded-lg border-2 border-[#0060B9] flex-col justify-center items-center  inline-flex text-[#0060B9] text-sm font-semibold leading-tight"
                type="button"
                onClick={props.handleCancel}
              >
                Cancel
              </button>
              <button
                className="w-48 px-10 py-4 text-white bg-[#0060B9] rounded-lg flex-col justify-center items-center gap-2 inline-flex"
                type="submit"
              >
                {isCPLoading ? <Spinner /> : "Save"}
              </button>
            </div>
          </form>
          {/* <LoadingComponent isLoading={isCPLoading} component={(
                    )} /> */}
        </div>
      </div>
    </>
  );
}

export default UpdateProfileForm;
