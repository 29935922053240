import { Checkbox, Label } from "flowbite-react";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from 'yup';
import AxiosMain from "../../../Components/AxiosMain";
import CustomDropDown from "../../../Components/CustomDropDown";
import FormsLayout from "../../../Components/FormsLayout";
import LoadingComponent from "../../../Components/LoadingComponent";
import { getAdminCategory, getAdminClasses } from "../../../Components/adminHelper";
import { setAdminCategory, setAdminVideos } from "../../../redux/features/authStateSlice";
function AdminVideoAdd() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isPaginate, setisPaginate] = useState(false)
    const [isCPLoading, setIsCPLoading] = useState(false);
    const adminAllCategory = useSelector((state) => state.auth.category);
    const adminAllClasses = useSelector((state) => state.auth.classes);
    const userauth = useSelector((state) => state.auth.data);
    const [selectedCat, setSelectedCat] = useState([]);
    const [categoryOptions, setCategoryOption] = useState([]);
    const [selectedClass, setSelectedClass] = useState([]);
    const [classOptions, setClassOption] = useState([]);
    const [isCatehoryDrop, setIsCategoryDrop] = useState(false);
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            "Authorization": `Bearer ${userauth.token.access}`,
        },
    }
    const addCategorySchema = Yup.object().shape({
        name: Yup.string()
            .required("Field is required"),
        videocategory: Yup.array().min(1).required('Video Category  is required.'),
        videoclass: Yup.array().min(1).required('Video Class is required.'),
        video: Yup.mixed(),
        pdf: Yup.mixed(),
    });
    const getvideoData = () => {
        AxiosMain.get(`/kits/video/?page=1`, config).then((res) => {
            if (res.status === 200) {
                if (res.data.count > 0) {
                    dispatch(setAdminVideos(res.data));
                    navigate("/videos")
                }
            }
        }).catch((e) => {
            const { response } = e;
            console.log("Errors ", response);
        })
    }
    const addCategory = useFormik({
        initialValues: {
            name: "",
            video: '',
            pdf:'',
            videocategory: [],
            videoclass: [],
            pdf:[],
            is_dummy: false
        },
        validationSchema: addCategorySchema,
        onSubmit: (values) => {
            // const bodyFormData = new FormData();
            // bodyFormData.append('name', values.name);
            // bodyFormData.append('name', "dadasd");
            // console.log(bodyFormData);
            var formdata = new FormData();
            if(values.video.name != undefined || values.pdf.name != undefined) {
                console.log('if called ',values);
                formdata.append("video", values.video);
                formdata.append("pdf", values.pdf);
                formdata.append('name', values.name);
                formdata.append('is_dummy', values.is_dummy);
            
            // formdata.append("video", values.video);
            // formdata.append("pdf", values.pdf);
            // formdata.append('name', values.name);
            // formdata.append('is_dummy', values.is_dummy);
                if (values.hasOwnProperty('videocategory')) {
                    values.videocategory.forEach((item) => {
                        formdata.append("videocategory", item);
                    })
                }

                if (values.hasOwnProperty('videoclass')) {
                    values.videoclass.forEach((item) => {
                        formdata.append("videoclass", item);
                    })
                }
                setIsCPLoading(true);
                AxiosMain.post("/kits/video/", formdata, config).then((res) => {
                    setIsCPLoading(false);
                    if (res.status === 201) {
                        Swal.fire({
                            title: 'Done',
                            text: 'Video Successfully added!',
                            icon: "success",
                            timer: 3000
                        });
                        getvideoData();
                    }
                }).catch((e) => {
                    setIsCPLoading(false);
                    const { response } = e;
                    console.log("Error ", response);
                    if (response.status === 400) {
                        const { data } = response;
                        const { video } = data;
                        if (video.length > 0) {
                            Swal.fire({
                                title: "Error",
                                text: video[0],
                                icon: "error",
                            })
                        } else {
                            Swal.fire({
                                title: "Error",
                                text: "Something Went Wrong!",
                                icon: "error",
                            })
                        }

                    }
                    else {
                        Swal.fire({
                            title: "Error",
                            text: "Something Went Wrong!",
                            icon: "error",
                        })
                    }
                })
            }
            else{
                Swal.fire({
                    title: "Error",
                    text: "Please Upload Video or PDF",
                    icon: "error",
                })
            }
        }
            
    }
    );
    function getUnique(arr, index) {

        const unique = arr
            .map(e => e[index])

            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);

        return unique;
    }
    const getSearchCategory = (val) => {
        if (val !== '') {
            AxiosMain.get(`/kits/category/?search=${val}`, config).then((res) => {
                if (Object.keys(adminAllCategory).length > 0 && adminAllCategory.results.length > 0) {
                    const mergeData = [...adminAllCategory.results, ...res.data.results];
                    const uniqueNames = mergeData.filter((val, id, array) => array.indexOf(val) == id)
                    const lastvale = getUnique(uniqueNames, 'id');
                    const newdata = { ...adminAllCategory, results: lastvale };
                    dispatch(setAdminCategory(newdata));
                } else {
                    dispatch(setAdminCategory(res.data));
                }
                setIsCategoryDrop(true);
            }).catch((e) => {
                const { response } = e;
                console.log("Error response", response);
            })
        }

    }
    useEffect(() => {
        if (Object.keys(adminAllCategory).length === 0) {
            getAdminCategory(dispatch, config);
        }
        else {
            const catData = adminAllCategory.results.map((item) => {
                return {
                    label: item.name,
                    value: item.id
                }
            })
            setCategoryOption(catData);
        }
        if (Object.keys(adminAllClasses).length === 0) {
            getAdminClasses(dispatch, config);
        }
        else {
            const classData = adminAllClasses.results.map((item) => {
                return {
                    label: item.name,
                    value: item.id
                }
            })
            setClassOption(classData);
        }

    }, [adminAllClasses, adminAllCategory]);
    useEffect(() => {
        if (selectedCat.length > 0) {
            let formValue = [];
            selectedCat.map((item) => formValue.push(item.id));
            addCategory.setFieldValue("videocategory", formValue);
        }
        if (selectedClass.length > 0) {
            let formValue = [];
            selectedClass.map((item) => formValue.push(item.id));
            addCategory.setFieldValue("videoclass", formValue)
        }
    }, [selectedCat, selectedClass])
    return (
        <>
            <div className="bg-white p-5 min-h-[50%]">
                <div className='flex flex-row md:w-full mb-3 w-full justify-center'>
                    <div className="shadow rounded-[10px] border p-3 basis-full md:basis-full lg:basis-1/2 ">
                        <h2 className="cm-kits-form-title flex text-xl lg:text-3xl font-normal text-[#5a5c69] gap-y-2 mb-4">Video Add</h2>
                        <LoadingComponent
                            isLoading={isCPLoading}
                            component={(
                                <form onSubmit={addCategory.handleSubmit} className='flex flex-col w-full cm-form-add' encType="multipart/form-data">
                            <div className='flex flex-col w-full'>
                                <div className="mb-3">
                                    <FormsLayout touched={addCategory.touched.name} placeholder="Name" errors={addCategory.errors.name} type="text" name="name" form={addCategory} values={addCategory.values.name} />
                                </div>
                                <div className="mb-3">
                                    <div className="form-group">
                                        <Label htmlFor="fileUpload" value="Upload Video" />
                                        <input id="fileUpload" name="video" type="file" onChange={(event) => {
                                            addCategory.setFieldValue("video", event.currentTarget.files[0]);
                                        }} className="form-control rounded-[8px]" />
                                    </div>
                                    {addCategory.errors.video && addCategory.touched.video && (
                                        <p className='form-error  mb-0'>{addCategory.errors.video}</p>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <div className="form-group">
                                        <Label htmlFor="PDFUpload" value="Upload PDF" />
                                        <input id="pdfUpload" name="pdf" type="file" 
                                        accept="application/pdf"
                                        onChange={(event) => {
                                            addCategory.setFieldValue("pdf", event.currentTarget.files[0]);
                                        }} className="form-control rounded-[8px]" />
                                    </div>
                                    
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="videocategory" className="ml-2">Select for Category</Label>
                                    <CustomDropDown url="/kits/category/" isMultiple={true} placeholder="Select Category" saveData={setSelectedCat} />
                                    {addCategory.errors.videocategory && addCategory.touched.videocategory && (
                                        <p className='form-error  mb-0'>{addCategory.errors.videocategory}</p>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="videoclass" className="ml-2">Select an option of Class</Label>
                                    <CustomDropDown url="/kits/class/" isMultiple={true} placeholder="Select Class" saveData={setSelectedClass} />
                                    {addCategory.errors.videoclass && addCategory.touched.videoclass && (
                                        <p className='form-error  mb-0'>{addCategory.errors.videoclass}</p>
                                    )}
                                </div>
                                <div>
                                    <Checkbox id="is_dummy" defaultChecked={addCategory.values.is_dummy} onChange={addCategory.handleChange} />
                                    <Label htmlFor="is_dummy" className="ml-2">Is uploaded Video is dummy or not .</Label>
                                </div>
                                <button className='btn btn-primary border text-xs md:text-sm bg-[#4e73df] mt-4 p-2 md:p-4 rounded-[8px] text-[#fff]' type="submit">Add</button>
                            </div>
                        </form>
                            )}
                        />

                    </div>

                </div>
            </div >

        </>
    )
}

export default AdminVideoAdd;