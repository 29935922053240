import React from "react";
import UserProfileDropdown from "./UserProfileDropdown";
import pfp from "../assets/images/pfp.svg";

function TopBarcomponent(props) {
  const { profile } = props;
  return (
    <>
      <div className="flex justify-between items-center lg:navbar-divider border-t-2 pt-4">
        <div className="flex flex-row items-center">
          <img src={pfp} alt="Profile" className="w-10 h-10 rounded-full" />
          <span className="ml-4 font-medium text-[#000]">
            {profile.fullname}
          </span>
        </div>
        <UserProfileDropdown profile={profile} />
      </div>
    </> 
  );
}
export default TopBarcomponent;
