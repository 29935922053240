import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Pageoutlay from "./Components/pageoutlay";
import Login from "./pages/Loginpage";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RegisterPage from "./pages/RegisterPage";
import ForgotpasswordPage from "./pages/ForgotpasswordPage";
import NotFoundPage from "./pages/NotFoundPage";
import PersonalDashboard from "./pages/Personal/PersonalDashboard";
import { getRoutesByUser } from "./Routes";
import EmailVerifyPage from "./pages/EmailVerifyPage";

function App() {
  const [isLogggedin, setLoggedin] = useState(false);
  const isUserLogged = useSelector((state) => state.auth.data);
  let userauth = useSelector((state) => state.auth);

  useEffect(() => {
    if (isUserLogged) {
      if (Object.keys(isUserLogged).length > 0) {
        setLoggedin(true);
      } else {
        setLoggedin(false);
      }
    } else {
      setLoggedin(false);
    }
  }, [isUserLogged, userauth]);

  return (
    <div>
      <Router>
        <Routes>
          {isLogggedin ? (
            <>
              <Route element={<Pageoutlay />}>
                {getRoutesByUser(userauth?.data?.user?.user_type)}
                <Route
                  exact
                  path="/email-verify"
                  element={<EmailVerifyPage />}
                />
              </Route>
            </>
          ) : (
            <>
              <Route exact path="/" element={<Login />} />
              <Route exact path="/login" element={<Login />} />
              <Route
                exact
                path="/forget-password/"
                element={<ForgotpasswordPage />}
              />
              <Route exact path="/register" element={<RegisterPage />} />
              <Route
                exact
                path="/dashboard/:id"
                element={<PersonalDashboard />}
              />
            </>
          )}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </div>
  );
}
export default App;
