import React, { useRef, useState } from "react";
import pdf from "../../../../../src/assets/images/Frame 18.svg";
import back from "../../../../../src/assets/images/back_arrow.svg";
import video_thumbnail from "../../../../../src/assets/images/play.jpg";

import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

function ThumbnailVideoItem({ videoUrl, description, pdf, handleOpenVideo }) {
  return (
    <div
      className="flex flex-row h-[4.5rem] mb-5 "
      onClick={() => handleOpenVideo(videoUrl, description, pdf)}
    >
      <img
        src={video_thumbnail}
        className="border-white-200 rounded-lg"
        alt="Video Thumbnail"
      />
      <p className="text-black text-md font-normal ml-3">{description}</p>
    </div>
  );
}

function CategoryVideos() {
  const videoRef = useRef(null); // Add this line
  const { id } = useParams();
  const [videoUrl, setVideoUrl] = useState("");
  const [videos, setVideos] = useState([]);
  const [videoName, setVideoName] = useState("");
  const [pdfName, setPdfName] = useState("");
  const history = useNavigate();
  const videoData = useSelector((state) => state.dashboard.videoData);

  const handleDownloadClick = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = pdfName;
    downloadLink.download = "downloaded.pdf";
    downloadLink.target = "_blank";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleOpenVideo = (newVideoUrl, newVideoName, newPdf) => {
    setVideoUrl(newVideoUrl);
    setVideoName(newVideoName);
    setPdfName(newPdf);
    if (videoRef.current) {
      videoRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const matchVideoData = (videoData) => {
    videoData.forEach((item) => {
      if (
        item.kit.category.name === id &&
        item.kit.category.videos.length > 0
      ) {
        setVideoUrl(`${item.kit.category.videos[0].video}`);
        setVideos(item.kit.category.videos);
        setVideoName(item.kit.category.videos[0].name);
        setPdfName(item.kit.category.videos[0].pdf);
      }
    });
  };

  React.useEffect(() => {
    matchVideoData(videoData);
  }, [videoData, id, videos]);

  return (
    <>
      <div
        ref={videoRef}
        className="flex flex-row items-center mb-9 lg:ml-10 mt-16 md:mt-6"
        onClick={() => history("/dashboard")}
      >
        <img src={back} alt="back" className="w-8 h-8 hover:cursor-pointer" />
        <h1 className="text-black text-xl font-bold ml-6">{id}</h1>
      </div>
      <div className="flex flex-col lg:flex-row w-full lg:pl-10 mt-10 md:mt-6">
        <div className="lg:w-2/3 lg:flex lg:flex-col">
          <div className="w-full pr-4">
            
            <div className="aspect-w-16 aspect-h-9 rounded-2xl overflow-hidden">
              {videoUrl ?
              <video
                controls
                controlsList="nodownload"
                src={videoUrl}
                type="video/mp4"
                className="w-full h-full"
                onContextMenu={(e) => e.preventDefault()}
              /> :<></>}
            </div>
            <h3 className="text-black text-base font-bold mt-8 mb-4">
              {videoName}
            </h3>
            <hr className="mb-8 " />
          </div>
        </div>
        <div className="lg:w-1/3 lg:flex lg:flex-col">
          {pdfName && (
            <>
              <div>
                <h3 className="text-black text-lg font-bold">Resources</h3>
                <div
                  className="flex flex-row items-center my-4 hover:cursor-pointer"
                  onClick={handleDownloadClick}
                >
                  <img src={pdf} alt="PDF Thumbnail" />
                  <p className="ml-3 text-black">View File</p>
                </div>
              </div>
              <hr className="mb-4 pt-4" />
            </>
          )}
          <div>
            <h3 className="text-black text-lg font-bold mb-4">All Videos</h3>
            <div className="hover:cursor-pointer">
              {[...videos]
                .sort((a, b) => {
                  const numA = parseInt(a.name.split(". ")[0], 10);
                  const numB = parseInt(b.name.split(". ")[0], 10);
                  return numA - numB;
                })
                .map((video) => (
                  <ThumbnailVideoItem
                    key={video.id}
                    videoUrl={video.video}
                    description={video.name}
                    pdf={video.pdf}
                    handleOpenVideo={handleOpenVideo}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoryVideos;
