import { Alert, Button } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import InformationFillIcon from "remixicon-react/InformationFillIcon";
function EmailVArifyMessage() {
  const navigate = useNavigate();
  const location = useLocation();
  const profileData = useSelector((state) => state.auth.profiledata);
  const [isEmail, setISEmail] = useState(true);
  useEffect(() => {
    if (Object.keys(profileData).length > 0) {
      const { email_verified } = profileData;
      if (location.pathname === "/email-verify") {
        setISEmail(true);
      } else {
        setISEmail(email_verified);
      }
    }
  }, [profileData, location]);
  return (
    <>
      {!isEmail ? (
        <div className="mt-14">
          <Alert
            color="failure"
            icon={InformationFillIcon}
            withBorderAccent={true}
            className="cm-email-verify-wrap-info"
          >
            <div className="flex items-center justify-between w-[100%]">
              <span className="font-medium">Please Verify Your Email.</span>
              <Button
                color="failure"
                size="sm"
                onClick={() => navigate("/email-verify")}
              >
                Verify Email
              </Button>
            </div>
          </Alert>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
export default EmailVArifyMessage;
