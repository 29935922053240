import { Button, Pagination } from 'flowbite-react';
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteBin3FillIcon from "remixicon-react/DeleteBin3FillIcon";
import Edit2FillIcon from "remixicon-react/Edit2FillIcon";
import Swal from "sweetalert2";
import AxiosMain from "../../../Components/AxiosMain";
import LoadingComponent from "../../../Components/LoadingComponent";
import { getAdminCategory } from "../../../Components/adminHelper";
import { setAdminCategory } from "../../../redux/features/authStateSlice";

function AdminCategoryList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isPaginate, setisPaginate] = useState(false)
    const [isCPLoading, setIsCPLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [startOffSet, setStartOffSet] = useState(0);
    const [endOffSet, setEndOffSet] = useState(10);
    const [categoryAPIData, setCategoryAPIData] = useState([]);
    const adminAllCategory = useSelector((state) => state.auth.category);
    const userauth = useSelector((state) => state.auth.data);
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userauth.token.access}`,
        },
    }
    const deleteCategory = (id) => {
        AxiosMain.delete(`/kits/category/${id}`, config).then((res) => {
            console.log(res);
            if (res.status === 204 && res.data === '') {
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
                getAdminCategory(dispatch, config);
            }
        }).catch((e) => {
            const { response } = e;
            console.log("error", response);
        })
    }
    const deleteAction = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCategory(id);
            }
        })
    }
    useEffect(() => {
        if (Object.keys(adminAllCategory).length === 0) {
            //getAdminCategory(dispatch, config);
            getCategoryPage(currentPage);
        } else {
            setIsCPLoading(false);
            const totalPage = adminAllCategory.count;
            setTotalPages(Math.ceil(totalPage / 10));
        }
    }, [adminAllCategory]);
    // useEffect(() => {
    //     // let totalPageCount = 10;
    //     // if (currentPage === 1) {
    //     //     setStartOffSet(0);
    //     //     setEndOffSet(10);
    //     // } else {
    //     //     let cprtpag = currentPage - 1;
    //     //     let tempEnd = currentPage * totalPageCount
    //     //     let tempStart = cprtpag * 10;
    //     //     setStartOffSet(tempStart);
    //     //     setEndOffSet(tempEnd);
    //     // }
    //     console.log(currentPage);
    // }, [currentPage])
    useEffect(() => {
        if (Object.keys(adminAllCategory).length > 0 && adminAllCategory.results.length > 0) {
            //setCategoryAPIData(adminAllCategory.results.slice(startOffSet, endOffSet));
            setCategoryAPIData(adminAllCategory.results);
        }

    }, [adminAllCategory])
    function getUnique(arr, index) {

        const unique = arr
            .map(e => e[index])

            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);

        return unique;
    }
    const getCategoryPage = (data) => {
        setIsCPLoading(true);
            AxiosMain.get(`/kits/category/?page=${data}`, config).then((res) => {
                if (res.status === 200) {
                    setIsCPLoading(false);
                    dispatch(setAdminCategory(res.data));
                    setCurrentPage(data);
                    // if (res.data.results.length > 0) {
                    //     if (data === 1) {
                    //         dispatch(setAdminCategory(res.data));
                    //     } else {
                    //         const mergeData = [...adminAllCategory.results, ...res.data.results];
                    //         const uniqueNames = mergeData.filter((val, id, array) => array.indexOf(val) === id)
                    //         const lastval = getUnique(uniqueNames, "id");
                    //         const newdata = { ...adminAllCategory, results: lastval };
                    //         dispatch(setAdminCategory(newdata));
                    //     }
                    //     setCurrentPage(data);
                    // }
                }
            }).catch((e) => {
                const { response } = e;
                setIsCPLoading(false);
                console.log("Category Errors found! while paginnation ", response);
                Swal.fire({
                    title: "Error",
                    text: "Something went Wrong While Getting Category!",
                    icon: "error"
                })
            })

    }
    const columnsLable = [
        {
            name: 'id',
            sortable: true,
            selector: row => row.id,
            maxWidth: "100px",
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            maxWidth: "200px",
        },
        {
            name: "Action",
            maxWidth: "150px",
            cell: (row) => (
                <div className="my-3 text-right flex">
                    <Button.Group>
                        <Button className="mb-2" onClick={() => navigate(`/category/edit/${row.id}`)}>
                            <Edit2FillIcon className="h-4 w-4" />
                        </Button>
                        <Button className="" color="failure" onClick={() => deleteAction(row.id)}>
                            <DeleteBin3FillIcon className="h-4 w-4" />
                    </Button>
                    </Button.Group>
                </div>
            )
        }
    ];
    return (
        <>
            <div className="bg-white py-5 px-3 mb-[30px]">
                <div className='md:w-full mb-3 w-full max-w-[700px] mx-auto'>
                    <div className="shadow rounded-[10px] border p-3 min-h-[300px]">
                        <div className="cm-admin-category-table-wrapper m-0 sm:mb-3 overflow-hidden">
                            <div className="flex justify-between">
                                <h2 className="cm-kits-form-title flex text-xl lg:text-3xl font-normal text-[#5a5c69] gap-y-2 mb-4">Category List</h2>
                                <Button color="light" className="mb-2  min-w-[130px]" onClick={() => getCategoryPage(1)}>Get Latest Category
                                </Button>
                            </div>
                            <DataTable
                                className="cm-category-admin-table"
                                    columns={columnsLable}
                                    data={categoryAPIData}
                                    paginationPerPage={10}
                                    pagination={isPaginate}
                                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
                                    />
                            {/* <LoadingComponent isLoading={isCPLoading} component={(
                                
                            )} /> */}
                        </div>
                    </div>
                    {
                        totalPages > 1 && (
                            <div className="flex justify-center mt-2">
                                <Pagination
                                    currentPage={currentPage}
                                    onPageChange={(data) => getCategoryPage(data)}
                                    showIcons={true}
                                    totalPages={totalPages} />
                            </div>
                        )
                    }

                </div>
            </div>


        </>
    )
}

export default AdminCategoryList;