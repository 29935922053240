import React from "react";

function KitCard(props) {
  const cardStyle = {
    width: "363px",
    height: "247px",
    borderRadius: "24px",
    border: "4px dashed #E1E1E1",
    background: "#FBFBFB",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    cursor: "pointer", // Add cursor style to indicate clickability
    transition: "transform 0.2s", // Add transition for smooth effect
    transform: props.isClicked ? "scale(0.95)" : "scale(1)",
  };

  const frameStyle = {
    display: "flex",
    width: "299px",
    height: "167px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    flexShrink: 0,
  };

  const iconStyle = {
    width: "48px",
    height: "48px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "36px", // Adjust as needed for visual correctness
    flexShrink: 0,
    color: "#929292",
    fontWeight: 800,
  };

  const textStyle = {
    width: "269px",
    color: "#7A7A7A",
    textAlign: "center",
    fontFamily: "Nunito",
    fontSize: "18px",
    fontWeight: 800,
  };

  return (
    <div style={cardStyle} onClick={props.onClick}>
      <div style={frameStyle}>
        <div style={iconStyle}>+</div>
        <div style={textStyle}>Add New Kit</div>
      </div>
    </div>
  );
}

export default KitCard;
