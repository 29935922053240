import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from 'yup';
import AxiosMain from "../../../Components/AxiosMain";
import FormsLayout from "../../../Components/FormsLayout";
import LoadingComponent from "../../../Components/LoadingComponent";
import { getAdminCategory } from "../../../Components/adminHelper";
function AdminCategoryEdit() {
    const param = useParams();
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isCPLoading, setIsCPLoading] = useState(false);
    const adminAllCategory = useSelector((state) => state.auth.category);
    const userauth = useSelector((state) => state.auth.data);
    const [isCategoryEdit, setIsCategoryEdit] = useState(false);
    const [categoryName, setCategoryName] = useState('');
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userauth.token.access}`,
        },
    }
    const editCategorySchema = Yup.object().shape({
        name: Yup.string()
            .required("Field is required"),
    });
    const editCategory = useFormik({
        initialValues: {
            name: 'asdasd',
        },
        validationSchema: editCategorySchema,
        onSubmit: (values) => {
            setIsCPLoading(true);
            console.log("Updateing", values);
            AxiosMain.put(`/kits/category/${id}`, values, config).then((res) => {
                console.log(res);
                setIsCPLoading(false);
                if (res.status === 200) {
                    Swal.fire({
                        title: 'Done',
                        text: 'Category Successfully Updated!',
                        icon: "success",
                        timer: 3000
                    });
                    getAdminCategory(dispatch, config);
                    navigate("/category")
                }
            }).catch((e) => {
                const { response } = e;
                console.log("erroe", response);
                setIsCPLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Something Went Wrong!",
                    icon: "error",
                })
            })
        }
    })
    useEffect(() => {
        if (categoryName !== '') {
            editCategory.setFieldValue("name", categoryName);
            console.log(editCategory.values);
        }
        if (id !== '') {
            console.log("Category ", adminAllCategory);
            if (Object.keys(adminAllCategory).length > 0) {
                if (adminAllCategory.results.length > 0) {
                    adminAllCategory.results.map((item) => {
                        const itemId = `${item.id}`
                        if (itemId === id) {
                            setCategoryName(item.name)
                        }
                    })
                }
            }

        }
    }, [id, categoryName])
    return (
        <>
            <div className="bg-white p-5 min-h-[50%]">
                <div className='flex flex-row md:w-full mb-3 w-full justify-center'>
                    <div className="shadow rounded-[10px] border p-3 basis-full md:basis-full lg:basis-1/2 xl:basis-1/3">
                        <h2 className="cm-kits-form-title flex text-xl lg:text-3xl font-normal text-[#5a5c69] gap-y-2 mb-4">Category Edit</h2>
                        <LoadingComponent isLoading={isCPLoading} component={(
                            <form onSubmit={editCategory.handleSubmit} className='flex flex-col w-full'>
                            <div className='flex flex-col w-full'>
                                    <FormsLayout touched={editCategory.touched.name} placeholder="Category Name" errors={editCategory.errors.name} type="text" name="name" form={editCategory} values={editCategory.values.name} />
                                <button className='btn btn-primary border text-xs md:text-sm bg-[#4e73df] mt-4 p-2 md:p-4 rounded-[8px] text-[#fff]' type="submit">Update</button>
                            </div>
                        </form>
                        )} />
                    </div>

                </div>
            </div>

        </>
    )
}

export default AdminCategoryEdit;