import { useDispatch } from "react-redux"
import Swal from "sweetalert2";
import { setAdminCategory, setAdminClasses, setAdminKits, setAdminQRCodes, setAdminQuery } from "../redux/features/authStateSlice";
import AxiosMain from "./AxiosMain";
import { setPersonalDBquery } from "../redux/features/personalDBStateSlice";


//Personal User

const getPersonalQuery = (dispatch, config) => {
    try {
        AxiosMain.get("/raise-query/", config).then((res) => {
            if (res.status === 200) {
                if (res.data.results.length > 0) {
                    dispatch(setPersonalDBquery(res.data.results));
                }
            }
        }).catch((e) => {
            Swal.fire({
                title: "Error",
                text: "Something went wrong while getting query!",
                icon: "error"
            })
        })
    } catch (e) {
        Swal.fire({
            title: "Error",
            text: "Something went wrong while getting queries!",
            icon: "error"
        })
    }
}

// Admin Functions
const getAdminQuery = (dispatch, config) => {
    try {
        AxiosMain.get("/query/", config).then((res) => {
            if (res.status === 200) {
                if (res.data.results.length > 0) {
                    dispatch(setAdminQuery(res.data.results));
                }
            }
        }).catch((e) => {
            Swal.fire({
                title: "Error",
                text: "Something went wrong While Getting query!",
                icon: "error"
            })
        })
    } catch (e) {
        Swal.fire({
            title: "Error",
            text: "Something went wrong while getting queries!",
            icon: "error"
        })
    }
}
const getAdminCategory = (dispatch, config) => {
    try {
        AxiosMain.get("/kits/category/", config).then((res) => {
            console.log("Admin Get Category Data ", res.data);
            if (res.status === 200) {
                if (res.data.count > 0) {
                    dispatch(setAdminCategory(res.data));
                }
            }
        }).catch((e) => {
            const { response } = e;
            console.log("Admin Get Category Errors found! ", response);
            Swal.fire({
                title: "Error",
                text: "Something went wrong While Getting Category!",
                icon: "error"
            })
        })
    } catch (e) {
        Swal.fire({
            title: "Error",
            text: "Something went Wrong!",
            icon: "error"
        })
    }

}

const getAdminClasses = (dispatch, config) => {
    try {
        AxiosMain.get("/kits/class/", config).then((res) => {
            console.log("Admin Get Classes Data ", res.data);
            if (res.status === 200) {
                if (res.data.count > 0) {
                    dispatch(setAdminClasses(res.data));
                }
            }
        }).catch((e) => {
            const { response } = e;
            console.log("Admin Get Classes Errors found! ", response);
            Swal.fire({
                title: "Error",
                text: "Something went wrong while getting classes!",
                icon: "error"
            })
        })
    } catch (e) {
        Swal.fire({
            title: "Error",
            text: "Something went Wrong!",
            icon: "error"
        })
    }
}

const getAdminQRCodes = (dispatch, config) => {
    try {
        AxiosMain.get("/kits/qr-code/", config).then((res) => {
            console.log("Admin Get QR Code Data ", res.data);
            if (res.status === 200) {
                if (res.data.count > 0) {
                    dispatch(setAdminQRCodes(res.data.results))
                }
            }
        }).catch((e) => {
            const { response } = e;
            console.log("Admin Get QR Code Errors found! ", response);
            Swal.fire({
                title: "Error",
                text: "Something went Wrong While Getting Classes!",
                icon: "error"
            })
        })
    } catch (e) {
        Swal.fire({
            title: "Error",
            text: "Something went Wrong!",
            icon: "error"
        })
    }

}
const setAdminKitsData = (categoryData, qrCodeData, kitsData, dispatch) => {
    let outputData = [];
    let finalData = [];
    kitsData.map((kititem) => {
        categoryData.map((catitem) => {
            if (catitem.name === kititem.qr_category) {
                let dt = {
                    ...kititem, category: catitem.name
                }
                outputData.push(dt)
            }
        })
    })

    if (outputData.length > 0) {
        console.log("outputData ", outputData);
        console.log("qrCodeData ", qrCodeData);
        outputData.map((item) => {
            qrCodeData.map((qritem) => {
                if (qritem.id === item.qrcode) {
                    let fdata = {
                        ...item, qrcode: qritem.qr_sr_no
                    }
                    finalData.push(fdata);
                }
            })
        })
    }
    if (finalData.length > 0) {
        console.log(finalData);
        dispatch(setAdminKits(finalData))
    }
}
const getAdminKits = (dispatch, config, categoryData, qrCodeData) => {
    try {
        AxiosMain.get("/kits/kit/", config).then((res) => {
            if (res.status === 200) {
                if (res.data.count > 0) {
                    console.log("I am here", res.data.results);
                    dispatch(setAdminKits(res.data))
                    //setAdminKitsData(categoryData, qrCodeData, res.data.results, dispatch);
                }
            }
        }).catch((e) => {
            const { response } = e;
            console.log("Admin Get Kits Errors found! ", response, e);
            Swal.fire({
                title: "Error",
                text: "Something went wrong while getting kits!",
                icon: "error"
            })
        })
    } catch (e) {
        Swal.fire({
            title: "Error",
            text: "Something went Wrong!",
            icon: "error"
        })
    }
}
export {
    getAdminCategory,
    getAdminClasses,
    getAdminQRCodes,
    getAdminKits,
    getAdminQuery,
    getPersonalQuery
}