import { Spinner } from "flowbite-react";
import React from "react";
import { boolean } from "yup";

function LoadingComponent(props) {
    const { isLoading, component } = props;
    return (
        <div className="relative w-full">
            {isLoading && (
                <>
                    <div className="bg-white absolute top-0 left-0 w-full h-full z-20 opacity-70"></div>
                    <div role="status" className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2 z-50">
                        <Spinner aria-label="Extra large spinner example"
                            size="xl" />
                    </div>
                </>
            )}
            <div className={isLoading ? "pointer-events-none opacity-75" : ""}>{component}</div>
        </div>
    )
}
LoadingComponent.defaultProps = {
    isLoading: true,
    component: "No Data Set"
}
export default LoadingComponent;