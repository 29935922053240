import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import AxiosMain from '../AxiosMain';
import { useFormik } from "formik";
import LoadingComponent from "../LoadingComponent";
import { Alert } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";
import { useEffect } from "react";



function PasswordResetForm(props) {


    const [isCPLoading, setIsCPLoading] = useState(false);
    const userauth = useSelector((state) => state.auth.data);
    const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
    const passwordSchema = Yup.object().shape({
        old_password: Yup.string().required("Enter Old Password"),
        password: Yup.string()
            .min(5)
            .matches(passwordRules, { message: "Please create a stronger password" })
            .required("Enter New Password"),
        confirm_password: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("Enter Confirm Password")

    });
    const passwordForm = useFormik({
        initialValues: {
            old_password: "",
            password: "",
            confirm_password: ""
        },
        validationSchema: passwordSchema,
        onSubmit: (values, { resetForm }) => {
            setIsCPLoading(true);
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userauth.token.access}`,
                },
            }
            AxiosMain.post("/update-password", values, config).then((res) => {
                setIsCPLoading(false);
                if (res.status === 201) {

                    props.setSuccessMessage(res.data.message);
                    props.setErrorMsg(false);
                }
                resetForm();
            }).catch((e) => {
                setIsCPLoading(false);
                const { response } = e;
                console.log("Error ", e);
                if (response.status === 400) {
                    const msg = response.data.old_password[0]

                    props.setErrorMsg(msg);
                    props.setSuccessMessage(false);
                }
                else {

                    props.setErrorMsg("Something Went Wrong!");
                    props.setSuccessMessage(false);
                }
            })
        }
    })

    // useEffect(() => {
    //     props.setErrorMsg(false);
    //     props.setSuccessMessage(false);
    // }, []);

    return (
        <div className="w-full bg-white rounded-lg flex-col justify-start items-start inline-flex">
            <div className="self-stretch justify-between items-center gap-6 inline-flex mb-4">
                <div className="text-black text-xl font-bold leading-loose">Change Password</div>

            </div>

            <form onSubmit={passwordForm.handleSubmit} className="self-stretch w-full md:w-auto flex-col justify-start items-start gap-6 flex">
                {props.errorMsg && (
                    <Alert color="failure" icon={HiInformationCircle}>
                        <span>
                            <p>
                                <span className="font-medium"> </span>
                                {props.errorMsg}
                            </p>
                        </span>
                    </Alert>
                )}
                {props.successMessage && (
                    <Alert color="success" icon={HiInformationCircle}>
                        <span>
                            <p>
                                <span className="font-medium"> </span>
                                {props.successMessage}
                            </p>
                        </span>
                    </Alert>
                )}
                <div className="self-stretch flex-col justify-start items-start gap-8 flex">
                    <div className="self-stretch h-24 flex-col justify-start items-start gap-2 flex">
                        <label className="self-stretch text-black text-sm font-normal leading-tight" htmlFor="old_password">Old Password</label>
                        <input
                            type="password"
                            id="old_password"
                            name="old_password"
                            onChange={passwordForm.handleChange}
                            onBlur={passwordForm.handleBlur}
                            value={passwordForm.values.old_password}
                            className="self-stretch p-4 bg-[#FAFAFA] rounded-lg border border-[#ECECEC] "
                        />
                        {passwordForm.touched.old_password && passwordForm.errors.old_password ? <div className="text-red-500 text-sm mt-1">{passwordForm.errors.old_password}</div> : null}
                    </div>


                    <div className="self-stretch h-24 flex-col justify-start items-start gap-2 flex">
                        <label className="self-stretch text-black text-sm font-normal leading-tight" htmlFor="password">New Password</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            onChange={passwordForm.handleChange}
                            onBlur={passwordForm.handleBlur}
                            value={passwordForm.values.password}
                            className="self-stretch p-4 bg-[#FAFAFA] rounded-lg border border-[#ECECEC]"
                        />
                        {passwordForm.touched.password && passwordForm.errors.password ? <div className="text-red-500 text-sm mt-1">{passwordForm.errors.password}</div> : null}
                    </div>


                    <div className="self-stretch h-24 flex-col justify-start items-start gap-2 flex">
                        <label className="self-stretch text-black text-sm font-normal leading-tight" htmlFor="confirm_password">Confirm New Password</label>
                        <input
                            type="password"
                            id="confirm_password"
                            name="confirm_password"
                            onChange={passwordForm.handleChange}
                            onBlur={passwordForm.handleBlur}
                            value={passwordForm.values.confirm_password}
                            className="self-stretch p-4 bg-[#FAFAFA] rounded-lg border border-[#ECECEC]"
                        />
                        {passwordForm.touched.confirm_password && passwordForm.errors.confirm_password ? <div className="text-red-500 text-sm  mt-1 ">{passwordForm.errors.confirm_password}</div> : null}
                    </div>

                </div>


                <div className="self-stretch justify-start items-center gap-4 inline-flex mt-5">
                    <button type="submit" className="grow shrink basis-0 px-10 py-4 bg-sky-700 rounded-lg flex-col justify-center items-center gap-2 inline-flex">
                        <div className="text-white text-sm font-semibold leading-tight">Save New Password</div>
                    </button>
                </div>
            </form>
            {/* <LoadingComponent
                isLoading={isCPLoading}
                component={

                }
            /> */}
        </div>
    );
}
export default PasswordResetForm;