import { createSlice } from "@reduxjs/toolkit";


const initialState= {
  data: {},
  profiledata: {},
  category: {},
  classes: {},
  videos: {},
  kits: {},
  qrcodes: [],
  query: [],
  kituser: {}
};

export const authStateSlice = createSlice({
  name: "state",
  initialState,
  reducers: {
    setAuthState: (state, action) => {
      state.data = action.payload;
    },
    removeAuthState: (state) => {
      state.data = {};
      state.profiledata = {};
      state.category = {};
      state.classes = {};
      state.videos = {};
      state.kits = {};
      state.qrcodes = [];
      state.query = [];
      state.kituser = {};
    },
    setProfileState: (state, action) => {
      state.profiledata = action.payload;
    },
    setAdminKitUser: (state, action) => {
      state.kituser = action.payload;
    },
    setAdminCategory: (state, action) => {
      state.category = action.payload;
    },
    setAdminClasses: (state, action) => {
      state.classes = action.payload;
    },
    setAdminVideos: (state, action) => {
      state.videos = action.payload;
    },
    setAdminKits: (state, action) => {
      state.kits = action.payload;
    },
    setAdminQuery: (state, action) => {
      state.query = action.payload;
    },
    setAdminQRCodes: (state, action) => {
      state.qrcodes = action.payload;
    }
  }
});

export const {
  setAuthState,
  removeAuthState,
  setProfileState,
  setAdminCategory,
  setAdminClasses,
  setAdminVideos,
  setAdminKits,
  setAdminQRCodes,
  setAdminQuery,
  setAdminKitUser
} = authStateSlice.actions;

export default authStateSlice.reducer;