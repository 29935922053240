import { Button, Pagination } from 'flowbite-react';
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AxiosMain from "../../../Components/AxiosMain";
import LoadingComponent from "../../../Components/LoadingComponent";
import { getAdminCategory, getAdminKits, getAdminQRCodes } from "../../../Components/adminHelper";
import { setAdminKits } from "../../../redux/features/authStateSlice";
function AdminKitsList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isPaginate, setisPaginate] = useState(false)
    const [isCPLoading, setIsCPLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [startOffSet, setStartOffSet] = useState(0);
    const [endOffSet, setEndOffSet] = useState(10);
    const [kitAPIData, setKitAPIData] = useState([]);
    const adminAllCategory = useSelector((state) => state.auth.category);
    const adminAllQRCode = useSelector((state) => state.auth.qrcodes);
    const adminKits = useSelector((state) => state.auth.kits);
    const userauth = useSelector((state) => state.auth.data);
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userauth.token.access}`,
        },
    }
    const deleteCategory = (id) => {
        AxiosMain.delete(`/kits/kit/${id}`, config).then((res) => {
            console.log(res);
            if (res.status === 204 && res.data === '') {
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
                getAdminKits(dispatch, config, adminAllCategory, adminAllQRCode);
            }
        }).catch((e) => {
            const { response } = e;
            console.log("error", response);
        })
    }
    const deleteAction = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCategory(id);
            }
        })
    }
    const getKitPage = (data) => {
        setIsCPLoading(true);
        AxiosMain.get(`/kits/kit/?page=${data}`, config).then((res) => {
            if (res.status === 200) {
                setIsCPLoading(false);
                if (res.data.results.length > 0) {
                    dispatch(setAdminKits(res.data));
                    // if (data === 1) {
                    //     dispatch(setAdminKits(res.data));
                    // } else {
                    //     const mergeData = [...adminKits.results, ...res.data.results];
                    //     const uniqueNames = mergeData.filter((val, id, array) => array.indexOf(val) === id)
                    //     const newdata = { ...adminKits, results: uniqueNames };
                    //     dispatch(setAdminKits(newdata));
                    // }
                    setCurrentPage(data);
                }
            }
        }).catch((e) => {
            const { response } = e;
            setIsCPLoading(false);
            console.log("Category Errors found! while paginnation ", response);
            Swal.fire({
                title: "Error",
                text: "Something went Wrong While Getting Category!",
                icon: "error"
            })
        })

    }
    const getKitsData = async () => {
        AxiosMain.get("/kits/kit/", config).then((res) => {
            console.log(res.data, "Kits Data");
            if (res.status === 200) {
                if (res.data.count > 0) {
                    dispatch(setAdminKits(res.data.results));
                }
                else if (res.data.count > 10) {
                    setisPaginate(true);
                }
            }
        }).catch((e) => {
            const { response } = e;
            console.log("Errors ", e);
        })
    }
    // useEffect(() => {
    //     if (adminKits.length === 0) {
    //         getKitsData();
    //     }
    // }, [adminKits]);

    useEffect(() => {

        if (Object.keys(adminKits).length === 0) {
            getKitPage(currentPage)
        }
        // else if (adminAllQRCode.length === 0) {
        //     getAdminQRCodes(dispatch, config);
        // }
        // if (Object.keys(adminAllCategory).length > 0 && adminAllQRCode.length > 0 && adminKits.length === 0) {
        //     getAdminKits(dispatch, config, adminAllCategory.results, adminAllQRCode);
        // }
        if (Object.keys(adminKits).length > 0) {
            setIsCPLoading(false);
            const totalPage = adminKits.count;
            setTotalPages(Math.ceil(totalPage / 10));
        }
        // if (adminKits.length > 10) {
        //     setisPaginate(true);
        // } else {
        //     setisPaginate(false);
        // }
    }, [adminKits]);
    // useEffect(() => {
    //     let totalPageCount = 10;
    //     if (currentPage === 1) {
    //         setStartOffSet(0);
    //         setEndOffSet(10);
    //     } else {
    //         let cprtpag = currentPage - 1;
    //         let tempEnd = currentPage * totalPageCount
    //         let tempStart = cprtpag * 10;
    //         setStartOffSet(tempStart);
    //         setEndOffSet(tempEnd);
    //     }
    // }, [currentPage])
    useEffect(() => {
        if (Object.keys(adminKits).length > 0 && adminKits.results.length > 0) {
            setKitAPIData(adminKits.results.slice(startOffSet, endOffSet))
        }
    }, [startOffSet, endOffSet, adminKits])
    const columnsLable = [
        {
            name: 'id',
            sortable: true,
            selector: row => row.id,
            maxWidth: "100px",
        },
        {
            name: 'Category',
            selector: row => row.qr_category,
            sortable: true,
        },
        {
            name: 'QR Code',
            selector: row => row.qr_code,
            sortable: true,
        },
        // {
        //     name: "Action",
        //     maxWidth: "150px",
        //     cell: (row) => (
        //         <div className="my-3 text-right flex">
        //             <Button.Group>
        //                 <Button className="mb-2" onClick={() => navigate(`/kits/edit/${row.id}`, {
        //                     state: {
        //                         id: row.id,
        //                         kitsData: row
        //                     }
        //                 })}>
        //                     <Edit2FillIcon className="h-4 w-4" />
        //                 </Button>
        //                 <Button className="" color="failure" onClick={() => deleteAction(row.id)}>
        //                     <DeleteBin3FillIcon className=" h-4 w-4" />
        //             </Button>
        //             </Button.Group>
        //         </div >
        //     )
        // }
    ];
    return (
        <>
            <div className="bg-white py-5 px-3 mb-[30px]">
                <div className='md:w-full mb-3 w-full max-w-[800px] mx-auto'>
                    <div className="shadow rounded-[10px] border p-3">
                        <div className="cm-admin-category-table-wrapper m-0 sm:mb-3">
                            <div className="flex justify-between">
                                <h2 className="cm-kits-form-title flex text-xl lg:text-3xl font-normal text-[#5a5c69] gap-y-2 mb-4">Kits List</h2>
                                <Button color="light" className="mb-2  min-w-[130px]" onClick={() => getKitPage(1)}>
                                    Get Latest Kits
                                </Button>
                            </div>
                            <LoadingComponent isLoading={isCPLoading} component={(
                                <DataTable
                                    className="cm-category-admin-table"
                                    columns={columnsLable}
                                    data={kitAPIData}
                                    paginationPerPage={10}
                                    pagination={isPaginate}
                                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
                                />
                            )} />
                        </div>
                    </div>
                    {
                        totalPages > 1 && (
                            <div className="flex justify-center mt-2">
                                <Pagination
                                    currentPage={currentPage}
                                    onPageChange={(data) => getKitPage(data)}
                                    showIcons={true}
                                    totalPages={totalPages}
                                />
                            </div>
                        )
                    }

                </div>
            </div>


        </>
    )
}

export default AdminKitsList;