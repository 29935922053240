import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  data: [],
  videoData: [],
  videoClass: [],
  query: []
};

export const b2bDBStateSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setB2BDBState: (state, action) => {
      state.data = action.payload;
    },
    setB2BDBVideoData: (state, action) => {
      state.videoData = action.payload;
    },
    setB2BDBVideoClass: (state, action) => {
      state.videoClass = action.payload;
    },
    setB2BDBquery: (state, action) => {
      state.query = action.payload;
    },
    removeB2BDBState: (state) => {
      state.data = [];
      state.videoData = [];
      state.videoClass = [];
      state.query = [];
    },
  }
});

export const {
  setB2BDBState,
  removeB2BDBState,
  setB2BDBVideoData,
  setB2BDBVideoClass,
  setB2BDBquery
} = b2bDBStateSlice.actions;

export default b2bDBStateSlice.reducer;