import { Textarea } from "flowbite-react";
import React, { useState } from "react";
import EyeCloseFillIcon from "remixicon-react/EyeCloseFillIcon";
import EyeFillIcon from "remixicon-react/EyeFillIcon"
function FormsLayout(props) {
    const { type, name, placeholder, form, values, errors, touched } = props;
    const [isPassword, setPassword] = useState(true);
    switch (type) {
        case 'email':
            return (
                <>
                    <input type={type} placeholder={placeholder} name={name} onBlur={form.handleBlur} onChange={form.handleChange} value={values} className='p-2 px-5 border-[#d1d3e2] rounded-[8px] focus:border-blue-300 outline-none border-2 focus:shadow-md mb-2 md:mb-0 text-xs md:text-base'></input>
                    {errors && touched && (
                        <p className='form-error  mb-0'>{errors}</p>
                    )}
                </>)
        case 'text':
            return (
                <>
                    <input type={type} placeholder={placeholder} name={name} onBlur={form.handleBlur} onChange={form.handleChange} value={values} className='p-2 px-5 border-[#d1d3e2] rounded-[8px] focus:border-blue-300 outline-none border-2 focus:shadow-md mb-2 md:mb-0 text-xs md:text-base'></input>
                    {errors && touched && (
                        <p className='form-error  mb-0'>{errors}</p>
                    )}
                </>)
        case 'textarea':
            return (
                <>
                    <Textarea type={type} placeholder={placeholder} name={name} onBlur={form.handleBlur} onChange={form.handleChange} value={values} className='p-2 px-5 border-[#d1d3e2] rounded-[8px] focus:border-blue-300 outline-none border-2 focus:shadow-md mb-2 md:mb-0 text-xs md:text-base' rows={4} />
                    {errors && touched && (
                        <p className='form-error  mb-0'>{errors}</p>
                    )}
                </>)
        case 'password':
            return (
                <>
                    <div className="form-control">
                        <div className="input-group border-[#d1d3e2] outline-none rounded-[8px] overflow-hidden">
                            <input type={isPassword ? type : "text"} placeholder={placeholder} name={name} onBlur={form.handleBlur} onChange={form.handleChange} value={values} className='w-full px-5 p-2  focus:border-blue-300 mb-2 md:mb-0 text-xs md:text-base'></input>
                            <button type="button" className="input" onClick={() => setPassword(!isPassword)}>{isPassword ? <EyeCloseFillIcon color="black" /> : <EyeFillIcon color="black" />}</button>
                        </div>
                    </div>

                    {errors && touched && (
                        <p className='form-error  mb-0'>{errors}</p>
                    )}
                </>)
        default:
            return null
    }
}
export default FormsLayout;