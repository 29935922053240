import React from "react";
import { Accordion } from "flowbite-react";
import CopyboardLineIcon from 'remixicon-react/FilePdfLineIcon';
import pdficon from '../../assets/images/pdf icon.png'

function VideoAccordion(props) {
    const { cat, post } = props;

    return (
        <>
            <Accordion alwaysOpen={true} className="shadow mb-3">
                <Accordion.Panel>
                    <Accordion.Title>
                        {cat.name}
                    </Accordion.Title>
                    <Accordion.Content className="bg-white">
                        <div className="w-full grid grid-cols-1 bg-[#fff] md:grid-cols-2 lg:grid-cols-3 py-9 md:gap-x-6 px-6 gap-y-4">
                            {post?.length > 0 && post?.filter((item) => item?.name === props.cat.name)?.map((item, index) => (
                                item.videos.map((item, index) => (
                                    
                                    <div>

                                        <div key={`video-key-${index}`} id="outer" onContextMenu={e => e.preventDefault()} className="w-full flex border rounded-xl bg-[#fff] shadow-md md:h-72 h-48 justify-center items-center text-[#4e73df] font-bold">

                                            <video disablePictureInPicture className='w-full h-full object-cover' src={item.video} controls controlsList="nodownload noremoteplayback noplaybackrate" />
                                        </div>
                                        <div className="w-full  m-3 flex   justify-center items-center text-[#4e73df] font-bold">
                                            <text className="center">{item.name}</text>
                                        </div>
                                    {item.pdf ? 
                                        <div className="w-full m-3 flex justify-center items-center text-[#000] text-sm">
                                            {/* <CopyboardLineIcon size={20}/> */}
                                            <img src={pdficon} width={"10%"}/>
                                    <a href={item.pdf} target="_new" className="p-1 text-[#000]">View PDF</a> 
                                    </div> : <></>}
                                    </div>
                                ))
                            ))}
                        </div>
                    </Accordion.Content>
                </Accordion.Panel>
            </Accordion>
        </>
    )
}
export default VideoAccordion;