import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function AdminRootPage() {
    const navigate = useNavigate();
    useEffect(() => {
        navigate("/label-printing");
    }, [])
    return (
        <>
            <h1 className='flex text-3xl font-normal text-[#5a5c69] gap-y-2 my-4'>Coming Soon </h1>
        </>
    )
}

export default AdminRootPage;