import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from 'yup';
import AxiosMain from "../../../Components/AxiosMain";
import FormsLayout from "../../../Components/FormsLayout";
import LoadingComponent from "../../../Components/LoadingComponent";
function AdminQueryReply() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [queryReply, setQueryReply] = useState({});
    const pallquery = useSelector((state) => state.auth.query);
    const [isCPLoading, setIsCPLoading] = useState(true);
    const userauth = useSelector((state) => state.auth.data);
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userauth.token.access}`,
        },
    }
    const addQuerySchema = Yup.object().shape({
        query: Yup.string()
            .required("Field is required"),
        remark: Yup.string()
            .required("Field is required"),
    });

    const addQuery = useFormik({
        initialValues: {
            query: "",
            remark: "",
            closedate: new Date().toLocaleString()
        },
        validationSchema: addQuerySchema,
        onSubmit: (values) => {
            console.log(values);
            setIsCPLoading(true);
            AxiosMain.put(`/query/${id}/`, values, config).then((res) => {
                setIsCPLoading(false);
                if (res.status === 200) {
                    Swal.fire({
                        title: 'Done',
                        text: 'Query Successfully Updated',
                        icon: "success",
                        timer: 3000
                    });
                    navigate("/query")
                }
            }).catch((e) => {
                const { response } = e;
                console.log("Error ", response);
                setIsCPLoading(false);
                Swal.fire({
                    title: "Error",
                    text: "Something Went Wrong!",
                    icon: "error",
                })
            })
        }
    });
    useEffect(() => {
        if (id !== '') {
            AxiosMain.get(`/query/${id}`, config).then((res) => {
                setQueryReply(res.data);
            }).catch((e) => {
                const { response } = e;
                console.log(response);
                if (response.status === 404) {
                    Swal.fire({
                        title: "Error",
                        text: response.data.detail,
                        icon: "error",
                    })
                }
            })
        }
    }, [id])

    useEffect(() => {
        if (Object.keys(queryReply).length > 0) {
            addQuery.setFieldValue("query", queryReply.query);
            setIsCPLoading(false);
        }
    }, [queryReply])
    return (
        <>
            <div className="bg-white p-5 min-h-[50%]">
                <div className='flex flex-row md:w-full mb-3 w-full justify-center'>
                    <div className="shadow rounded-[10px] border p-3 basis-full md:basis-full lg:basis-1/2">
                        <h2 className="cm-kits-form-title flex text-xl lg:text-3xl font-normal text-[#5a5c69] gap-y-2 mb-4">Query Reply</h2>
                        <LoadingComponent isLoading={isCPLoading} component={(
                            <form onSubmit={addQuery.handleSubmit} className='flex flex-col w-full'>
                                <div className='flex flex-col w-full'>
                                    <div className="mb-3">
                                        <div className='w-full px-5 p-2 border-[#d1d3e2] rounded-[8px] focus:border-blue-300 outline-none border-2 focus:shadow-md mb-2 md:mb-0 text-xs md:text-base'>{addQuery.values.query}</div>
                                    </div>
                                    {queryReply.remark ? 
                                    <div className="mb-3">
                                        <div className='w-full px-5 p-2 border-[#d1d3e2] rounded-[8px] focus:border-blue-300 outline-none border-2 focus:shadow-md mb-2 md:mb-0 text-xs md:text-base'>{queryReply.remark}</div>
                                    </div>: <></>}

                                    {queryReply.resolved  ? 
                                    <div></div> :
                                    <div>
                                    <div className="mb-3">
                                        <FormsLayout touched={addQuery.touched.name} placeholder="Leave a comment..." errors={addQuery.errors.name} type="textarea" name="remark" form={addQuery} values={addQuery.values.remark} />
                                    </div>

                                    <button className='btn btn-primary border text-xs md:text-sm bg-[#4e73df] mt-4 p-2 md:p-4 rounded-[8px] text-[#fff]' type="submit">Submit</button>
                                    </div> }
                                </div>
                            </form>
                        )} />

                    </div>

                </div>
            </div>

        </>
    )
}

export default AdminQueryReply;