import AddCircleFillIcon from "remixicon-react/AddCircleLineIcon";
import DashboardIcon from 'remixicon-react/Dashboard3LineIcon';
import ListCheckIcon from "remixicon-react/ListCheck2Icon";
import QuestionnaireFillIcon from "remixicon-react/QuestionnaireLineIcon";
import ProfilePage from "../Components/ProfilePage";
import B2BDashboardPage from '../pages/B2B/B2BDashboardPage';
import B2BQueryAdd from '../pages/B2B/query/B2BQueryAdd';
import B2BQueryList from '../pages/B2B/query/B2BQueryList';
const b2bSidebarRoutes = [
    {
        label: "Dashboard",
        icon: <DashboardIcon color='206CB3' />,
        icont: <DashboardIcon className=' mx-12 h-8 w-7' color='#206CB3' />,
        link: "/dashboard",
        child: []
    },
    {
        label: "Query",
        icon: <QuestionnaireFillIcon color='#206CB3' />,
        icont: <QuestionnaireFillIcon className=' mx-12 h-8 w-7' color='#206CB3' />,
        link: "/query",
        child: [
            {
                label: "List",
                icon: <ListCheckIcon color='206CB3' />,
                icont: <ListCheckIcon className=' mx-12 h-8 w-7' color='#206CB3' />,
                link: "/query",
            },
            {
                label: "Query Add",
                icon: <AddCircleFillIcon color='206CB3' />,
                icont: <AddCircleFillIcon className=' mx-12 h-8 w-7' color='#206CB3' />,
                link: "/query/add",
            },
        ]
    },
]
const b2bRoutes = [
    {
        link: "/",
        component: <B2BDashboardPage />
    },
    {
        link: "/dashboard",
        component: <B2BDashboardPage />
    },
    {
        link: "/profile",
        component: <ProfilePage />
    },
    {
        link: "/query",
        component: <B2BQueryList />
    },
    {
        link: "/query/add",
        component: <B2BQueryAdd />
    },

];

export {
    b2bRoutes,
    b2bSidebarRoutes
};
