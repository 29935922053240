import React, { useState, useEffect } from 'react';
import PencilFillIcon from 'remixicon-react/PencilFillIcon';
import LockPasswordFillIcon from 'remixicon-react/LockPasswordFillIcon';
import { Modal } from 'flowbite-react';
import PasswordResetForm from '../../src/Components/FormComponent/PasswordResetForm';
import UpdateProfileForm from '../../src/Components/FormComponent/UpdateProfileForm';

import AxiosMain from '../../src/Components/AxiosMain';
import { useDispatch, useSelector } from 'react-redux';
import { setProfileState } from '../redux/features/authStateSlice';
import ArrowLeftLineIcon from 'remixicon-react/ArrowLeftLineIcon'
import { useNavigate } from "react-router-dom";



function ProfilePage() {

  const [errorMsg, setErrorMsg] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const [openModal, setOpenModal] = useState('');
  const [email, setEmail] = useState("");
  const props = { openModal, setOpenModal, email, setEmail, errorMsg, successMessage, setErrorMsg, setSuccessMessage };

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.data);
  const Profiledata = useSelector((state) => state.auth.profiledata);

  const history = useNavigate();


  const HandleCancel = () => {
    setOpenModal(undefined);
  };


  useEffect(() => {
    if (Object.keys(userData).length > 0 && Object.keys(Profiledata).length === 0) {
      const userId = userData.user.user_id;
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${userData.token.access}`,
        },
      }
      AxiosMain.get(`/my-profile/${userId}/`, config).then((res) => {

        if (res.status === 200) {
          dispatch(setProfileState(res.data));
        }
      }).catch((e) => {
        console.log({
          title: 'Warning',
          text: `Unable to get Profile Data`,
          icon: "warning",
        })
      })
    }
    else {
      if (Object.keys(Profiledata).length > 0) {
        console.log("Profile Data ", Profiledata);
      }
    }
  }, [userData, Profiledata]);

  return (
    <>

      <div className='w-full md:mt-16 my-8 lg:mt-10 lg:ml-10 mt-16'>
        <div className='flex flex-row items-center'>
          <ArrowLeftLineIcon className='w-8 h-8 cursor-pointer hidden md:flex text-black'
            onClick={() => history("/dashboard")} />
          <p className='lg:ml-6 text-black font-[700] text-[1.25rem]'>Profile</p>
        </div>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-12 gap-4 h-auto lg:ml-10  box-border'>
        <div className='md:col-span-6 lg:col-span-3'>
          <div className="flex flex-col gap-4">
            <h2 className="text-black text-lg font-extrabold leading-loose">Profile Options</h2>
            <div className="flex gap-3 items-center cursor-pointer mt-1" onClick={() => props.setOpenModal('personal-info')}>
              <PencilFillIcon color='#0B54A4' className="w-5 h-5" />
              <span className="text-sky-700 text-sm font-bold leading-loose" >Edit Personal Information</span>
            </div>
            <div className="flex gap-3 items-center cursor-pointer" onClick={() => props.setOpenModal('change-password')}>
              <LockPasswordFillIcon color='#0B54A4' className="w-5 h-5" />
              <span className="text-sky-700 text-sm font-bold leading-loose">Change Password</span>
            </div>
          </div>
        </div>

        <div className='md:col-span-4 lg:col-span-9 md:pl-4 lg:border-l-2 '>
          <div className="flex flex-col lg:ml-1">
            <h2 className="text-black text-lg font-extrabold leading-loose">Personal Information</h2>
            <div className="flex flex-col gap-2 mt-4">
              <div className="flex flex-col gap-1">
                <label className="text-neutral-400 text-xs font-medium leading-tight">Full Name</label>
                <span className="text-black text-sm font-bold leading-tight">{Profiledata.fullname ? Profiledata.fullname : 'Not Available'}</span>
              </div>
              <div className="flex flex-col gap-1 mt-4">
                <label className="text-neutral-400 text-xs font-medium leading-tight">Email</label>
                <span className="text-black text-sm font-bold leading-tight">{Profiledata.email ? Profiledata.email : 'Not Available'}</span>
              </div>
              <div className="flex flex-col gap-1 mt-4">
                <label className="text-neutral-400 text-xs font-medium leading-tight">Mobile</label>
                <span className="text-black text-sm font-bold leading-tight">{Profiledata.mobile ? Profiledata.mobile : 'Not Available'}</span>
              </div>
              <div className="flex flex-col gap-1 mt-4">
                <label className="text-neutral-400 text-xs font-medium leading-tight">Date of Birth</label>
                <span className="text-black text-sm font-bold leading-tight">{Profiledata.dob ? Profiledata.dob.split('T')[0] : 'Not Available'}</span>
              </div>
              <div className="flex flex-col gap-1 mt-4">
                <label className="text-neutral-400 text-xs font-medium leading-tight">School Name</label>
                <span className="text-black text-sm font-bold leading-tight">{Profiledata.schoolname ? Profiledata.schoolname : 'Not Available'}</span>
              </div>
              <div className="flex flex-col gap-1 mt-4">
                <label className="text-neutral-400 text-xs font-medium leading-tight">Full Address</label>
                <span className="text-black text-sm font-bold leading-tight">{Profiledata.address ? Profiledata.address : 'Not Available'}</span>
              </div>
            </div>
          </div>
        </div>
        <Modal show={props.openModal === 'change-password'} size="lg" popup onClose={() => {
          props.setOpenModal(undefined)
          props.setErrorMsg(false);
          props.setSuccessMessage(false);
          console.log("Modal Closed");
        }} >
          <Modal.Header />
          <Modal.Body>
            <div className='p-4 md:p-0'>
              <PasswordResetForm {...props} />
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={props.openModal === 'personal-info'} size="4xl" popup onClose={() => {
          props.setOpenModal(undefined)
          props.setErrorMsg(false);
          props.setSuccessMessage(false);
          console.log("Modal Closed");
        }}
        >
          <Modal.Header />
          <Modal.Body>
            <div className='p-4 md:p-0'>
              <UpdateProfileForm handleCancel={HandleCancel} />
            </div>
          </Modal.Body>
        </Modal>

      </div >
    </>
  );
}

export default ProfilePage;
