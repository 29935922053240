import React, { useEffect, useState } from "react";
import Footer from "./footer";
import Sidenav from "./sidenav";

import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";

import AxiosMain from "./AxiosMain";
import { setProfileState } from "../redux/features/authStateSlice";
import Swal from "sweetalert2";

import EmailVArifyMessage from "../pages/EmailVArifyMessage";
import TopBarcomponent from "./TopBarcomponent";

function Pageoutlay(props) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.data);
  const Profiledata = useSelector((state) => state.auth.profiledata);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (
      Object.keys(userData).length > 0 &&
      Object.keys(Profiledata).length === 0
    ) {
      const userId = userData.user.user_id;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.token.access}`,
        },
      };
      AxiosMain.get(`/my-profile/${userId}/`, config)
        .then((res) => {
          if (res.status === 200) {
            dispatch(setProfileState(res.data));
          }
        })
        .catch((e) => {
          Swal.fire({
            title: "Warning",
            text: `Unable to get Profile Data`,
            icon: "warning",
          });
        });
    }
  }, [userData]);
  useEffect(() => {
    setSidebarOpen(false);
  }, [location]);
  return (
    <>
      <div className="grid min-h-screen grid-rows-header bg-white">
        <div className="grid md:grid-cols-sidebar  min-h-screen">
          <Sidenav
            userauth={userData}
            open={sidebarOpen}
            setOpen={setSidebarOpen}
            profile={Profiledata}
          />
          <div className="p-3 relative overflow-auto bg-white">
            <TopBarcomponent
              profile={Profiledata}
              onMenuButtonClick={() => setSidebarOpen((prev) => !prev)}
            />
            <EmailVArifyMessage />
            <div className="mt-4 lg:mt-4">
              <Outlet />
            </div>
            <div className="hidden bg-white py-4 border-t-2 fixed left-0 right-0 bottom-0 cm-logged-ftr">
              {/* <Footer /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pageoutlay;
