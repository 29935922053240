import React, { useState, useRef, useEffect } from "react";
import ProfileIcon from "remixicon-react/User3FillIcon";
import LogtIcon from "remixicon-react/LogoutBoxRLineIcon";
import { useDispatch, useSelector } from "react-redux";
import { removeAuthState } from "../redux/features/authStateSlice";
import { useNavigate } from "react-router-dom";
import { removePersonalDBState } from "../redux/features/personalDBStateSlice";
import { removeB2BDBState } from "../redux/features/b2bDBStateSlice";
import AxiosMain from "../Components/AxiosMain";
import moment from "moment";
import MoreVercitalIcon from "remixicon-react/More2FillIcon";
import { arrayOf } from "prop-types";

export default function UserProfileDropdown(props) {
  const dropDownRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.auth.data);
  const [showProfileMenu, setshowProfileMenu] = useState(false);
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userData.token.access}`,
    },
  };

  const clickHandler = (e) => {
    setshowProfileMenu(!showProfileMenu);
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      var date = new Date(userData.user.exp * 1000 - 36000).toLocaleString();
      var newDate = moment(date, "DD/MM/YYYY HH:mm").toDate();
      var subtractDate = moment(newDate).subtract(1, "hours").toDate();
      var curentDate = new Date();
      var curentTime = moment(curentDate, "DD/MM/YYYY HH:mm").toDate();
      if (subtractDate === curentTime) {
        AxiosMain.get("/logout/", config)
          .then((res) => {
            if (res.status === 200) {
              dispatch(removeAuthState());
              dispatch(removePersonalDBState());
              dispatch(removeB2BDBState());
              navigate("/");
            }
          })
          .catch((e) => {
            console.log(e.response?.data);
          });
      } else if (subtractDate <= curentTime) {
        AxiosMain.get("/logout/", config)
          .then((res) => {
            if (res.status === 200) {
              dispatch(removeAuthState());
              dispatch(removePersonalDBState());
              dispatch(removeB2BDBState());
              navigate("/");
            }
          })
          .catch((e) => {
            console.log(e.response?.data);
          });
      } else {
        console.log("Something went wrong.");
      }
    }, [3600000]);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setshowProfileMenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropDownRef]);

  const logoutuser = () => {
    AxiosMain.get("/logout/", config)
      .then((res) => {
        if (res.status === 200) {
          dispatch(removeAuthState());
          dispatch(removePersonalDBState());
          dispatch(removeB2BDBState());
          localStorage.clear();
          navigate("/login");
        }
      })
      .catch((e) => {
        console.log(e.response?.data);
        dispatch(removeAuthState());
        dispatch(removePersonalDBState());
        dispatch(removeB2BDBState());
        localStorage.clear();
        navigate("/login");
      });
  };
  return (
    <div className="relative inline-block text-left" ref={dropDownRef}>
      <MoreVercitalIcon color="#000" onClick={clickHandler} className="cursor-pointer" />
      <h3
        onClick={clickHandler}
        className="flex flex-row cursor-pointer justify-center items-center rounded-full w-full"
      />

      {showProfileMenu && (
        <div className="origin-bottom-right absolute right-0 bottom-full mb-1 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="p-2 text-[#5a5c69] cm-top-bar-links-list">
            <div
              className="flex py-1 px-2 gap-x-4 cursor-pointer hover:bg-gray-100"
              onClick={() => {
                setshowProfileMenu(!showProfileMenu);
                navigate("/profile");
              }}
            >
              <ProfileIcon />
              Profile
            </div>

            <hr className="my-2" />
            <div
              className="flex py-1 px-2 gap-x-4 cursor-pointer hover:bg-gray-100"
              onClick={() => logoutuser()}
            >
              <LogtIcon />
              Logout
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
