import { Modal } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AxiosMain from "../../../Components/AxiosMain";
import LoadingComponent from "../../../Components/LoadingComponent";
import { setPersonalDBquery } from "../../../redux/features/personalDBStateSlice";
import CheckDoubleFillIcon from "remixicon-react/CheckDoubleFillIcon";
import AddFillIcon from "remixicon-react/AddFillIcon";
import QuestionnaireFillIcon from "remixicon-react/QuestionnaireFillIcon";
import CheckboxFillIcon from "remixicon-react/CheckboxFillIcon";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Alert } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";
import { Spinner } from "flowbite-react";
import CloseFillIcon from "remixicon-react/CloseFillIcon";
import QuerySuccessIcon from "../../../assets/images/QuerySuccessIcon.svg";

function PersonalQueryList() {
  const [errorMsg, setErrorMsg] = useState(false);

  const dispatch = useDispatch();
  const [allquery, setAllQuery] = useState([]);
  const [isCPLoading, setIsCPLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPostCount, setTotalPostCount] = useState(0);
  const [totalPostPages, settotalPostPages] = useState(0);
  const pallquery = useSelector((state) => state.dashboard.query);
  const userauth = useSelector((state) => state.auth.data);

  const [openModal, setOpenModal] = useState("");
  const [isQueryRaised, setIsQueryRaised] = useState(false);

  const addQuerySchema = Yup.object().shape({
    query: Yup.string().required("Field is required"),
  });

  const addQuery = useFormik({
    initialValues: {
      query: "",
      date: new Date().toLocaleString(),
    },
    validationSchema: addQuerySchema,
    onSubmit: (values) => {
      setIsCPLoading(true);
      AxiosMain.post("/raise-query/", values, config)
        .then((res) => {
          setIsCPLoading(false);
          if (res.status === 201) {
            setIsQueryRaised(true);
          }
        })
        .catch((e) => {
          const { response } = e;
          console.log("Error ", response);
          setIsCPLoading(false);
          setErrorMsg("Something went wrong, Please try again later");
        });
    },
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userauth.token.access}`,
    },
  };

  const getNextData = (page) => {
    setIsCPLoading(true);
    AxiosMain.get(`/raise-query/?page=${page}`, config)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setPersonalDBquery(res.data));
          setCurrentPage(page);
        }
      })
      .catch((e) => {
        console.log("Error While getting Query of user", e.response);
      });
    setIsCPLoading(false);
  };

  useEffect(() => {
    if (Object.keys(pallquery).length === 0) {
      getNextData(currentPage);
    }
    if (Object.keys(pallquery).length > 0) {
      setTotalPostCount(pallquery.count);
      settotalPostPages(Math.ceil(pallquery.count / 10));
      if (pallquery.results.length > 0) {
        setAllQuery(pallquery.results);
      } else {
        getNextData(currentPage);
      }
    }
  }, [pallquery]);

  useEffect(() => {
    if (allquery.length > 0) {
      setIsCPLoading(false);
    }
  }, [allquery]);

  useEffect(() => {
    if (totalPostPages !== 0) {
      getNextData(currentPage);
    }
  }, [currentPage, totalPostPages]);

  return (
    <>
      <div className="w-auto h-96 relative bg-white flex flex-col lg:ml-10">
        <div className="top-[40px] mt-[40px] justify-start items-center gap-6 inline-flex">
            <div className="text-black text-xl font-bold">Query</div>
          <div className="py-1 justify-start items-center gap-3 flex">
          </div>
        </div>
        <div className="h-96 mt-[2.5rem] flex-col justify-start items-start gap-6 inline-flex mb-4">
          <button
            onClick={() => {
              setOpenModal("raise-query");
              setIsCPLoading(false);
            }}
            className="self-stretch h-16 px-6 py-4 bg-[#FBFBFB] rounded-2xl border-2 border-dashed border-[#E1E1E1] border-dash-wide flex-row align-middle items-start gap-6 flex"
          >
            <div className="self-stretch justify-start items-center gap-3 inline-flex">
              <AddFillIcon className="w-[2.25rem] h-[2.25rem]" />
              <div className="ml-auto grow basis-0 text-[#7A7A7A] text-[1rem] font-bold">
                Raise query
              </div>
            </div>
          </button>
        </div>
        <div className="">
          {allquery.length > 0 &&
            allquery.map((item, index) => (
              <div
                key={`query-index-${index}`}
                className=" mb-4 self-stretch h-auto px-6 py-8  rounded-2xl border border-[#E1E1E1] flex-col justify-center items-start gap-6 flex"
              >
                <div className="self-stretch h-24 flex-col justify-center items-start gap-4 flex">
                  {item.resolved && (
                    <div className="px-3 py-1 bg-green-500 bg-opacity-10 rounded-3xl justify-start items-center gap-2 inline-flex">
                      <CheckDoubleFillIcon
                        color="#29AE5F"
                        className="w-4 h-4 relative"
                      />
                      <div className="text-green-500 text-sm font-bold leading-tight">
                        Resolved
                      </div>
                    </div>
                  )}
                  <div className=" self-stretch h-12 flex-col justify-center items-start gap-1.5 flex">
                    <div className="self-stretch text-[#8B8B8B] text-sm font-medium leading-tight">
                      {item.query}
                    </div>
                    <div className="self-stretch text-black text-base font-[700] leading-normal">
                      {item.remark ? item.remark : "Not Answered Yet"}
                    </div>
                  </div>
                  <div className="text-neutral-500 text-xs font-semibold leading-none">
                    Created on <span>{item.date}</span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      {openModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-white  lg:bg-[#00000080] flex justify-center items-center z-50">
          <div className="mx-2 mb-auto lg:m-auto flex flex-grow flex-col bg-white rounded-2xl lg:min-w-[28rem] lg:min-h-[28rem] lg:max-w-[28rem] shadow p-6">
            {errorMsg && (
              <Alert color="failure" icon={HiInformationCircle}>
                <span>
                  <p>
                    <span className="font-medium"> </span>
                    {errorMsg}
                  </p>
                </span>
              </Alert>
            )}
            {
              !isQueryRaised && (
                <div div className="text-black flex flex-row justify-between items-center w-full text-xl font-bold leading-loose">
                  Raise Query
                  <div className="">

                    <button
                      className="ml-auto flex justify-center items-center gap-2"
                      onClick={() => {
                        setOpenModal(undefined);
                        setIsCPLoading(false);
                      }}
                    >
                      <CloseFillIcon />
                    </button>
                  </div>
                </div>
              ) 
            }
            {!isQueryRaised ? (
              <form
                onSubmit={addQuery.handleSubmit}
                values={addQuery.values}
                className="flex flex-col gap-4"
              >
                <div className="self-stretch h-full flex-col justify-start items-start gap-8 flex">
                  <div className="self-stretch h-80 flex-col justify-start items-start gap-6 flex">
                    <div className="self-stretch text-neutral-500 text-sm font-semibold leading-none">
                      Facing issues or need help with something? Raise your query here & our team will assist you shortly.
                    </div>
                    <textarea
                      type="text"
                      name="query"
                      value={addQuery.values.query}
                      onBlur={addQuery.handleBlur}
                      onChange={addQuery.handleChange}
                      className="p-4 input-placeholder-top w-full flex flex-grow bg-neutral-50 rounded-lg border border-gray-200 align-text-top"
                      placeholder="Input your query here"
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={isCPLoading}
                    className="w-full h-12 px-10 py-4 bg-[#0060B9] rounded-lg flex-col justify-center items-center gap-2 inline-flex text-white text-sm font-bold leading-tight"
                  >
                    {isCPLoading ? <Spinner /> : "Submit"}
                  </button>
                </div>
              </form>
            ) : (
              <div className="w-full min-h-full flex-grow flex flex-col justify-center items-center gap-8">
                <div className="flex flex-col m-auto">
                  <img src={QuerySuccessIcon} alt="Query Success" className="w-[4rem] h-[4rem] mx-auto mb-4" />
                  <div className="text-center text-black text-xl font-bold">
                    Query Raised Successfully
                  </div>
                  <div className="text-center text-neutral-400 text-xs font-semibold leading-none">
                    Our expert team will help you with your query shortly
                  </div>
                </div>

                <button
                  onClick={() => {
                    setIsQueryRaised(false);
                    setOpenModal(undefined);
                    setIsCPLoading(false);
                    addQuery.resetForm();
                  }}
                  className="w-full h-12 px-10 py-4 bg-[#0060B9] rounded-lg flex-col justify-center items-center gap-2 inline-flex text-white text-sm font-bold leading-tight"
                >
                  Done
                </button>
              </div>
            )}
          </div>
        </div >
      )
      }
    </>
  );
}
export default PersonalQueryList;
