import { Button, Pagination } from "flowbite-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AxiosMain from "../../../Components/AxiosMain";
import LoadingComponent from "../../../Components/LoadingComponent";
import { setAdminQuery } from "../../../redux/features/authStateSlice";

function AdminQueryList() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [allquery, setAllQuery] = useState([]);
    const [isCPLoading, setIsCPLoading] = useState(false);
    const [isPaginate, setisPaginate] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPostCount, setTotalPostCount] = useState(0);
    const [totalPostPages, settotalPostPages] = useState(0);
    const [adminQueryData, setAdminQueryData] = useState([]);
    const adminallquery = useSelector((state) => state.auth.query);
    const userauth = useSelector((state) => state.auth.data);
    const postPerPage = 10;
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userauth.token.access}`,
        },
    }
    const columnsLable = [
        {   
            id:'id',
            name: 'Id',
            sortable: true,
            selector: row => row.id,
            cell: row => <div>{row.id}</div>,
            maxWidth: "100px",
        },
        {
            id:'name',
            name: 'Name',
            sortable: true,
            selector: row => row.user.fullname,
            cell: row => <div>{row.user.fullname}</div>,
            maxWidth: "100px",
        },
        {
            id:'email',
            name: 'Email',
            sortable: true,
            selector: row => row.user.email,
            cell: row => <div>{row.user.email}</div>,
            maxWidth: "100px",
        },
        {
            id:'query',
            name: 'Query',
            sortable: true,
            cell: row => <div>{row.query}</div>,
            selector: row => row.query,
        },
        {
            id:'date',
            name: 'Raised On',
            sortable: true,
            cell: row => <div>{row.date ? moment(row.date).format('DD/MM/YYYY HH:mm:ss') == 'Invalid date' ? (row.date):moment(row.date).format('DD/MM/YYYY HH:mm:ss'): ""}</div>,
            sortType: (a, b) => {
                return new Date(b.date) - new Date(a.date);
            },
            selector: row => row.date,
            format: (row) => moment(row.date).format('DD/MM/YYYY HH:mm:ss A'),
        },
        {
            id:'resolved',
            name: 'Answered',
            sortable: true,
            selector: row => row.resolved ?
                <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Yes</span>
                :
                <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">No</span>
        },
        {
            id:'closedate',
            name: 'Answered On',
            sortable: true,
            
            cell: row => <div>{row.closedate ? moment(row.closedate).format('DD/MM/YYYY HH:mm:ss') == 'Invalid date' ? (row.closedate):moment(row.closedate).format('DD/MM/YYYY HH:mm:ss'): ""}</div>,
            selector: row => row.closedate,
            format: (row) => moment(row.closedate).format('DD/MM/YYYY HH:mm:ss A'),
        },
        {
            id:'action',
            name: "Action",
            selector: row => <Button onClick={() => navigate(`/query/reply/${row.id}`)}>Reply</Button>
        }
    ]

    const getNextData = (page) => {
        setIsCPLoading(true);
        AxiosMain.get(`/query/?page=${page}`, config).then((res) => {
            console.log('res',res)
            if (res.status === 200) {
                let dtasave = {};
                dtasave.count = res.data.count;
                dtasave.results = [{ page: page, data: res.data.results }];
                dispatch(setAdminQuery(dtasave));
                // if (page === 1) {
                //     dispatch(setAdminQuery(dtasave));
                // }
                // else {
                //     const mergeData = [...adminallquery.results, ...dtasave.results];
                //     const newdata = { ...adminallquery, results: mergeData };
                //     dispatch(setAdminQuery(newdata));
                // }
                setCurrentPage(page);
                setIsCPLoading(false);
            }
        }).catch((e) => {
            console.log("Error While getting Kit user", e.response);
            setIsCPLoading(false);
        })
    }
    // function getUnique(arr, index) {

    //     const unique = arr
    //         .map(e => e[index])

    //         // store the keys of the unique objects
    //         .map((e, i, final) => final.indexOf(e) === i && i)

    //         // eliminate the dead keys & store unique objects
    //         .filter(e => arr[e]).map(e => arr[e]);

    //     return unique;
    // }
    const setDataintable = () => {
        if (Object.keys(adminallquery).length > 0) {
            if (adminallquery.results.length > 0) {
                const datawillsave = adminallquery.results.filter((item) => item.page === currentPage);
                if (datawillsave.length > 0) {
                  console.log('datawillSave',datawillsave)
                    setAdminQueryData(datawillsave[0].data)
                }
            }
        }
    }
    useEffect(() => {
        if (Object.keys(adminallquery).length === 0) {
            getNextData(currentPage);
        }
        if (Object.keys(adminallquery).length > 0) {
            setTotalPostCount(adminallquery.count);
            settotalPostPages(Math.ceil(adminallquery.count / 10));
            setDataintable();
        }

    }, [adminallquery])
    useEffect(() => {
        if (adminQueryData.length > 0) {
            setIsCPLoading(false);
        }
    }, [adminQueryData])
    useEffect(() => {
        if (totalPostPages !== 0) {
            getNextData(currentPage);
            // if (Object.keys(adminallquery).length > 0) {
            //     const datawillsave = adminallquery.results.filter((item) => item.page === currentPage);
            //     if (datawillsave.length > 0) {
            //         setAdminQueryData(datawillsave[0].data)
            //     } else {
            //         getNextData(currentPage);
            //     }
            // }
            // else {
            //     console.log("Hello aia m hete");
            //     getNextData(currentPage);
            // }
        }
    }, [currentPage, totalPostPages]);

  
    return (<div className="bg-white py-5 px-3">
        <div className='md:w-full mb-3 w-full  mx-auto'>
            <div className="shadow rounded-[10px] border p-3 min-h-[300px]">
                <div className="cm-admin-category-table-wrapper m-0 sm:mb-3">
                    <div className="flex justify-between">
                        <h2 className="cm-kits-form-title flex text-xl lg:text-3xl font-normal text-[#5a5c69] gap-y-2 mb-4">Query List</h2>
                        <Button color="light" className="mb-2  min-w-[130px]" onClick={() => getNextData(1)}>
                            Get Latest Query
                        </Button>
                    </div>
                    <LoadingComponent isLoading={isCPLoading} component={(
                        <DataTable
                            className="cm-category-admin-table"
                            columns={columnsLable}
                            data={adminQueryData}
                            defaultSortFieldId="id"
                            pivotBy={["id"]}
                            sortable={true}
                            striped
                            defaultSortAsc={false}
                            pagination
                            />
                    )} />
                </div>
            </div>
            {
                totalPostPages > 1 && (
                    <div className="flex justify-center mt-2">
                <Pagination
                    currentPage={currentPage}
                    onPageChange={(data) => setCurrentPage(data)}
                    showIcons={true}
                    totalPages={totalPostPages} />
            </div>
                )
            }
        </div>
    </div>)
}
export default AdminQueryList;